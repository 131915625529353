<template>

	<!-- Offcanvas actualizar pax -->
	<div v-if="reservation" class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvasUpdatePax-' + reservation.id" aria-labelledby="offcanvasBottomLabel">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">Actualizar reserva</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<form>
				<div class="input-group">
					<span class="input-group-text" id="basic-addon1"><i class="fas fa-user-edit"></i></span>
					<input type="number" class="form-control" placeholder="Número de pax" v-model="paxTuUpdate">
				</div>
				<div class="content-center">
					<button class="btn btn-primary" @click="updatePax" :disabled="paxTuUpdate<1 || isLoading">
						<span v-if="!isLoading">Actualizar</span><span v-else>Actualizando... <SpinnerIcon/></span>
					</button>
				</div>
			</form>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false" content-btn-text="No cambiar"/>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import { hideOffcanvas} from "@/utils";

import DismissButton from "@/core/DismissButton";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: "CopilotOffcanvasUpdatePaxReservation",
	inject: {
		reservationRepository: 'reservationRepository',
	},
	data() {
		return{
			paxTuUpdate: null,
			isLoading: false,
		}
	},
	components:{
		SpinnerIcon,
		DismissButton

	},
	computed:{
		...mapGetters('copilot',['selectedReservation']),
		reservation(){
			return this.selectedReservation
		}
	},
	mounted() {
	},
	methods: {
		async updatePax() {
			event.preventDefault();
			this.isLoading = true;
			const resultUpdatePax = await this.reservationRepository.updatePax({
				reservationWid: this.reservation.id,
				pax: this.paxTuUpdate
			});
			console.log(resultUpdatePax);
			if (resultUpdatePax.result === 0) {
/*				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Se han actualizado los comensales correctamente.',
						showToast: true,
						idToast: "correctlyToast"
					}
				});*/
				this.paxTuUpdate = null;
				this.isLoading = false;
				hideOffcanvas('offcanvasUpdatePax-' + this.reservation.id);
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error actualizado los comensales correctamente.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isLoading = false;
			}
		},
	}
}
</script>
