import {cookieManager} from "@/service/cookieManager";
import store from "@/store/index";
import {formatPrice, roundUp, sanitizeString} from "@/utils";
import {DateTime} from "luxon";

const state = {
	isConnected: false,
	socket: null,
	messages: [],
};

const getters = {
	isConnected: (state) => state.isConnected,
	messages: (state) => state.messages,
	trafficLightStatus: (state) => {
		return state.isConnected ? "green" : "red";
	},
};

const mutations = {
	SET_CONNECTED(state, status) {
		state.isConnected = status;
	},
	SET_SOCKET(state, socket) {
		state.socket = socket;
	},
	ADD_MESSAGE(state, message) {
		state.messages.push(message);
	},
	CLEAR_MESSAGES(state) {
		state.messages = [];
	},
};

const actions = {
	connectToWebSocket({ commit, dispatch }) {
		return new Promise((resolve, reject) => {
			try {
				const socket = new WebSocket("ws://localhost:7007"); // Use the global WebSocket

				socket.onopen = () => {
					console.log("WebSocket connection established");
					commit('SET_CONNECTED', true);
					commit('SET_SOCKET', socket);

					dispatch("sendMessage", { action: "GET_VERSION", data: null });
					resolve(); // Resolve the promise if connected
				};

				socket.onmessage = (event) => {
					console.log("Message received:", event.data);
					commit('ADD_MESSAGE', event.data);
					dispatch('handleIncomingMessage', event.data);
				};

				socket.onerror = (error) => {
					console.error("WebSocket error:", error);
					commit('SET_CONNECTED', false);
					reject(error); // Reject the promise on error
				};

				socket.onclose = () => {
					console.log("WebSocket connection closed");
					commit('SET_CONNECTED', false);
					commit('SET_SOCKET', null);
					reject(new Error("WebSocket connection closed")); // Reject if closed unexpectedly
				};
			} catch (error) {
				console.error("Error connecting to WebSocket:", error);
				reject(error); // Reject the promise if there's an error during creation
			}
		});
	},

	checkAndConnectToWeWingman({ state, dispatch }) {
		if (state.isConnected) {
			console.log("WeWingman is already connected.");
			return;
		}

		console.log("Attempting to connect to WebSocket...");
		dispatch("connectToWebSocket")
			.then(() => {
				console.log("WebSocket connection established successfully.");
			})
			.catch(() => {
				console.warn("Error conectando a websocket. Intentando abrir servicio de impresión WeWelcom...");
				testAppDeepLink(store);
			});
	},

	async printTicket({dispatch, rootGetters}, reservation) {
		//console.log("Imprimiendo "+reservation.id)

		const businessName = rootGetters['venue/businessName'];
		const fiscalInfo = rootGetters['venue/fiscalInfo'];
		const data = {
			"id": reservation.id
		}
		let ticketData = await dispatch("copilot/getReservationPaymentInfo", data, {root: true});

		if (ticketData == null) { // solo para casos en los que el backend tuviera algún problema, y siempre que no emitamos facturas
			ticketData = reservation.ticket;
		}

		console.log("Hemos consultado a ticketData", ticketData)
		const accountDate = reservation.startDateTime;
		const formattedDate = DateTime.fromSQL(accountDate).setLocale('es').toFormat('dd/MM/yyyy HH:mm');
		const pax = reservation.pax;
		//const waiterName = "Servicio estupendo";

		//const charsTotal = 48;
		const charsUnits = 3;
		const charsDescription = 29;
		const charsPvp = 6;
		const charsAmount = 7;

		const header =
			"[L]\n" +
			`[C]<font size='big'>${businessName}</font>\n` +
			"[L]\n" +
			`[C]${fiscalInfo.fiscal_name}\n` +
			`[C]${fiscalInfo.fiscal_address}\n` +
			`[C]NIF: ${fiscalInfo.fiscal_id}\n` +
			"[L]\n" +
			`[L]COMPROBANTE\n` +
			"[C]------------------------------------------------\n" +
			`[L]Fecha: ${formattedDate}[R]Comensales: ${pax}\n` +
			"[C]================================================\n" +
			"[C]Uds Descripcion                   Precio Importe\n" +
			"[C]------------------------------------------------\n";

		let productsSection = "";
		ticketData.products.forEach((product) => {
			const quantity = `${product.quantity}`.padStart(charsUnits);
			const name = sanitizeString(product.product).padEnd(charsDescription).slice(0, charsDescription); // Ensure the name fits
			const basePrice = `${formatPrice(product.basePrice, true, 2)}`.padStart(charsPvp);
			const totalPrice = `${formatPrice(product.totalPrice, true, 2)}`.padStart(charsAmount);

			productsSection += `[L]${quantity} ${name} ${basePrice} ${totalPrice}\n`;
		});

		const totalWithVat = ticketData.totalPrice;

		const perPerson = roundUp(ticketData.totalPrice / pax);
		const perCouple = perPerson * 2;
		//const evenPax = pax % 2 === 0;

		let totals =
			//"[L]\n" +
			"[C]================================================\n" +
			`[R][R][R]<b><font size='tall'>TOTAL:</font></b>[R]<b><font size='tall'>${formatPrice(totalWithVat, true, 2)}</font></b>\n` +
			"[L]\n";

		if (pax > 1) {

			let divisionSection = "";

			let perCouplePrint = "[R][R]";
			if (pax > 4){
				perCouplePrint = `[R]Por pareja:[R]${formatPrice(perCouple, true, 2)}`;
			}
			// Add per person total
			divisionSection += `${perCouplePrint}[R]Entre ${pax}:[R]${formatPrice(perPerson, true, 2)}\n`;

			// Add specific cases for pax > 1
			let blockCount = 0;
			if (pax <= 5) {
				for (let i = pax - 1; i >= 2; i--) {
					blockCount++;
					const amount = roundUp(totalWithVat / i);
					if (pax === 3){
						divisionSection += `[R][R][R]Entre ${i}:[R]${formatPrice(amount, true, 2)}\n`;
					} else {
						divisionSection += `[R]Entre ${i}:[R]${formatPrice(amount, true, 2)}`;
						if (blockCount % 2 === 0){
							divisionSection += "\n";
						}

					}
				}
			} else {
				// For pax > 5
				const half = Math.floor(pax / 2);

				divisionSection += `[R]Entre ${half}:[R]${formatPrice(roundUp(totalWithVat / half), true, 2)}[R]Entre ${half + 1}:[R]${formatPrice(roundUp(totalWithVat / (half + 1)), true, 2)}\n`;
				divisionSection += `[R][R][R]Entre ${half - 1}:[R]${formatPrice(roundUp(totalWithVat / (half - 1)), true, 2)}\n`;
			}


			totals += divisionSection;
		}

		const footer =
			"[L]\n" +
			"[C]................................................\n" +
			"[C]Todos los precios son en euros\n" +
			"[L]\n" +
			"[L]\n" +
			"[C]<font size='tall'>Gracias por su visita</font>\n" +
			"[C]<font color='bg-black'> Hasta pronto </font>\n" +
			//"[C]<barcode type='ean13' height='10'>831254784551</barcode>\n" +
			//"[L]\n" +
			//"[L]<qrcode size='25'>https://wewelcom.io/</qrcode>\n" +
			"[L]\n";

		const printMessage = header + productsSection + totals + footer;

		let printData = {
			"message" : printMessage,
			"cut" : true,
			"printLogo" : false,
			"postFeedMm": 0
		}

		dispatch("sendMessage", {action: "PRINT", data: printData })
	},
	disconnectWebSocket({ state, commit }) {
		if (state.socket) {
			state.socket.close();
			commit('SET_CONNECTED', false);
			commit('SET_SOCKET', null);
			console.log("WebSocket connection closed by client");
		}
	},
	sendMessage({ state }, { action, data }) {
		if (state.socket && state.isConnected) {
			const message = JSON.stringify({ action, data });
			state.socket.send(message);
		} else {
			console.error("WebSocket is not connected");
		}
	},
	handleIncomingMessage(_, message) {
		try {
			const { status, data, message: serverMessage } = JSON.parse(message);

			console.log("Server Response:", { status, data, serverMessage });

			if (status === "success") {
				// Handle successful responses

				switch (data.action) {
					case "GET_VERSION":
						console.log("Server version:", data.version);
						break;
					case "LIST_PRINTERS":
						console.log("Available printers:", data.printers);
						break;
					default:
						console.warn("Unhandled successful action:", data);
				}
			} else if (status === "error") {
				// Handle error responses
				console.error("Error from server:", serverMessage);
			} else {
				console.warn("Unknown status received:", status);
			}
		} catch (error) {
			console.error("Error parsing server message:", error);
		}
	},
	downloadApk({ rootGetters }) {
		const venueWewelcomUrl = rootGetters['venue/urlVenue'];
		const apkUrl = `${venueWewelcomUrl}/world/api/wewingman/download`;
		console.log("WeWingman download URL:", apkUrl);
		const token = cookieManager.getToken()
		// Fetch the APK file with authentication
		fetch(apkUrl, {
			method: "GET",
			headers: {
				"Authorization": `Bearer ${token}`, // Adjust for your token storage
			},
		})
			.then((response) => {
				if (!response.ok) {
					throw new Error("Failed to download APK");
				}
				return response.blob();
			})
			.then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = "wewingman-v1.0-release.apk";
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
				window.URL.revokeObjectURL(url);
				console.log("APK downloaded successfully");
			})
			.catch((error) => {
				console.error("Error downloading APK:", error);
				alert("There was an issue downloading the APK. Please try again later.");
			});
	},
};
const testAppDeepLink = (store) => {
	let deepLinkOpened = false; // Track if the app was successfully opened

	// Create an invisible iframe to trigger the deep link
	const iframe = document.createElement('iframe');
	iframe.style.display = 'none';
	iframe.src = "wewelcom://wewingman";

	document.body.appendChild(iframe);

	// Wait a short time to determine if the app is installed
	const timeout = setTimeout(() => {
		if (!deepLinkOpened) {
			// If the deep link did not open the app, assume it's not installed
			alert("El Servicio de impresión WeWelcom no está instalado. Intentando descargar la aplicación...");
			store.dispatch('weWingman/downloadApk')
				.then(() => {
					console.log("APK downloaded successfully");
				})
				.catch((error) => {
					console.error("Failed to download APK:", error);
					alert("Hubo un problema descargando la aplicación. Por favor, inténtelo más tarde.");
				});
		}
		document.body.removeChild(iframe); // Clean up the iframe
	}, 1000); // Adjust this timeout as necessary

	// Attempt to capture the focus event, which indicates the deep link worked
	window.addEventListener('blur', () => {
		deepLinkOpened = true; // App successfully opened
		clearTimeout(timeout); // Cancel the fallback logic
	});
};



export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
};
