<template>
	<section class="box-shadow m-0 h-100 p-2  d-flex flex-column justify-content-between">
		<ul class="nav nav-tabs mb-2">
			<li class="nav-item">
				<button
					ref="buttons"
					class="btn btn-outline-secondary btn-welcom rounded-bottom-0 active"
				>
					Bebidas a preparar
				</button>
			</li>
		</ul>

		<div class="overflow-y-scroll h-100" v-if="sortedReservationsWithProducts.length">
			<TpvListProductOrder
				v-for="reservation in sortedReservationsWithProducts"
				:key="reservation.id"
				:reservation="reservation"
			/>
		</div>
		<div v-else>
			No hay nada que preparar
		</div>
	</section>
</template>

<script>
import TpvListProductOrder from "@/components/tpv/tpvListProductOrder/TpvListProductOrder.vue";
import {mapGetters} from "vuex";

export default {
	name: "TpvProductsByCategory",
	components: {
		TpvListProductOrder
	},
	computed:{
		...mapGetters("copilot", ['reservationsWithProducts']),

		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => !reservation.finishedDateTime)
				.slice()
				.sort((a, b) => {
					if (a.cartsession && !b.cartsession) {
						return -1;
					} else if (!a.cartsession && b.cartsession) {
						return 1;
					} else {
						return 0;
					}
				});
		},
	}
}
</script>
