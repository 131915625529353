const state = {
	isLoading: false,
	viewSectionAccounts: 'allAccounts',
	viewSectionItems: 'topChoice',

	accountSelectedId: null,
	newAccount: null,
	isChangeTable: false,
	isSelectedPax: false,

	selectedNumberPax: null,
	descriptionZone: null
};

const getters = {
	isLoading: state => {
		return state.isLoading
	},
	viewSectionAccounts: state => {
		return state.viewSectionAccounts
	},
	viewSectionItems: state => {
		return state.viewSectionItems
	},

	accountSelectedId: state => {
		return state.accountSelectedId
	},
	newAccount: state => {
		return state.newAccount
	},
	isChangeTable: state => {
		return state.isChangeTable
	},
	isSelectedPax: state => {
		return state.isSelectedPax
	},
	selectedNumberPax: state => {
		return state.selectedNumberPax
	},
	descriptionZone: state => {
		return state.descriptionZone
	}
};

const mutations = {
	setIsLoading: (state, payload) => {
		state.isLoading = payload;
	},
	setViewSectionAccounts(state, sectionAccounts) {
		state.viewSectionAccounts = sectionAccounts;
	},
	setViewSectionItems(state, sectionItems) {
		state.viewSectionItems = sectionItems;
	},

	setAccountSelectedId(state, accountId) {
		state.accountSelectedId = accountId;
	},
	setNewAccount(state, newAccount) {
		state.newAccount = newAccount;
	},

	updateNewAccount(state, payload) {
		state.newAccount = {
			...state.newAccount,
			...payload,
		};
	},

	setIsChangeTable(state, payload) {
		state.isChangeTable = payload;
	},
	setIsSelectedPax(state, payload) {
		state.isSelectedPax = payload;
	},
	setSelectedNumberPax(state, payload) {
		state.selectedNumberPax = payload;
	},
	setDescriptionZone(state, payload) {
		state.descriptionZone = payload;
	}
};

const actions = {

};

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions,
}
