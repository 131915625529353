<template>
	<div class="col-3 col-md-3 col-lg-2 col-xl-2">
		<button
			ref="buttons"
			:disabled="!availability"
			class="btn btn-primary w-100 btn-tpv"
			style="height: 5rem;"
			@click="selectProduct(product)"
		>
			{{ product.shortName ? product.shortName : product.name }}
		</button>
	</div>
</template>

<script>

import store from "@/store";

export default {
	name: "TpvCardProduct",
	components: {},
	props:{
		product:{
			type: Object,
			required: true
		}
	},
	computed:{
		hasProductOptions() {
			return Array.isArray(this.product.productOptions) && this.product.productOptions.length > 0;
		},

		availability() {
			return this.product.menuCategoryProductInfo.availability === 1
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods:{
		selectProduct(product) {
			if (product.productOptions && product.productOptions.length) {
				this.$emit('product-selected-with-options', {
					product: product,
					productOptions: product.productOptions,
					isProductOption: true
				});
			} else {
				store.commit('menu/addProductToCart', { product: product, units:1, options:product.productOptions });
				store.commit('tpv/setViewSectionAccounts', "createAccount")
			}
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	}
}
</script>

<style scoped>

</style>
