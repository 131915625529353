	<template>
		<label class="product-card-client product-card-client-list" :for="`option_checkbox_${order.name}-${order.productOrderId}`" :class="{'bg-body-secondary': statusReject || statusLoss}">
			<div class="product-card-body">
				<img v-if="showPictureMenu !== null && imageUrl()" class="product-card-img " :src="imageUrl()" @error="handleImageError" alt="...">
				<div class="product-card-info">
					<div class="product-card-data">
						<div class="product-card-title fw-normal">
							<span class="me-2 fw-bold" v-if="order.quantity && source !== 'account' && !order.units">{{ order.quantity }}</span>
							<span class="fw-bold" v-if="source === 'account'">x{{ order.quantity }}</span>
							<span class="fw-bold pe-1" v-if="order.units > 1">{{ order.units }} (uds.)</span>
							<span v-if="order.count && order.count > 1">{{ order.count }}</span>
							<span :class="{'text-decoration-line-through': statusReject || statusLoss}">
								{{ order.shortName || order.name }}
							</span>
							<span v-if="source === 'account'">{{ order.price }}</span>
						</div>
					</div>
					<div class="ingredients">
						<div v-for="(choiceGroup, index) in order.choices" :key="index">
							<span>- {{ choiceGroup.choice }}</span>
						</div>
					</div>
				</div>

				<div class="product-card-btns flex-column justify-content-between">
					<div>
						<div class="fw-bold badge badge-secondary" v-if="order.dishClassHasChanged">Como primero</div>
						<div class="fw-bold badge badge-secondary" v-if="order.shareable">Para Compartir</div>

						<ChefHatIcon v-if="orderPreparingInKitchen" fill-color="black"/>

						<div v-if="orderWaitingForWaiter">
							<i class="fa-solid fa-check"></i>
							<SpinnerIcon/>
						</div>

						<i v-if="orderAlreadyOnTable" class="fa-solid fa-check-double"></i>
					</div>

					<div>
						<div v-if="source === 'user'" class="btn-group" role="group" aria-label="Basic example">
							<button type="button" :class="{'active': statusLoss}" class="btn btn-outline-primary btn-sm" @click="statusLoss ? '' : openModalRemoveProduct('decrease')">
								{{ this.statusLoss ? 'Mermado' : 'Mermar' }}
							</button>
							<button type="button" :class="{'active': statusReject}" class="btn btn-outline-primary btn-sm" @click="statusReject ? '' : openModalRemoveProduct('remove')">
								{{ this.statusReject ? 'Quitado' : 'Quitar' }}
							</button>
						</div>
					</div>
				</div>
			</div>
		</label>
	</template>

	<script>
	import {mapActions, mapGetters} from "vuex";
	import {printChoices, showModal} from "@/utils";
	import store from "@/store";
	import ChefHatIcon from "@/core/icons/ChefHatIcon";
	import SpinnerIcon from "@/core/icons/SpinnerIcon";

	export default {
		name: 'HistoricProductCard',
		components: {SpinnerIcon, ChefHatIcon},
		inject: {
			reservationRepository: 'reservationRepository',
			userRepository: 'userRepository',
		},
		emits: ['add-unit', 'subtract-unit'],
		props: {
			order: {
				type: Object,
				required: true,
				default: null
			},
			source: {
				type: String,
				default: null
			},

			reservation: {
				type: Object,
				default: null
			},

		},
		data() {
			return {
				showPictureMenu: null,
				availability: 'availability',

				idModalMoveProductOrder: 'modal-confirm-dish-class-changed-' + this.order.name + '-' + this.order.productOrderId,
			};
		},
		computed: {
			...mapGetters('venue', ['resourceId', 'urlVenue']),
			...mapGetters('copilot', ['selectedProductOrdersToServe', 'selectedProductOrdersToPrepared', 'productOrder']),
			chosenOptions() {
				const counts = this.order.choices.reduce((acc, option) => {
					acc[option.choice] = (acc[option.choice] || 0) + 1;
					return acc;
				}, {});

				return Object.keys(counts).map(choice => ({
					choice,
					count: counts[choice]
				}));
			},
			productImg() {
				return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
			},
			productFoodImg() {
				return `${this.urlVenue}/world/img/${this.resourceId}/product/${this.order.pictureMenu}`;
			},
			orderPreparingInKitchen() {
				return this.order.statusProductOrder === 2
			},

			orderWaitingForWaiter() {
				return this.order.statusProductOrder === 3
			},

			orderAlreadyOnTable() {
				return this.order.statusProductOrder === 100
			},

			statusReject() {
				return this.order.statusProductOrder === -1
			},

			statusLoss() {
				return this.order.statusProductOrder === -3
			}
		},
		watch: {
		},
		beforeMount() {
			store.commit('copilot/setSelectedProductOrdersToServe', {selectedProductOrdersToServe: []});
			store.commit('copilot/setSelectedProductOrdersToPrepared', {selectedProductOrdersToPrepared: []});
		},
		mounted() {
			this.checkImage();
		},
		methods: {
			...mapActions('copilot', ['changeIsRemoveProduct', 'saveLocalProductIdModalReject']),
			imageUrl() {
				return this.productImg;
			},
			openModalRemoveProduct(type) {
				if (type === 'decrease') {
					this.changeIsRemoveProduct(false)
					this.saveLocalProductIdModalReject({
						orderId: this.order.productOrderId,
						reservationId: this.reservation.id
					})

					this.$nextTick(() => {
						showModal('modal-remove-product');
					})
				} else {
					this.changeIsRemoveProduct(true)
					this.saveLocalProductIdModalReject({
						orderId: this.order.productOrderId,
						reservationId: this.reservation.id
					});

					this.$nextTick(() => {
						showModal('modal-remove-product');
					})
				}
			},
			checkImage() {
				const img = new Image();
				img.src = this.productImg;

				img.onerror = () => {
					this.showPictureMenu = null;
				};

				this.showPictureMenu = true
			},
			handleImageError() {
				this.showPictureMenu = null;
			},
			printChoices(choices) {
				return printChoices(choices)
			},
		}
	}
	</script>
