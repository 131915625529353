<template>
	<div class="reservation-kitchen-content-product-list">
		<div v-if="dishClass.name" class="product-card-employee product-card-employee-secondary rotate-text">
			<div class="product-card-body">
				<div class="product-card-info">
					<div class="product-card-data">
						<h5 class="product-card-title">
							{{ dishClass.name }}
						</h5>
					</div>
				</div>
			</div>
		</div>

		<!-- Agrupar productos con el mismo nombre -->
		<template
			v-for="(group, index) in groupedProducts"
			:key="index"
		>
			<div class="position-relative" :class="{'d-flex mx-2 bg-secondary border border-primary rounded-1 pe-2 gap-1': group.items.length > 1}">
				<span v-if="group.items.length > 1" class="badge badge-primary p-1 m-1 d-flex justify-content-center align-items-center fs-5 d-flex flex-column" style="height: auto">{{ group.items.length }} <span> ud.</span><span v-if="group.totalUnits > 1 && group.items.length !== group.totalUnits" >| {{ group.totalUnits }}</span>
				</span>
				<CopilotProductCardKitchen
					v-for="product in group.items"
					:key="product.productOrderId"
					:product="product"
				/>
			</div>
		</template>
	</div>
</template>


<script>
import CopilotProductCardKitchen from "@/components/copilotProductCardKitchen/CopilotProductCardKitchen";

export default {
	name: "CopilotProductsKitchen",
	components: { CopilotProductCardKitchen },
	props: {
		dishClass: {
			type: Object,
			required: true,
		},
		productsOrder: {
			type: Array,
			required: true,
		},
	},
	computed: {
		groupedProducts() {
			// Agrupar productos por SKU
			const grouped = this.productsOrder.reduce((acc, product) => {
				const groupName = product.productSku;
				if (!acc[groupName]) {
					acc[groupName] = { items: [], totalUnits: 0 };
				}
				acc[groupName].items.push(product);

				if (product.units) {
					acc[groupName].totalUnits += product.units;
				}

				return acc;
			}, {});
				console.log('🛠️🛠️🛠️',grouped)
			return Object.values(grouped);
		},
	},
};
</script>


<style scoped>
.product-group-border {
	border: 2px solid var(--bs-secondary);
	padding-inline: 8px;
	margin-inline: 8px;
	border-radius: 8px;
	position: relative;
	gap: 5px;
}
</style>
