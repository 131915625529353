<template>
	<!--  Offcanvas historico de pedidos	-->
	<div v-if="reservation" class="offcanvas offcanvas-bottom" tabindex="-1" :id="'offcanvas-historic-account-' + reservation.id" :aria-labelledby="'collapseHistoricOrders' + reservation.id">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">Cuenta mesa {{ reservation.table }}</div>
			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>

		<div class="offcanvas-body p-3">
			<div class="row mb-4 row-gap-2">
				<div class="col-6">
					<button v-if="showTicketButton" @click="handlePrintAccount()" class="btn btn-light w-100">
						<span class="process-button"><i class="fa-regular fa-file-pdf"></i> Ver Ticket</span></button>
				</div>
				<div v-if="isConnected" class="col-6">
					<button @click="handlePrintTicket()" class="btn btn-light w-100">
						<span class="process-button"><i class="fa-solid fa-print"></i> Imprimir</span></button>
				</div>
				<div class="container">
					<div class="btn-group w-100 btn-group-sm" role="group" aria-label="Basic outlined example">
						<button class="btn btn-light btn-outline-secondary w-100" :class="{ active: viewMode === 'unified' }" @click.stop="viewMode = 'unified'">
							<span v-if="viewMode === 'unified'">Total</span><span v-else>Cuenta total</span>
						</button>

						<button class="btn btn-light btn-outline-secondary w-100" :class="{ active: viewMode === 'user' }" @click.stop="viewMode = 'user'">
							<span v-if="viewMode === 'user'">Pedido</span><span v-else>Cuenta por pedido</span>
						</button>
					</div>
				</div>
			</div>

			<transition v-if="processedCartSessionOrders.length" name="fade" :duration="300" mode="out-in">
				<div v-if="viewMode === 'user'" key="user">
					<div class="orders-history " style="background-color: var(--bs-body-bg)" v-for="cartSessionOrder in processedCartSessionOrders" :key="cartSessionOrder.cartSessionOrderId">
						<div class="list-name">
							<div class="fw-bold">
								<i class="fas fa-utensils"></i>{{ cartSessionOrder.userName }}
							</div>
							<div>
              <span>
                <i class="fas fa-clock"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.createdDateTime) }} &nbsp; <i class="fas fa-check"></i>{{ dateFormatterService.formattedTime(cartSessionOrder.acknowledgedDateTime) }}
              </span>
							</div>
						</div>
						<div class="extras-products-list">
							<HistoricProductCard :order="order" :reservation="reservation" :source="'user'" v-for="order in  cartSessionOrder.items" :key="order.cartSessionOrderId"/>
						</div>
						<div class="vr-horizontal"></div>
					</div>
				</div>
				<div v-else key="unified">
					<div class="extras-products-list">
						<copilot-historic-menu-details v-for="menu in groupedMenuChoices" :menu="menu" :key="`menu-details-${menu.productSku}`"></copilot-historic-menu-details>

						<HistoricProductCard :order="order" v-for="order in historicUnification" :key="order.productSku"/>
					</div>
				</div>
			</transition>
			<div v-else>
				No hay historial de pedidos
			</div>
		</div>

		<div class="offcanvas-footer">
			<DismissButton :is-modal="false"/>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

import {groupItemsBySkuAndChoices} from "@/utils";

import DismissButton from "@/core/DismissButton";
import HistoricProductCard from "@/components/historicProductCard/HistoricProductCard";
import CopilotHistoricMenuDetails
	from "@/components/copilotOffcanvasHistoricAccountReservation/CopilotHistoricMenuDetails";

export default {
	name: "CopilotOffcanvasHistoricAccountReservation",
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	data() {
		return{
			viewMode: 'unified',
		}
	},
	components:{
		CopilotHistoricMenuDetails,

		HistoricProductCard,
		DismissButton
	},
	watch: {

	},
	computed:{
		...mapGetters('venue', ['resourceId']),
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('copilot', ['selectedReservation']),
		...mapGetters('weWingman', ['isConnected']),
		reservation(){
			return this.selectedReservation
		},
		cartSessionOrders() {
			return this.reservation && this.reservation.cartSession ? this.reservation.cartSession.productOrders : [];
		},
		showTicketButton() {
			return true;
			//return this.resourceId === "wewelcom";
		},
		processedCartSessionOrders() {
			return this.cartSessionOrders
				.filter(order => order.cartSessionStatus > 1)
		},
		groupedMenuChoices() {
			const groupedByProductSku = [];

			// Iteramos sobre las órdenes y sus ítems
			this.cartSessionOrders
				.filter(order => order.cartSessionStatus > 1)
				.flatMap(order => order.items)
				.filter(item => item.isMenu === "SI") // Solo los menús
				.forEach(order => {
					// Buscamos si ya existe un grupo para este 'productSku'
					let productGroup = groupedByProductSku.find(group => group.productSku === order.productSku);

					// Si no existe un grupo para este 'productSku', lo creamos
					if (!productGroup) {
						productGroup = {
							productSku: order.productSku,
							name: order.name, // Asumimos que el 'name' está en el orden
							quantity: 0, // Inicializamos el quantity
							choices: [] // Cambiamos 'choices' a un array para agrupar tipos
						};
						groupedByProductSku.push(productGroup);
					}

					// Aumentamos la cantidad total del 'productSku'
					productGroup.quantity += order.units;

					// Agrupamos por 'type' (tipo de menú) dentro de este 'productSku'
					order.choices.forEach(choice => {
						// Buscamos si ya existe un grupo de tipo para este 'type'
						let typeGroup = productGroup.choices.find(c => c.type === choice.type);

						// Si no existe un grupo de tipo, lo creamos
						if (!typeGroup) {
							typeGroup = {
								type: choice.type,
								options: [] // Almacenamos las opciones dentro de 'options'
							};
							productGroup.choices.push(typeGroup);
						}

						// Verificamos si ya existe esta opción dentro del tipo
						const existingChoice = typeGroup.options.find(c => c.name === choice.choice);

						if (existingChoice) {
							// Si ya existe, aumentamos la cantidad
							existingChoice.quantity += 1;
						} else {
							// Si no existe, la añadimos con una cantidad de 1
							typeGroup.options.push({
								name: choice.choice, // Renombramos a 'name'
								quantity: 1
							});
						}
					});
				});

			return groupedByProductSku;
		},
		historicUnification() {
			const groupedItems = groupItemsBySkuAndChoices(this.processedCartSessionOrders);

			const arrayProducts = Object.values(groupedItems).map(item => ({
				productSku: item.productSku,
				name: item.name,
				quantity: item.count,
				category: item.category,
				pictureGeneral: item.pictureGeneral,
				pictureMhd: item.pictureMhd,
				units: item.units > 1 ? item.units : null,
				type: item.type,
				isVisible: item.isVisible,
				isMenu: item.isMenu,
				choices: item.choices.map(choice => ({
					...choice,
					quantity: choice.quantity || 1
				})),
				choicesKey: item.choicesKey
			}));
			return arrayProducts.filter(product => product.isVisible && product.isMenu !== "SI");
		},
	},
	mounted() {

	},
	methods: {
		...mapActions('weWingman', ['printTicket']),
		async handlePrintAccount() {
			console.log("En la venue " + this.resourceId + " Vamos a imprimir el ticket de " + this.reservation.id);

			let openTab = true;

			if (openTab) {
				window.open(this.weWelcomUrl + "/world/api/reservation/ticket/" + this.reservation.id, '_blank');

			} else {
				try {
					console.log("Fetching PDF for reservation " + this.reservation.id);

					// Fetch the PDF as a Blob
					const response = await fetch(`${this.weWelcomUrl}/world/api/reservation/ticket/${this.reservation.id}`, {
						method: 'GET',
						headers: {
							'Accept': 'application/pdf',
						}
					});

					if (response.ok) {
						const blob = await response.blob();

						this.pdfUrl = URL.createObjectURL(blob);
					} else {
						console.error('Error fetching PDF');
					}
				} catch (error) {
					console.error('Error:', error);
				}
			}
		},
		async handlePrintTicket() {
			console.log("En la venue " + this.resourceId + " Vamos a imprimir el ticket de " + this.reservation.id);

			await this.printTicket(this.reservation);

		},
	}
}
</script>
