<template>
	<div id="offcanvas-menu-edit" aria-hidden="true" class="offcanvas offcanvas-bottom fade" tabindex="-1">
		<div class="offcanvas-header mb-0">
			<div id="exampleModalLabel" class="offcanvas-title h4"><i class="fas fa-edit"></i> Editar Menú</div>
			<button @click="handleCloseOffCanvas" aria-label="Close" class="btn-close" data-bs-dismiss="offcanvas" type="button"></button>
		</div>
		<div class="offcanvas-body p-3">
			<label class="">Selecciona el menú:</label>
			<select class="form-select" v-model="selectedMenuProductSku">
				<option v-for="menuProduct in menuProducts" :key="menuProduct.sku" :value="menuProduct.sku">{{ menuProduct.name }}</option>
			</select>

			<div v-if="selectedMenuProductSku != null">
				<div v-for="(optionType, optionTypeIndex) in selectedMenuProduct.productOptions" :key="optionType.id" class="row form-row-content row-column-gap justify-content-start">
					<copilot-options-daily-menu
						:option-type="optionType"
						:option-type-index="optionTypeIndex"
						:update-option="handleOptionUpdate"
						:add-menu-product-option="addMenuProductOption">
					</copilot-options-daily-menu>
				</div>
			</div>
		</div>
		<div class="offcanvas-footer">
			<DismissButton @click="handleCloseOffCanvas" :is-modal="false"/>
			<button v-if="selectedMenuProduct !== {}" type="button" class="btn btn-primary" @click="handleUpdateMenuProduct" :disabled="isSendingRequest">Guardar</button>
		</div>
	</div>
</template>
<script>

import {mapGetters} from "vuex";
import DismissButton from "@/core/DismissButton";
import store from "@/store";
import {hideAllOffcanvas} from "@/utils";
import CopilotOptionsDailyMenu from "@/components/copilotDailyMenu/CopilotOptionsDailyMenu";



export default {
	name: 'CopilotDailyMenu',
	components: {
		CopilotOptionsDailyMenu,
		DismissButton,
	},
	inject: {
		reservationRepository: 'reservationRepository',
		copilotRepository: 'copilotRepository',
		menuRepository: 'menuRepository',
	},
	props: {
	},
	data() {
		return {
			isSendingRequest: false,
			selectedMenuProductSku: null,
			selectedMenuProduct: {}
		}
	},
	computed: {
		...mapGetters('menu', ['menuProducts']),
	},
	beforeMount() {

	},
	mounted() {
		if (this.menuProducts.length > 0) {
			this.selectedMenuProductSku = this.menuProducts[0].sku;
			this.selectedMenuProduct = this.menuProducts.find(product => product.sku === this.selectedMenuProductSku) || {};
		}
	},
	beforeUnmount() {
	},
	watch:{
		menuProducts(newMenuProducts) {
			console.log('Ha cambiado el menú!!!');
			if (newMenuProducts.length > 0) {
				const product = newMenuProducts.find(product => product.sku === this.selectedMenuProduct?.sku);
				if (product && product.sku === this.selectedMenuProduct?.sku) {
					this.selectedMenuProduct = { ...product };
				}
			}
		},
		selectedMenuProductSku(newSku) {
			const product = this.menuProducts.find(product => product.sku === newSku);
			if (product && product.sku !== this.selectedMenuProduct?.sku) {
				this.selectedMenuProduct = { ...product };
			}
		}
	},
	methods: {
		handleOptionUpdate(newOption) {
			const updatedMenuProduct = {
				...this.selectedMenuProduct,
				productOptions: Array.isArray(this.selectedMenuProduct.productOptions)
					? this.selectedMenuProduct.productOptions.map(productOption => {
						return {
							...productOption,
							// Convertimos options a array si es un objeto
							options: Array.isArray(productOption.options)
								? productOption.options.map(option =>
									option.id === newOption.id ? newOption : option
								)
								: Object.values(productOption.options).map(option =>
									option.id === newOption.id ? newOption : option
								)
						};
					})
					: Object.values(this.selectedMenuProduct.productOptions).map(productOption => {
						return {
							...productOption,
							options: Array.isArray(productOption.options)
								? productOption.options.map(option =>
									option.id === newOption.id ? newOption : option
								)
								: Object.values(productOption.options).map(option =>
									option.id === newOption.id ? newOption : option
								)
						};
					})
			};

			this.selectedMenuProduct = updatedMenuProduct;
		},
		async handleUpdateMenuProduct() {
			this.isSendingRequest = true;
			console.log("Vamos a actualizar el menú " + this.selectedMenuProductSku);

			if (!this.selectedMenuProduct || !this.selectedMenuProduct.productOptions) {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha seleccionado un producto para actualizar.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				return
			}
			// Remove options with empty names
			this.selectedMenuProduct.productOptions.forEach(optionType => {
				optionType.options = optionType.options.filter(option => option.name.trim() !== '');
			});

			// Logic to save changes to the backend
			console.log('Actualizando menuProduct', this.selectedMenuProduct);
			// Make your API call here with the updated selectedProduct
			let updateMenuProductResult = await this.menuRepository.updateMenuProduct(this.selectedMenuProduct);

			console.log("Resultado de updateMenuProduct", updateMenuProductResult)

			if (updateMenuProductResult.result === 0) {
/*				store.commit("copilot/setToast", {
					toast: {
						toastMessage: updateMenuProductResult.message,
						showToast: true,
						idToast: "correctlyToast"
					}
				})*/
				this.isSendingRequest = false;
				hideAllOffcanvas()
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `Ha habido un error al actualizar el producto.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				this.isSendingRequest = false;
			}
		},
		addInput() {
			const newId = this.inputs.length + 1;
			this.inputs.push({id: newId, placeholder: "Nombre del plato:"});
		},
		addMenuProductOption(optionTypeIndex) {
			const newOption = {
				id: Date.now().toString(), // Unique ID
				enabled: 1,
				name: '',
				sortOrder: this.selectedMenuProduct.productOptions[optionTypeIndex].options.length,
				priceSupplement: null,
				isNewOption: true
			};
			this.selectedMenuProduct.productOptions[optionTypeIndex].options.push(newOption);
		},
		handleCloseOffCanvas(){
			this.selectedMenuProduct = {...this.menuProducts.find(product => product.sku === this.selectedMenuProductSku)} || {};
		}
	}

}
</script>
