<template>
	<section class="box-shadow m-0 h-100 p-1 d-flex flex-column justify-content-between">
		<ul class="list-group">
			<li class="list-group-item">
				<div class="col-12">
					<div class="row text-uppercase fw-bold">
						<div class="col-2 col-lg-1">
							Ud.
						</div>
						<div class="col-10 col-lg-11">
							Descripción
						</div>
					</div>
				</div>
			</li>
		</ul>

		<ul class="list-group overflow-y-scroll overflow-hidden h-100" v-if="productsCart.length">
			<li class="list-group-item list-group-item-light ">
				<div class="col-12" v-for="product in productsCart" :key="product.id">
					<div class="row g-0 border-bottom py-1">
						<div class="col-2 col-lg-1">
								<span class="fw-bold">
									{{ product.cartItemCount }}
								</span>
						</div>
						<div class="col-8 col-lg-9">
							<div class="row">
								<div class="col-12">
									{{ product.name }}
								</div>
								<div class="col-12" v-if="product.chosenOptions">
									<ul v-for="(choice, index) in product.chosenOptions" :key="index">
										<li v-for="(chosenId, idx) in choice.chosenIds" :key="`chosen_option_${idx}`">
											{{ getOptionName(product, choice.optionId, chosenId) }}
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-2 col-lg-2">
							<div class="d-flex gap-2 w-100 justify-content-end">
								<button v-if="false" class="btn btn-outline-primary btn-square" style="width: 40px;height: 40px;" @click="moveProductReservation(product)">
									<i class="fa-solid fa-arrow-turn-up"></i>
								</button>
								<button class="btn btn-outline-primary btn-square" style="width: 40px;height: 40px;" @click="removeProduct(product)">
									<i class="fa-solid fa-xmark"></i>
								</button>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>

		<div class="row g-0">
			<div class="col-6 col-xl-3">
				<button
					ref="buttons"
					class="btn w-100 btn-tpv"
					:disabled="this.isCreateAccountInBar || !productsCart.length"
					:class="[this.isCreateAccountInBar || !productsCart.length ? 'btn-primary' : 'btn-outline-primary']"
					style="height: 4.3rem;"
					@click="createAccountInBar"
				>
					Crear en Barra
				</button>
			</div>
<!--			<div class="col-3">-->
<!--				<button-->
<!--					class="btn w-100 btn-tpv"-->
<!--					:disabled="this.viewSectionItems === 'selectedPax' && this.viewSectionAccounts === 'createAccount' && !this.isCreateAccountInBar"-->
<!--					:class="[this.viewSectionItems === 'selectedPax' && this.viewSectionAccounts === 'createAccount' && !this.isCreateAccountInBar ? 'btn-primary' : 'btn-outline-primary']"-->
<!--					style="height: 4.3rem;"-->
<!--					@click="handleSelectedPax"-->
<!--				>-->
<!--					Crear cuenta-->
<!--				</button>-->
<!--			</div>-->
			<div class="col-6 col-xl-3">
				<button
					class="btn w-100 btn-tpv"
					:disabled="this.viewSectionItems === 'tables' && this.viewSectionAccounts === 'createAccount' || !productsCart.length"
					:class="[this.viewSectionItems === 'tables' && this.viewSectionAccounts === 'createAccount'|| !productsCart.length ? 'btn-primary' : 'btn-outline-primary']"
					style="height: 4.3rem;"
					@click="handleChangeTable"
				>
					Asignar Mesa
				</button>
			</div>
		</div>
	</section>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import {walkingService} from "@/service/walkingService";

export default {
	name: "TpvAccount",
	data() {
		return {
			isCreateAccountInBar: false
		}
	},
	components: {},
	props: {
		chosenTable: {
			type: Object
		},
		reservationDetails: {
			type: Object,
		},
	},
	computed: {
		...mapGetters('menu', ['productsCart']),
		...mapGetters('tpv', ['newAccount', 'viewSectionAccounts', 'viewSectionItems']),
		...mapGetters('copilot', ['tables', 'reservations']),
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods: {
		removeProduct(product) {
			this.$store.commit('menu/subtractUnit', {product})
		},
		moveProductReservation(product) {
			this.$store.commit('copilot/moveProductReservation', {product})
		},
		isTableOccupied(table) {
			return this.reservations.find(reservation =>
				reservation.table === table.name &&
				reservation.menuAccessEnabled === 1 &&
				reservation.status !== 7 &&
				reservation.status !== 10
			);
		},
		async createAccountInBar() {
			this.isCreateAccountInBar = true;
			const tableBar = this.tables.filter(table => table.room === "Barra");
			const firstAvailableTableInBar = this.tables
				.filter(table => table.room === "Barra")
				.find(table => !this.isTableOccupied(table));

			console.log('tiene que ser barra', tableBar);
			console.log('estas son las mesas disponibles', firstAvailableTableInBar);

			const tables = [firstAvailableTableInBar.table_id];
			const products = this.productsCart.flatMap(product => {
				if (product.isUnitary === "SI") {
					return {
						sku: product.sku,
						options: product.chosenOptions,
						units: product.cartItemCount,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					}
				} else {
					return Array(product.cartItemCount).fill({
						sku: product.sku,
						options: product.chosenOptions,
						modifyDishClass: product.modifyDishClass?.id,
						isShareable: product.isShareable
					});
				}
			});

			store.commit('tpv/updateNewAccount',{
				products: products,
				tables: tables,
			})

			if (!this.isSelectedPax) {
				store.commit('tpv/setViewSectionItems', 'selectedPax');
				return
			}

			store.commit('tpv/updateNewAccount',{
				pax: this.selectedNumberPax,
			})

			store.commit('tpv/setIsLoading', true);
			console.log(this.newAccount);

			let resultWalkin = await walkingService.create(this.newAccount);
			console.log("El resultado de crear el walkin", resultWalkin);

			if (resultWalkin.result === 0) {
				console.log({result: resultWalkin});
				// store.commit('tpv/setAccountSelectedId', resultWalkin.reservation.id)
				store.commit("tpv/setViewSectionAccounts", 'allAccounts');

				store.commit('menu/setProductsCart', {productsCart: []});
				store.commit('tpv/setNewAccount', null)
				this.isCreateAccountInBar = false;
				store.commit('tpv/setIsLoading', false);

			} else {
				store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear el sit in.', showToast: true, idToast: "incorrectlyToast"}});
				store.commit('tpv/setIsLoading', false);
			}
		},
		createAccountInTable() {

		},

		getOptionName(product, optionId, chosenId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},

		handleChangeTable() {
			this.isCreateAccountInBar = false;
			store.commit('menu/setCategorySelected', '');
			store.commit("tpv/setViewSectionItems", "tables");
			store.commit('tpv/updateNewAccount', {
				tables: null
			})
		},

		handleSelectedPax() {
			this.isCreateAccountInBar = false;
			store.commit('tpv/setViewSectionItems', 'selectedPax')
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	}
}
</script>

<style scoped>

</style>
