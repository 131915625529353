<template>
	<div class="product-commander" :class="{'disabled bg-warning': !availability}" :id="product.sku" @click="hasProductOptions ? openInformationProduct(product.sku) : sendProductToCart($event)" :data-bs-target="'#modal_' + product.sku">
		<div class="commander-title">
			<div>{{ product.shortName || product.name }}</div>
			<div v-if="!availability">(No disponible)</div>
		</div>
		<div class="commander-btns">
			<button v-if="matchingProductUnits.cartItemCount !== 0" @click.prevent="subtractUnit('modalRemoveProduct_'+product.sku )" class="btn p-1 btn-secondary btn-square btn-sm">
				<i class="fas" :class="matchingProductUnits.cartItemCount === 1 ? 'fa-trash' : 'fa-minus'"></i>
			</button>
			<div v-if="matchingProductUnits.cartItemCount !== 0" class="badge badge-primary rounded-1">
				{{ matchingProductUnits.cartItemCount }}
				<span v-if="isCopilotRoute">ud</span>
			</div>
			<button v-if="matchingProductUnits.cartItemCount !== 0 " @click.prevent="hasProductOptions ? openInformationProduct(product.sku) : sendProductToCart($event)" class="btn p-1 btn-secondary btn-square btn-sm ">
				<i class="fas fa-plus"></i>
			</button>
			<button v-if="matchingProductUnits.cartItemCount === 0 " class="btn btn-secondary btn-square btn-sm btn-square p-1"><i class="fas fa-plus"></i></button>
		</div>
	</div>
</template>

<script>
import {computed, reactive} from "vue";
import {useI18n} from "vue-i18n";
import {getTranslationKey} from "@/i18n";
import {mapGetters, mapActions} from "vuex";
import {hideAllModals, normalizeChoices} from "@/utils";
import * as bootstrap from "bootstrap";
import store from "@/store";

export default {
	name: "ProductCard",
	components: {},
	props:{
		product: {
			type: Object,
			required: true
		},
		isValidReservation: {
			type: Boolean,
			required: false
		},
		reservationPromotion: {
			type: [Object, null],
			required: false
		},
	},
	data() {
		return {
			pictureMenu: null,
			productImg: null,
			productFoodImg: null,
			selectedUnits: this.calculateValue(0),
			showPictureMenu: null,
			iSDisabledButtonAddCanvasDisable: true,
			options: reactive({}),
			menuQuantity: 0,

			isTimeOut: false
		};
	},
	setup(props) {
		const { t } = useI18n();

		// Use `props` to get the correct translation key
		const translatedProductName = computed(() => {
			const translationKey = getTranslationKey('product', props.product, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.product.name;
		});

		const translatedProductDescription = computed(() => {
			const translationKey = getTranslationKey('product', props.product, 'description');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : props.product.description;
		});

		const translatedProductOptionName = (productOption) => {
			const translationKey = getTranslationKey('productOptionType', productOption, 'name');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : productOption.name;
		};

		const translatedProductOptionDescription = (productOption) => {
			const translationKey = getTranslationKey('productOptionType', productOption, 'description');
			let translation = t(`dynamic.${translationKey}`);
			return translation !== `dynamic.${translationKey}` ? translation : productOption.description;
		};

		return {
			t,
			translatedProductName,
			translatedProductDescription,
			translatedProductOptionName,
			translatedProductOptionDescription,
		};
	},
	beforeMount() {
		this.isLoading = true

		if (this.isCopilotRoute){
			this.pictureMenu = `${this.urlVenue}/world/image/product/${this.product.pictureMenu}`
		} else {
			this.pictureMenu = `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`
		}

		this.productImg = `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`
		this.productFoodImg = `${this.urlVenue}/world/img/${this.resourceId}/product/${this.product.pictureMenu}`

		if (this.product.category === 'Soft' || this.product.subcategory === 'Beer' || this.product.subcategory === 'Wine') {
			// return this.product.finalPrice = this.product.priceMemberUnit * 100;

		} else {
			if (this.reservationPromotion && this.reservationPromotion.shortDescription) {
				// const discountPercentage = parseFloat(this.reservationPromotion.shortDescription.match(/\d+(\.\d+)?/));
				// discountFactor = discountPercentage ? (100 - discountPercentage) / 100 : 1;
			}
		}
		this.checkImage();

	},
	mounted() {
		if (this.hasProductOptions) {
			this.initializeOptions();
		}
	},
	computed: {
		...mapGetters('config', ['weWelcomUrl']),
		...mapGetters('venue', ['businessName', 'urlVenue', 'resourceId']),
		...mapGetters('menu', ['productsCart']),
		productRange() {
			const range = [];
			for (let i = this.product.servingQtyMin; i <= this.product.servingQtyMax; i++) {
				range.push(i);
			}
			return range;
		},
		hasProductOptions() {
			return Array.isArray(this.product.productOptions) && this.product.productOptions.length > 0;
		},
		productOptionsOld() {
			return this.product.productOptions ? this.product.productOptions[0] : []
		},
		isOptionsWithinRangeOld() {
			const options = [...this.options];
			if (this.hasProductOptions) {
				return options.length >= this.productOptions.min && options.length <= this.productOptions.max;
			}
			return true;
		},
		isOptionsWithinRange() {
			if (this.hasProductOptions) {
				return this.product.productOptions.every(option => {
					const selectedOptions = this.options[option.id] || [];
					return selectedOptions.length >= option.min && selectedOptions.length <= option.max;
				});
			}
			return true;
		},
		matchingProductUnits() {
			return this.productsCart.find(unit => unit.sku === this.product.sku) || {cartItemCount: 0};
		},
		backdropAccount() {
			return !this.isCopilotRoute;
		},
		availability() {
			return this.product.menuCategoryProductInfo.availability === 1
		}
	},
	inject: {
		dateFormatterService: 'dateFormatterService',
	},
	methods: {
		...mapActions('menu', ['getProduct']),
		checkImage() {
			const img = new Image();
			img.onerror = () => {
				console.log('Error 404: No se pudo cargar la imagen de fondo.');
				this.showPictureMenu = null;
			};
			this.showPictureMenu = true;

			img.src = this.pictureMenu;
		},
		initializeOptions() {
			if (Array.isArray(this.product.productOptions)) {
				this.product.productOptions.forEach(option => {
					this.options[option.id] = [];
				});
			} else {
				console.warn('productOptions is not an array or is undefined:', this.product.productOptions);
			}
		},
		calculateValue(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		calculateLabel(increment) {
			return this.product.servingQtyMin + this.product.unitaryIncrement * increment;
		},
		sendProductToCart(event) {
			if (event) {
				event.stopPropagation();
			}
			this.getProduct(this.product)

			if (this.isCopilotRoute) {
				this.clearProductOptions();
				this.hideOffcanvas();
			}
			const normalizedOptions = normalizeChoices(this.options);
			console.log('Emitting add-product with options:', normalizedOptions);

			this.addProductToCart(this.product, 1, this.options);
			this.clearProductOptions();
			this.initializeOptions();
			this.hideOffcanvas();
		},
		clearProductOptions() {
			if (this.product.productOptions) {
				this.product.productOptions.forEach((productOption) => {
					const refName = `product_option_card_${this.product.sku}-${productOption.id}`;
					const ref = this.$refs[refName];
					if (ref && Array.isArray(ref)) {
						ref.forEach(r => r.clearSelectedOptions());
					} else if (ref) {
						ref.clearSelectedOptions();
					}
				});
			}

		},
		getImageAllergenUrl(alergeno) {
			return `assets/img/allergies/${alergeno.pictureName.toLowerCase()}.png`;
		},
		formatFloat(price) {
			if (isNaN(price)) {
				return price;
			}
			const formatted = this.dateFormatterService.formattedPrice(price)
			return formatted
		},
		getMinusButtonIcon() {
			return this.product.cartItemCount === 1 ? 'fa-trash' : 'fa-minus';
		},
		openInformationProduct(productSku/*, event*/) {
			this.getProduct(this.product)
			this.$nextTick(() => {
				let offcanvasElement = document.getElementById('offcanvas_' + productSku);

				if (offcanvasElement) {
					let offcanvasInstance = bootstrap.Offcanvas.getInstance(offcanvasElement);
					if (!offcanvasInstance) {
						offcanvasInstance = new bootstrap.Offcanvas(offcanvasElement, {
							keyboard: true // Permite cerrar el offcanvas con la tecla ESC
						});
					}
					if (offcanvasElement.classList.contains('show')) {
						// offcanvasInstance.hide();
					} else {
							offcanvasInstance.show();
					}
				}
			})

		},
		hideOffcanvas() {
			console.log('vamos a cerrar el canvas')
			const offcanvasElement = document.getElementById("offcanvas_" + this.product.sku);

			if (offcanvasElement) {
				const offCanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
				if (offCanvas) {
					offCanvas.hide();
				}
			}
		},
		updateOptionsOld(options) {
			options ? this.options = options : this.options = []
		},
		updateOptions(optionId, selectedOptions) {
			this.options[optionId] = selectedOptions;
		},
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		addUnit() {
			if (event) {
				event.stopPropagation();
			}
			let product = this.matchingProductUnits
			store.commit('menu/addUnit', {product})
		},
		subtractUnit() {
			if (event) {
				event.stopPropagation();
			}
			let product = this.matchingProductUnits

			this.confirmSubtractUnit(product)
		},
		confirmSubtractUnit(product) {
			if (event) {
				event.stopPropagation();
			}
			store.commit('menu/subtractUnit', {product})
			this.productToRemove = null;
			// hideAllModals()
		},
		closeModal() {
			if (event) {
				event.stopPropagation();
			}
			hideAllModals()
		},
	}
}
</script>

<style scoped>

.product-commander {
	background-color: var(--bs-white);
	/*color: var(--bs-white);*/
	color: var(--bs-primary);
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid var(--bs-gray);
	padding: .3rem;
}

.commander-title {
	font-size: 16px;
	display: -webkit-box;
	-webkit-line-clamp: 1; /* Número de líneas permitidas */
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.commander-btns {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 5px;
}
</style>
