<template>
	<div class="col-12" v-if="!isProductOption">
		<ul class="nav nav-tabs mb-2">
			<li class="nav-item">
				<button
					ref="buttons"
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv active"
				>
					<i class="fa-solid fa-star fa-xl"></i>
				</button>
			</li>
		</ul>
		<div class="col-12">
			<div class="row g-0">
				<TpvCardProduct
					v-for="product in quickAccessProducts"
					:key="product.id"
					:product="product"
					@product-selected-with-options="viewProductOptions"
				/>
			</div>
		</div>
	</div>
	<div class="col-12" v-else>
		<div class="row g-1">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<button
						ref="buttons"
						class="btn btn-outline-secondary btn-welcom btn-nav-tpv"
						@click="closeProductSelectedForOptions"
					>
						Volver
					</button>
				</li>
				<template v-if="selectedProductOptions.length > 1">
					<li class="nav-item" v-for="category in selectedProductOptions" :key="category.id">
						<button
							ref="buttons"
							class="btn btn-outline-secondary btn-welcom btn-nav-tpv"
							@click="selectedCategoryMenuProduct(category.id)"
						>
							{{ category.name }}
						</button>
					</li>
				</template>
			</ul>
			<template
				v-for="option in quickAccessProducts"
				:key="option.id"
			>
				<div
					class="col-12 col-md-3 col-xl-2"
					v-for="choseOption in option.options"
					:key="choseOption.id"
				>
					<button
						ref="buttons"
						class="btn btn-primary w-100 btn-tpv"
						@click="selectedOption(choseOption.id)"
					>
						{{ choseOption.name }}
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import TpvCardProduct from "@/components/tpv/tpvCardProduct/TpvCardProduct.vue";
import {normalizeChoices} from "@/utils";

export default {
	name: 'TpvListProductsTopChoice',
	components: {TpvCardProduct},
	data() {
		return {
			isProductOption: false,
			selectedProductOptions:null,

		}
	},
	computed: {
		...mapGetters('menu', ['menus']),

		quickAccessProducts() {
			const quickAccessProducts = [];

			this.menus.forEach(menu => {
				if (Array.isArray(menu.menuCategories)) {
					menu.menuCategories.forEach(menuCategory => {
						if (Array.isArray(menuCategory.children) && menuCategory.children.length > 0) {
							menuCategory.children.forEach(firtsChildren => {
								if (Array.isArray(firtsChildren.children) && firtsChildren.children.length > 0) {
									firtsChildren.children.forEach(secondChildren => {
										if (Array.isArray(secondChildren.menuCategories)) {
											secondChildren.menuCategories.forEach(category => {
												if (Array.isArray(category.products)) {
													category.products.forEach(product => {
														if (product.quickAccess.isQuickAccess) {
															quickAccessProducts.push(product);
														}
													});
												}
											});
										}
									});
								} else if (Array.isArray(firtsChildren.menuCategories)) {
									firtsChildren.menuCategories.forEach(category => {
										if (Array.isArray(category.products)) {
											category.products.forEach(product => {
												if (product.quickAccess.isQuickAccess) {
													quickAccessProducts.push(product);
												}
											});
										}
									});
								}
							});
						} else if (Array.isArray(menuCategory.products)) {
							menuCategory.products.forEach(product => {
								if (product.quickAccess.isQuickAccess) {
									quickAccessProducts.push(product);
								}
							});
						}
					});
				}
			});

			console.log(quickAccessProducts);

			return quickAccessProducts;
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.adjustFontSize();
		});
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods:{
		viewProductOptions({ product, productOptions, isProductOption }) {
			this.productSelectedForOptions = product;
			this.selectedProductOptions = productOptions;
			this.isProductOption = isProductOption;

			const normalizedOptions = normalizeChoices(this.selectedProductOptions);
			console.log('Emitting add-product with options:', normalizedOptions);
		},

		selectedOption(chosenOptionId) {
			const options = {};
			if (Array.isArray(this.selectedProductOptions)) {
				this.selectedProductOptions.forEach(option => {
					options[option.id] = [];
				});
			} else {
				console.warn('selectedProductOptions is not an array or is undefined:', this.selectedProductOptions);
			}

			const selectedOptionGroup = this.selectedProductOptions.find(option => option.options.some(o => o.id === chosenOptionId));
			if (selectedOptionGroup) {
				options[selectedOptionGroup.id] = [chosenOptionId];
			}

			store.commit('menu/addProductToCart', {
				product: this.productSelectedForOptions,
				units: 1,
				options: options
			});

			store.commit('tpv/setViewSectionAccounts', "createAccount")
			this.closeProductSelectedForOptions()
		},

		closeProductSelectedForOptions(){
			this.productSelectedForOptions = null;
			this.isProductOption = false;
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	}
}
</script>

