<template src="./copilotMainComponent.html"></template>

<script>
import * as bootstrap from 'bootstrap';
import GeneratorQr from "@/components/generatorQr/GeneratorQr";
import CopilotContainerReservation from "@/components/copilotContainerReservation/CopilotContainerReservation";
import CopilotOrdersContainer from "@/components/copilotOrdersContainer/CopilotOrdersContainer";
import ModalScannerQR from "@/components/modalScannerQR/ModalScannerQR.vue";
import FooterWelcom from "@/core/FooterWelcom";
import {mapGetters} from "vuex";
import DatePicker from "@/components/pickdate/DatePicker";
import store from "@/store";
import {
	filterReservationsByService,
	hideAllModals,
	hideAllOffcanvas,
	showModal,
} from "@/utils";
import Commander from "@/components/commander/Commander";
import CheckCopilotModal from "@/components/checkCopilotModal/CheckCopilotModal";
import CopilotPendingNotifications from "@/components/copilotPendingNotifications/CopilotPendingNotifications";
import ReservationComponent from "@/components/reservationComponent/ReservationComponent";
import DismissButton from "@/core/DismissButton";
import CopilotOrdersContainerKitchen from "@/components/copilotOrdersContainerKitchen/CopilotOrdersContainerKitchen";
import SittingIcon from "@/core/icons/SittingIcon";
import CopilotCardSelectTable from "@/components/copilotCardSelectTable/CopilotCardSelectTable";
import CopilotSitting from "@/components/copilotSitting/CopilotSitting";
import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import TableIcon from "@/core/icons/TableIcon";
import CopilotOffcanvasHybridRole from "@/components/copilotOffcanvasHybridRole/CopilotOffcanvasHybridRole";
import GoogleIcon from "@/core/icons/googleIcon";
import ModalQrGoogle from "@/components/modalQrGoogle/ModalQrGoogle";
import ModalConfirmDishClassChanged from "@/components/modalConfirmDishClassChanged/ModalConfirmDishClassChanged";
import CopilotModalQrReservation from "@/components/copilotModalQrReservation/CopilotModalQrReservation";
import {walkingService} from "@/service/walkingService";
import CopilotOffcanvasUpdatePaxReservation
	from "@/components/copilotOffcanvasUpdatePaxReservation/CopilotOffcanvasUpdatePaxReservation";
import CopilotOffcanvasHistoricAccountReservation
	from "@/components/copilotOffcanvasHistoricAccountReservation/CopilotOffcanvasHistoricAccountReservation";
import CopilotOffcanvasFinishReservation from "@/components/copilotOffcanvasFinishReservation/CopilotOffcanvasFinishReservation";
import ModalRemoveProduct from "@/components/modalRemoveProduct/ModalRemoveProduct";
import {useI18n} from "vue-i18n";
import CopilotDetailsUserReservation from "@/components/copilotDetailsUserReservation/CopilotDetailsUserReservation";
import CopilotMenuFinisher from "@/components/copilotMenuFinisher/CopilotMenuFinisher";
import CopilotCommander from "@/components/copilotCommander/CopilotCommander";
import {WebSocketClient} from "@/service/webSocketClient";

export default {
	name: 'CopilotMainComponent',
	inject: {
		reservationRepository: 'reservationRepository',
		userRepository: 'userRepository',
		speakService: 'speakService',
		listenService: 'listenService',
		harvisService: 'harvisService',
		cameraService: 'cameraService',
		copilotRepository: 'copilotRepository',
		dateFormatterService: 'dateFormatterService',
		walkingService: 'walkingService',
	},
	data() {
		return {
			hasNewAccount: false,
			walkInName: null,
			walkInPax: null,
			walkInRoomId: null,

			isCreateWalkIn: false,
			isLoadingBtnWalkin:false,

			showErrorMessageWalkIn: false,
			errorMessageWalkIn: null,
			viewClosedAccounts: false,
			copilotButtonTextAccount: 'Cuenta entregada',
			selectedAreaFilterTitle: 'Comida',
			isLoadingNotification: false,
			isLoadingOrder: false,
			elapsedTime: '',
			commentsButtonText: 'Enviar comentario',
			showErrorMessageComments: false,
			errorMessageComments: '',
			commentsButtonDisabled: true,
			isMenuOpen: false,

			navbarVisible: true,
			lastScrollPosition: 0,
			selectedRoleLocal: this.selectedRole,
			productsAcceptedList: [],
			productsFinishedList: [],

			isLoadingMarkToReady:false,
			isOpen: false,
			selectedAreaFilter: null,

			isLoadingOrderReady:false,

			webSocketClient: WebSocketClient,
			wsClientStatus: null,


		};
	},
	setup() {
		const { t } = useI18n();
		const showError = (value) => {
			// Obtiene el mensaje de error
			const errorMessage = t('copilot.offcanvasWalkin.errorMessageWalkin');

			console.log('Mensaje completo:', errorMessage);
			const errorMessages = errorMessage.split('|').map(msg => msg.trim());
			console.log('Mensajes de error:', errorMessages);

			if (errorMessages.length > value) {
				return errorMessages[value];
			} else {
				return errorMessages[0];
			}
		};


		return { t , showError};
	},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('config', ['websocketUrl', 'websocketPort','wsConnectionStatus']),
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName', 'venueId', 'reservationManagerUrl', 'menuServices']),
		...mapGetters('copilot', ['view','selectedProductOrdersToPrepared','reservationsWithProducts','availableRoles', 'pendingOrders', 'serviceEventPendingService', 'selectedRole', 'selectedDate', 'reservations', 'accounts', 'notifications', 'assistantNotifications', 'isPlayNotificationSound', "isLoadingGetReservations", "isShowToastScanQr", "newNotification", "isKitchenSelectedRole", "commanderReservation", 'notificationMode', 'tables', "showTicketRequestNotifications"]),
		walkInButtonDisabled() {
			return !this.walkInName || !this.walkInPax;
		},
		filteredReservesServicesOld() {
			// Filtrar las reservas por servicio utilizando la función intacta
			let services = filterReservationsByService(this.reservations);

			// Organizar las reservas según el filtro seleccionado
			if (this.selectedAreaFilter === 'table') {
				// Ordenar por número de mesa de menor a mayor
				services.forEach(service => {
					service.serviceReservations.sort((a, b) => {
						return a.table - b.table;
					});
				});
			} else if (this.selectedAreaFilter === 'hour') {
				// Ordenar por hora de inicio de menor a mayor
				services.forEach(service => {
					service.serviceReservations.sort((a, b) => {
						const timeA = parseInt(a.startDate.split(':')[0]);
						const timeB = parseInt(b.startDate.split(':')[0]);
						return timeA - timeB;
					});
				});
			}

			return services;
		},
		filteredReservesServices() {
			const reservations = this.reservations.filter(reservation => reservation.roomName !== "Barra"); // Available in your module
			let allServiceSchedules = store.getters['venue/serviceSchedule']; // Accessing service schedules for day 1 (since they are all the same currently)

			console.log("estos son los servicios", allServiceSchedules)
			let serviceSchedules = allServiceSchedules[1];
			// Initialize the services array
			let services = [];

			// If there are service schedules defined, process them
			if (serviceSchedules && serviceSchedules.length > 0) {
				// Sort serviceSchedules by start time
				serviceSchedules.sort((a, b) => a.timeStart.localeCompare(b.timeStart));

				// Iterate through service schedules and categorize reservations by service
				for (let i = 0; i < serviceSchedules.length; i++) {
					const service = serviceSchedules[i];
					const serviceReservations = reservations.filter(reservation => {
						const startTime = parseInt(reservation.startDate.split(':')[0]);
						const serviceStart = parseInt(service.timeStart.split(':')[0]);
						const serviceEnd = parseInt(service.timeEnd.split(':')[0]);

						// Handle services that span across midnight (e.g., Noche: 23:00 -> 04:59)
						let isWithinTimeRange;
						if (serviceEnd < serviceStart) {
							// Service spans midnight, so we check if the reservation falls either before or after midnight
							isWithinTimeRange = (
								(startTime >= serviceStart && startTime <= 23) || // From service start until midnight
								(startTime >= 0 && startTime <= serviceEnd)      // From midnight until service end
							);
						} else {
							// Normal time range comparison
							isWithinTimeRange = startTime >= serviceStart && startTime <= serviceEnd;
						}

						// Exclude inactive reservations
						const isActive = reservation.status !== 0;

						return isWithinTimeRange && isActive;
					});

					// Add the service and its reservations to the services array
					if (serviceReservations.length > 0) {
						services.push({ serviceName: service.name, serviceReservations });
					}
				}

				// Handle reservations that do not fall into any defined service schedule
				const uncategorizedReservations = reservations.filter(reservation => {
					const startTime = parseInt(reservation.startDate.split(':')[0]);
					return !serviceSchedules.some(service => {
						const serviceStart = parseInt(service.timeStart.split(':')[0]);
						const serviceEnd = parseInt(service.timeEnd.split(':')[0]);

						// Check if reservation falls within this service's time range
						if (serviceEnd < serviceStart) {
							// Service spans midnight
							return (
								(startTime >= serviceStart && startTime <= 23) ||
								(startTime >= 0 && startTime <= serviceEnd)
							);
						} else {
							// Normal time range
							return startTime >= serviceStart && startTime <= serviceEnd;
						}
					});
				});

				// Assign uncategorized reservations to the closest service
				uncategorizedReservations.forEach(reservation => {
					const reservationTime = parseInt(reservation.startDate.split(':')[0]);
					let closestService = null;
					let minTimeDifference = Number.MAX_SAFE_INTEGER;

					// Find the closest service based on time difference
					serviceSchedules.forEach(service => {
						const serviceStart = parseInt(service.timeStart.split(':')[0]);
						const timeDifference = Math.abs(serviceStart - reservationTime);

						if (timeDifference < minTimeDifference) {
							minTimeDifference = timeDifference;
							closestService = service;
						}
					});

					// If a closest service is found, add the reservation to it
					if (closestService) {
						let service = services.find(s => s.serviceName === closestService.name);
						if (!service) {
							service = { serviceName: closestService.name, serviceReservations: [] };
							services.push(service);
						}
						service.serviceReservations.push(reservation);
					}
				});
			}

			// If no services are defined, create a "Sin Servicio" for all reservations
			if (services.length === 0 && reservations.length > 0) {
				services.push({
					serviceName: "Sin Servicio",
					serviceReservations: reservations
				});
			}

			// Sort the reservations based on the selected filter
			if (this.selectedAreaFilter === 'table') {
				services.forEach(service => {
					service.serviceReservations.sort((a, b) => a.table - b.table);
				});
			} else if (this.selectedAreaFilter === 'hour') {
				services.forEach(service => {
					service.serviceReservations.sort((a, b) => {
						const timeA = parseInt(a.startDate.split(':')[0]);
						const timeB = parseInt(b.startDate.split(':')[0]);
						return timeA - timeB;
					});
				});
			}

			return services;
		},

		rolSelectedName() {
			const role = this.availableRoles.find(i => i.id === this.selectedRole);
			// console.log(role.name)
			hideAllOffcanvas();
			return role ? role.name : 'Todos los mensajes';
		},
		sortedReservationsWithProducts() {
			return this.reservationsWithProducts
				.filter(reservation => {
					const orderedItems = reservation.orderedItems;

					if (reservation.finishedDateTime || !orderedItems || !Array.isArray(orderedItems)) {
						return false;
					}

					const filteredItems = orderedItems.filter(item =>
						item.statusProductOrder > 0 &&
						item.statusProductOrder < 3 &&
						item.statusProductOrder < 100 &&
						item.requirementProductCopilotRoles.some(role => role.name.includes('Barra'))
					);

					return filteredItems.length > 0;
				})
				.sort((a, b) => {
					if (a.cartsession && !b.cartsession) {
						return -1;
					} else if (!a.cartsession && b.cartsession) {
						return 1;
					} else {
						return 0;
					}
				});
		},

		isHybridRol(){
			return typeof this.view === 'string' && this.view.includes('Híbrido');
		},
		isVisibleDrinkOrdersOffCanvas(){
			return this.availableRoles.find(role => role.id === this.selectedRole && role.name === "TPV + Barra")
		},
		notificationReview() {
			const review = this.notifications[0].reservation.userReviews.find(review => review.userId === this.notifications[0].creatorUserId);
			return review ? review.score : null;
		},
	},
	created() {
		this.cameraService.sourceComponent = 'Copilot';
	},
	async mounted() {
		this.updateElapsedTime();

		window.addEventListener('scroll', this.handleScroll);
		document.addEventListener('click', this.handleDocumentClick);

		const savedFilter = localStorage.getItem('selectedAreaFilter');

		if (savedFilter !== null && savedFilter !== undefined) {
			this.selectedAreaFilter = savedFilter;
		}
		setInterval(() => {
			this.updateElapsedTime();
		}, 1000);
	},
	beforeUnmount() {
		document.removeEventListener('click', this.handleDocumentClick);
		window.removeEventListener('scroll', this.handleScroll);
	},
	watch: {
		selectedDate() {
			this.updateReservations()
		},

		selectedRoleLocal(newValue) {
			if (newValue !== this.selectedRole) {
				store.commit('copilot/setSelectedRole', {selectedRole: newValue});
			}
		},

		selectedRole: {
			handler(newValue) {
				this.selectedRoleLocal = newValue;
			},
			immediate: true
		},

		selectedAreaFilter(newValue) {
			localStorage.setItem('selectedAreaFilter', newValue);
		},

		watch: {
			wsConnectionStatus(newValue) {
				console.log('Nuevo valor del WSConnection', newValue);
			},
		},
	},
	components: {
		'copilot-container-reservation': CopilotContainerReservation,
		'generator-qr': GeneratorQr,
		'copilot-orders-container': CopilotOrdersContainer,
		DatePicker,
		modalScannerQr: ModalScannerQR,
		FooterWelcom,
		'commander-welcom': Commander,
		CheckCopilotModal,
		CopilotPendingNotifications,
		ReservationComponent,
		DismissButton,
		CopilotOrdersContainerKitchen,
		SittingIcon,
		CopilotCardSelectTable,
		CopilotSitting,
		OrdersProductCard,
		TableIcon,
		CopilotOffcanvasHybridRole,
		GoogleIcon,
		ModalQrGoogle,
		ModalConfirmDishClassChanged,
		CopilotModalQrReservation,
		CopilotOffcanvasUpdatePaxReservation,
		CopilotOffcanvasHistoricAccountReservation,
		CopilotOffcanvasFinishReservation,
		ModalRemoveProduct,
		SpinnerIcon,
		CopilotDetailsUserReservation,
		CopilotMenuFinisher,
		CopilotCommander
	},
	methods: {
		updateFilter(filter) {
			this.selectedAreaFilter = filter;
		},
		paymentMethodName(message){
			if (message.includes("Pago en efectivo")){
				return "Efectivo";
			} else if (message.includes("Pago con tarjeta")){
				return "Tarjeta";
			} else if (message.includes("pago online")){
				return "Online";
			}
			return "";
		},
		notificationPaymentOnline(){
			return this.paymentMethodName(this.notifications[0].message) === 'Online'
		},
		closeOffcanvas() {
			const offcanvasElement = document.getElementById('offcanvas-filter-reservation');
			const offcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
			if (offcanvas) {
				offcanvas.hide();
			}
		},
		handleScroll() {
			const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
			this.navbarVisible = currentScrollPosition <= this.lastScrollPosition;
			this.lastScrollPosition = currentScrollPosition;
			this.isMenuOpen = false
		},

		async updateReservations() {
			// this.isLoading = true;
			await store.dispatch('copilot/updateReservations');
			this.isLoading = false;
		},

		async sendNotification(notificationId) {
			this.isLoadingOrderReady= true;
			this.copilotButtonTextAccount = 'Enviando ... <i class="fas fa-spinner fa-spin"></i>'
			const result = await this.copilotRepository.acknowledgeNotification(notificationId);
			if (result.result === 0) {
				console.log('Actualizando notificaciones')
				// await store.dispatch('copilot/updateOrdersAndNotifications')
				this.copilotButtonTextAccount = 'Cuenta entregada';
				this.isLoadingOrderReady= false;
			}
			this.isLoadingOrderReady= false;
		},

		clearNewReservationForm() {
			const offcanvasElement = document.getElementById('offcanvas-new-reserve');

			let myOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
			if (myOffcanvas == null) {
				myOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
			}
			myOffcanvas.hide();
			/*store.commit("copilot/setToast", {
				toast: {
					toastMessage: `Se ha creado la reserva correctamente.`,
					showToast: true,
					idToast: "correctlyToast"
				}
			});*/
		},

		async handleNewWalkinButton(event) {
			event.preventDefault();
			this.isLoadingBtnWalkin = true;
			this.showErrorMessageWalkIn = false;
			this.errorMessageWalkIn = "";
			this.isCreateWalkIn = true;

			let data = {
				name: this.walkInName,
				pax: this.walkInPax,
			};

			if (this.walkInPax < 0) {
				this.isLoadingBtnWalkin = false;
				this.showErrorMessageWalkIn = true;


				this.errorMessageWalkIn = this.showError(0);
				console.log(this.errorMessageWalkIn); // Muestra el mensaje en la consola

				this.isCreateWalkIn = false;

				return;
			}
			//const service = walkingService(this.$store);
			let createWalkInResult = await walkingService.create(data);

			if (createWalkInResult.result === 0) {
				this.isLoadingBtnWalkin = false
				const modalWalkIn = document.getElementById('modal-walkin');
				const bsModalWalkIn = bootstrap.Offcanvas.getInstance(modalWalkIn);

				if (bsModalWalkIn) {
					bsModalWalkIn.hide();
				}

				this.walkInName = '';
				this.walkInPax = '';
				this.walkInRoomId = null;
			} else {
				this.isLoadingBtnWalkin = false

				this.showErrorMessageWalkIn = true;
				this.errorMessageWalkIn = "No se ha podido asignar mesa.";
			}

			this.isCreateWalkIn = false;
		},

		updateElapsedTime() {
			if (!this.notifications.length || !this.notifications[0].dateCreated) {
				return;
			}
			this.elapsedTime = this.dateFormatterService.formattedTimeChronometer(this.notifications[0].dateCreated)
		},

		handleViewClosedAccounts() {
			this.viewClosedAccounts = !this.viewClosedAccounts
		},

		addOneDay() {
			const newDate = new Date(this.selectedDate);
			newDate.setDate(newDate.getDate() + 1);
			store.commit('copilot/setSelectedDate', {selectedDate: newDate.toISOString().substr(0, 10)})
		},

		subtractOneDay() {
			const newDate = new Date(this.selectedDate);
			newDate.setDate(newDate.getDate() - 1);
			store.commit('copilot/setSelectedDate', {selectedDate: newDate.toISOString().substr(0, 10)})
		},

		formatPrice(price) {
			if (isNaN(price)) {
				return price;
			}
			const formattedTotal = price.toFixed(2);
			return formattedTotal.replace('.', ',') + '€';
		},

		openRestoo() {
			window.open(this.reservationManagerUrl, 'BookingProviderWindow', 'width=800,height=600');
		},

		openSection(sectionName) {
			const sectionId = `collapseService${sectionName}`;
			const sectionElement = document.getElementById(sectionId);
			if (sectionElement) {
				sectionElement.classList.add('show');
			}
		},

		openSectionByHour() {
			const currentHour = new Date().getHours();
			if (currentHour >= 6 && currentHour < 11) {
				this.openSection('Desayuno');
			} else if (currentHour >= 11 && currentHour < 16) {
				this.openSection('Comida');
			} else if (currentHour >= 16 && currentHour < 22) {
				this.openSection('Cena');
			} else {
				this.openSection('Default');
			}
		},

		closeModalWalkin() {
			const offcanvasElement = document.getElementById('modal-walkin');

			let myOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
			myOffcanvas.hide();
		},

		closeModal() {
			hideAllModals();
		},

		async handleCancelOrderHarvis() {
			await this.listenService.stopTranscription()
		},

		async handleordenar() {
			showModal('modal-harvis')
			const result = await this.listenService.receiveInstruction()
			console.log('Resultado de listenService: ', result);
			const resultHarvisMessage = await this.harvisService.call(result);
			console.log({resultHarvisMessage: resultHarvisMessage})
			await this.speakService.speak(resultHarvisMessage, true);
		},

		async repeatHandleOrdenar() {
			const result = await this.listenService.receiveInstruction()
			console.log('Resultado de listenService: ', result);
			const resultHarvisMessage = await this.harvisService.call(result);
			await this.speakService.speak(resultHarvisMessage, true);
		},

		showModal(idModal) {
			showModal(idModal);
		},

		async selectTableWalkin(event) {
			const selectedTableId = parseInt(event.target.value);
			console.log("Número mesa seleccionado:", selectedTableId);
			this.walkInRoomId = selectedTableId;
		},

		// Función para manejar clics fuera del menú y dentro de las opciones del menú
		handleDocumentClick(event) {
			const wrapper = this.$refs.wrapper;
			if (wrapper && !wrapper.contains(event.target) && this.isMenuOpen) {
				this.isMenuOpen = false;
			}
		},

		handleOptionClick(option) {
			this.isMenuOpen = false;
			this.isOpen = false;

			if (option === 'camera') {
				this.isOpen = false;
				this.cameraService.showModalCameraScan();
			}
			if (option === 'walkin') {
				this.isOpen = false;

				const offcanvasElement = document.getElementById('modal-walkin');

				let myOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);

				myOffcanvas = new bootstrap.Offcanvas(offcanvasElement);

				myOffcanvas.show();

			}

			if (option === 'plano') {
				this.isOpen = false;

				this.openRestoo();
			}

			if (option === 'newReserve') {
				this.isOpen = false;

				const offcanvasElement = document.getElementById('offcanvas-new-reserve');

				let myOffcanvas = bootstrap.Offcanvas.getInstance(offcanvasElement);
				myOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
				myOffcanvas.show();
			}

			if (option === 'google') {
				showModal('modal-qr-google')
			}
		},
		toggleSpeedDial() {
			this.isOpen = !this.isOpen;
		},
	},
}
</script>
<style>
#visualizer {
	display: flex;
	align-items: flex-end;
	height: 80px;
	width: 100%;
	overflow: visible;
}

#visualizer .bar {
	width: 15px;
	height: 0;
	background-color: #4CAF50;
	margin: 0 3px;
	transition: height 0.1s ease;
	min-height: 1px;
}
</style>
