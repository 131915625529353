import {speakService} from "@/service/speakService";
// import store from "@/store";
import {hideAllModals} from "@/utils";

export class ListenService {

	constructor() {
		this.actionsDiccionary = ['walking', 'activa', 'finaliza', 'paso'];
		this.recognizer = null;
	}

	async startTranscription() {
		return new Promise((resolve, reject) => {
			if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
				speakService.speak('Hay error con el listenService antes de utilizarlo')
				reject('El reconocimiento de voz no está soportado en este navegador.');
				return;
			}
			const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
			this.recognizer = new SpeechRecognition();
			this.recognizer.continuous = false;
			this.recognizer.lang = 'es-ES'; // Cambia al código de idioma adecuado si es necesario

			// store.commit("copilot/setToast", {toast:{toastMessage: "Escuchando", showToast:true, idToast: "correctlyToast"}})
			this.recognizer.start();

			this.recognizer.onresult = (event) => {
				const result = event.results[event.results.length - 1];
				const transcript = result[0].transcript;
				console.log('Transcripción:', transcript);
				resolve(transcript); // Resuelve la promesa con el resultado de la transcripción
			};

			this.recognizer.onend = () => {
				console.log('La transcripción ha finalizado automáticamente');
				reject('La transcripción finalizó sin resultados.'); // Rechaza la promesa si no hay resultados
			};

			this.recognizer.onerror = (event) => {
				speakService.speak('Hay error con el listenService')
				console.error('Error en el reconocimiento de voz:', event.error);
				reject(event.error); // Rechaza la promesa con el error del reconocimiento de voz
			};
		});
	}

	async stopTranscription() {
		if (this.recognizer) {
			await this.recognizer.stop();
			console.log('Reconocimiento de voz detenido.');
		}
	}

	async receiveInstruction(){
		try{
			const phrase = await this.startTranscription();
			const phraseArray = phrase.split(" ");
			const action = this.findAction(phraseArray);
			const result ={action};

			switch (action) {
				case 'paso':
					result.action = "walking"
					result.name = this.getNameOfByTheWay(phraseArray)
					result.pax = this.findNumber(phraseArray);
					return result;
				case 'walking':
					result.name = phraseArray[0]
					result.pax = this.findNumber(phraseArray);
					return result;
				case 'activa':
				case 'finaliza':
					result.table = this.findNumber(phraseArray);
					return result;
			}

			return result

		}catch (e) {
			console.log('Ha surgido un error', e);
			hideAllModals()
			return null
		}

	}

	findAction(phrase){
		const match = phrase.filter(word => this.actionsDiccionary.includes(word));
		return match.length > 0 ? match[0] : null;
	}

	findNumber(phrase){
		const stringNumbers = {
			"cero": 0,
			"uno": 1,
			"dos": 2,
			"tres": 3,
			"cuatro": 4,
			"cinco": 5,
			"seis": 6,
			"siete": 7,
			"ocho": 8,
			"nueve": 9,
		};

		let number = phrase.map(word => {
			if(Object.prototype.hasOwnProperty.call(stringNumbers,word)){
				return stringNumbers[word]
			}else{
				let number = parseFloat(word);
				return isNaN(number) ? null : number;
			}
		}).filter(number => number !== null);

		return number.length > 0 ? number[0] : null;
	}

	getNameOfByTheWay(array) {
		const indexOfDe = array.indexOf("de");
		if (indexOfDe === -1) {
			return "";
		}
		return array.slice(0, indexOfDe).join(" ");
	}
}

export const listenService = new ListenService();
