<template>
  <div :id="category.id + '-' + category.name">
    <!-- Nombre de la categoría -->
    <div v-if="category.name && level !== 1" class="">
      <div :class="[levelClass]" class="mb-0">{{ category.name }}</div>
    </div>

    <!-- Productos de la categoría -->
    <template v-if="category.products && category.products.length">
      <ProductCard
          v-for="product in category.products"
          :key="product.sku"
          @add-product="addProductToCart"
          :product="product"
          @subtract-unit="subtractUnit"
          @add-unit="addUnit"
      ></ProductCard>
    </template>

    <!-- Renderizar subcategorías recursivamente -->
    <template v-if="hasSubcategories">
      <ProductsCategory
          v-for="subCategory in subcategories"
          :key="subCategory.id"
          :category="subCategory"
          :level="level + 1"
      ></ProductsCategory>
    </template>
  </div>
</template>

<script>
import ProductCard from "@/components/copilotCommander/components/ProductCard";
import store from "@/store";
export default {
	name: "ProductsCategory",
	components: {ProductCard},
	props: {
		category: {
			type: Object,
			required: true,
		},
		level: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {};
	},
	computed: {
		hasSubcategories() {
			return (
				(this.category.children && this.category.children.length > 0) ||
				(this.category.menuCategories && this.category.menuCategories.length > 0)
			);
		},
		subcategories() {
			return [
				...(this.category.children || []),
				...(this.category.menuCategories || []),
			];
		},
		levelClass() {
			switch (this.level) {
				case 1:
					return ;
				case 2:
					return 'h3 level-1';
				case 3:
					return 'h4 level-2';
				case 4:
					return 'h5 level-3';
				default:
					return '';
			}
		},
	},
	watch: {
		productsCart: {
			handler(newCart, oldCart) {
				const oldCartSkus = oldCart.map(product => product.sku);
				const newCartSkus = newCart.map(product => product.sku);

				// Add 'addProduct' class for new items in the cart
				newCart.forEach(product => {
					if (!oldCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.add('addProduct');
						}
					}
				});

				// Remove 'addProduct' class for items no longer in the cart
				oldCart.forEach(product => {
					if (!newCartSkus.includes(product.sku)) {
						const element = document.getElementById(product.sku);
						if (element) {
							element.classList.remove('addProduct');
						}
					}
				});

				// Update local storage with the new cart state
				localStorage.setItem('cart', JSON.stringify(newCart));
			},
			deep: true
		},
	},
	methods: {
		addProductToCart(product, units, options) {
			store.commit('menu/addProductToCart', {product, units, options})
		},
		addUnit(product) {
			store.commit('menu/addUnit', {product})
		},
		subtractUnit(product) {
			store.commit('menu/subtractUnit', {product})
		},
	},
}
</script>

<style scoped>
.level-1 {
  background-color: var(--bs-primary-scale-500);
}
.level-2 {
  background-color: var(--bs-primary-scale-400);
}
.level-3 {
  background-color: var(--bs-primary-scale-300);
}
</style>
