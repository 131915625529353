<template>
	<label class="fw-bold">{{ optionType.name }}</label>
	<button type="button" class="btn btn-outline-primary" @click="handleShowDisabledProducts">{{showDisabledProductsTextButton}}</button>
	<div class="container">
		<div class="row g-0 fw-bold">
			<div class="col-6">
				Nombre
			</div>
			<div class="col-4">
				Suplemento
			</div>
			<div class="col-2">
				Activar
			</div>
		</div>
	</div>
	<div v-for="(option) in options" :key="option.id" class="col-12 col-md-6 m-0">
		<copilot-daily-menu-option :option="option" @update-option="updateOption"></copilot-daily-menu-option>
	</div>
	<div class="col-12">
		<button @click="addMenuProductOption(optionTypeIndex)" class="btn btn-primary w-100 btn-sm">
			<i class="fas fa-plus"></i> Añadir Opción a {{ optionType.name }}
		</button>
	</div>
</template>

<script>
import CopilotDailyMenuOption from "@/components/CopilotDailyMenuOption/CopilotDailyMenuOption";
import {useI18n} from "vue-i18n";


export default {
	name: "CopilotOptionsDailyMenu",
	components: {
		CopilotDailyMenuOption,
	},
	props: {
		optionType: {
			type: Object,
			required: true,
			default: null,
		},
		optionTypeIndex: {
			type: Number,
			required: true,
			default: null,
		},
		updateOption: {
			type: Function,
			required: true,
			default: null,
		},
		addMenuProductOption: {
			type: Function,
			required: true,
			default: null,
		},
	},
	data() {
		return {
			showDisabledProducts: false,
		}
	},
	setup() {
		const { t } = useI18n();

		return {
			t
		};
	},
	computed:{
		options() {
			const filteredOptions = this.showDisabledProducts
				? this.optionType.options
				: this.optionType.options.filter(option => option.enabled === 1);

			return filteredOptions.sort((a, b) => {
				if (a.isNewOption === b.isNewOption) {
					// If both are either new or existing, sort alphabetically
					return a.isNewOption ? 0 : a.name.localeCompare(b.name);
				}
				// Existing options come before new options
				return a.isNewOption - b.isNewOption;
			});
		},
		showDisabledProductsTextButton(){
			return this.showDisabledProducts
				? this.t('copilot.ocultarLosProductosInactivos')
				: this.t('copilot.mostrarLosProductosInactivos');
		},
	},
	methods:{
		handleShowDisabledProducts(){
			this.showDisabledProducts = !this.showDisabledProducts
		}
	}
}
</script>

<style scoped>

</style>
