<template>
	<div class="container-fluid g-0 vh-100 overflow-hidden" style="font-size: 14px">
		<div class="row g-0">
			<main class="col-10 col-lg-11">
				<div style="height: 50vh" class="row g-0">
					<div class="col-5 col-lg-4 h-100 p-1">
						<TpvListAccounts
							v-if="viewSectionAccounts === 'allAccounts'"
						/>

						<TpvAccountSelected
							v-if="viewSectionAccounts === 'accountSelected'"
						/>

						<TpvNewOrder
							v-if="viewSectionAccounts === 'createAccount'"
						/>
					</div>
					<div class="col-7 col-lg-8 h-100 p-1">
						<TpvMenuCategories/>
					</div>
				</div>
				<div style="height: 50vh" class="row g-0">
					<div class="col-5 col-lg-4 p-1 h-100">
						<TpvDrinksToPrepare />
					</div>
					<div class="col-7 col-lg-8 p-1 h-100">
						<section class="box-shadow m-0 h-100 p-1 scroll-y">
							<div class="row g-2 h-100">
								<TpvProductsByCategory
									v-if="viewSectionItems === 'products'"
									:category="selectedCategory"
								/>

								<TpvTables
									v-if="viewSectionItems === 'tables'"
								/>

								<TpvSelectedPax
									v-if="viewSectionItems === 'selectedPax'"
								/>

								<TpvListProductsTopChoice
									v-if="viewSectionItems === 'topChoice'"
								/>
							</div>
						</section>
					</div>
				</div>
			</main>
			<header class="col-2 col-lg-1">
				<div style="height: 50vh" class="row g-0">
					<div class="col-12 h-100 p-1">
						<img src="/assets/img/logo.PNG" alt="" class="logo w-100">
					</div>
				</div>

				<div style="height: 50vh" class="row g-0">
					<div class="col-12 h-100 p-1">
						<section class="box-shadow m-0 p-1 h-100 d-flex flex-column justify-content-end	">
							<div class="row g-1">
								<div class="col-12">
									<button
										ref="buttons"
										class="btn btn-outline-primary w-100 btn-tpv"
										@click="showHome"
										style="height: 5rem;"

									>
										<i class="fa-solid fa-house fa-xl"></i>
									</button>
								</div>
								<div class="col-12">
									<button
										ref="buttons"
										class="btn w-100 btn-tpv"
										:class="[viewSectionAccounts === 'createAccount' ? 'btn-primary' : 'btn-outline-primary']"
										:disabled="viewSectionAccounts === 'createAccount'"
										@click="showCreateAccount"
										style="height: 5rem;"

									>
										Nuevo Pedido
									</button>
								</div>
								<div class="col-12">
									<button
										ref="buttons"
										class="btn w-100 btn-tpv"
										:class="[viewSectionAccounts === 'allAccounts' ? 'btn-primary' : 'btn-outline-primary']"
										:disabled="viewSectionAccounts === 'allAccounts'"
										@click="showAllAccounts"
										style="height: 5rem;"

									>
										Cuentas
									</button>
								</div>

								<div class="col-12">
									<button
										ref="buttons"
										class="btn btn-primary w-100 btn-tpv"
										type="button" data-bs-toggle="offcanvas"
										data-bs-target="#offcanvasNavbar"
										aria-controls="offcanvasNavbar"
										aria-label="Toggle navigation"
										style="height: 5rem;"

									>
										<span class="navbar-toggler-icon"></span>
									</button>
								</div>
							</div>
						</section>
					</div>

				</div>
			</header>
		</div>
	</div>
	<div v-if="isLoading" class="spinner-overlay">
		<div class="spinner-container">
			<div class="spinner"></div>
			<p>Cargando...</p>
		</div>
	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from '@/store'

import TpvMenuCategories from "@/components/tpv/tpvMenuCategories/TpvMenuCategories";
import TpvProductsByCategory from "@/components/tpv/tpvProductsByCategory/TpvProductsByCategory";
import TpvTables from "@/components/tpv/tpvTables/tpvTables";
import TpvListAccounts from "@/components/tpv/tpvListAccounts/TpvListAccounts.vue";
import TpvAccountSelected from "@/components/tpv/tpvAccountSelected/TpvAccountSelected.vue";
import TpvNewOrder from "@/components/tpv/tpvNewOrder/TpvNewOrder.vue";
import TpvDrinksToPrepare from "@/components/tpv/tpvDrinksToPrepare/TpvDrinksToPrepare.vue";
import TpvSelectedPax from "@/components/tpv/tpvSelectedPax/TpvSelectedPax.vue";
import TpvListProductsTopChoice from "@/components/tpv/tpvListProductsTopChoice/TpvListProductsTopChoise.vue";

export default {
	name: "TPV",
	components: {
		TpvListProductsTopChoice,
		TpvSelectedPax,
		TpvDrinksToPrepare,
		TpvNewOrder,
		TpvAccountSelected,
		TpvListAccounts,
		TpvTables,
		TpvProductsByCategory,
		TpvMenuCategories
	},
	data() {
		return {
			chosenReservation: null,
		}
	},
	computed: {
		...mapGetters('tpv', ["viewSectionAccounts", "viewSectionItems",'isLoading']),
		...mapGetters("menu", ["selectedCategory", "productsCart"]),
		...mapGetters('venue', ['venueId']),
		...mapGetters('copilot',['reservations']),
	},
	created() {
		this.$nextTick(() => {
			this.adjustFontSize()
		})
	},
	beforeMount() {
		this.adjustFontSize()
	},
	methods: {
		...mapActions("menu", ["setCategorySelected"]),

		showAllAccounts() {
			store.commit("tpv/setViewSectionAccounts", 'allAccounts');
			this.resetProductsCart()
		},
		showCreateAccount() {
			store.commit("tpv/setViewSectionAccounts", 'createAccount');
			this.resetProductsCart()
		},

		resetProductsCart() {
			store.commit('menu/setProductsCart', {productsCart: []});
			store.commit('tpv/setViewSectionItems', 'topChoice');
			this.setCategorySelected({id: '', name: ''});
			store.commit('tpv/setIsChangeTable', false);
			store.commit('tpv/setNewAccount', null)
		},

		async updateNotifications() {
			await store.dispatch('copilot/updateOrdersAndNotifications')
		},

		showHome() {
			store.commit('tpv/setViewSectionAccounts', 'allAccounts');
			this.resetProductsCart()
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				let button = this.$refs.buttons;
				let fontSize = 14;
				button.style.fontSize = `${fontSize}px`;
				while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
					fontSize -= 1;
					button.style.fontSize = `${fontSize}px`;
				}
			}
		}
	},
}
</script>
<style scoped>
/* Fondo que cubre toda la pantalla */
.spinner-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

/* Contenedor del spinner */
.spinner-container {
	text-align: center;
	color: white;
}

/* Estilo del spinner */
.spinner {
	border: 8px solid rgba(255, 255, 255, 0.3); /* Color de fondo del spinner */
	border-top: 8px solid white; /* Color del borde animado */
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 1s linear infinite;
}

/* Animación del spinner */
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
