<template>
	<section class="box-shadow m-0 h-100 p-1 d-flex flex-column justify-content-between">
		<ul class="list-group">
			<li class="list-group-item">
				<div class="col-12">
					<div class="row text-uppercase fw-bold">
						<div class="col-2 col-lg-2">
							<i class="fa-solid fa-users"></i>
							<span class="ps-2" v-if="reservation.pax">{{ reservation.pax }}</span>
						</div>
						<div class="col-4 col-lg-4">
							<i class="fa-solid fa-user"></i>
							<span class="ps-2" v-if="reservation.name">{{ reservation.name }}</span>
						</div>
						<div class="col-6 col-lg-6 fs-6">
							<TableIcon/>
							<span class="ms-1" v-if="!reservation.locationTag">
								<span v-if="reservation.table">{{ reservation.table }}</span>
								<span class="vr mx-2"></span>
								<span v-if="reservation.roomName">{{ reservation.roomName }}</span>
							</span>
							<span class="ms-1" v-else>
								<span v-if="reservation.locationTag">{{ reservation.locationTag }}</span>
								<span class="vr mx-2"></span>
								<span v-if="reservation.roomName">{{ reservation.roomName }}</span>
							</span>
						</div>
					</div>
				</div>
			</li>

			<li class="list-group-item bg-dark">
				<div class="col-12">
					<div class="row text-uppercase fw-bold">
						<div class="col-1 col-lg-1 col-xl-1">
							Ud.
						</div>
						<div class="col-4 col-lg-6 col-xl-6">
							Descripción
						</div>
						<div class="col-4 col-lg-4 col-xl-1">
							subTotal
						</div>
						<div class="col-3 col-lg-5 col-xl-4">
							<span class="badge badge-warning text-dark" v-if="reservation.dateReadyForPayment">
								Cuenta solicitada
							</span>
						</div>
					</div>
				</div>
			</li>
		</ul>

		<ul class="list-group overflow-y-scroll overflow-hidden h-100" v-if="reservation.cartSession">
			<li class="list-group-item list-group-item-light text-uppercase">
				<div class="col-12" v-for="order in reservation.cartSession.productOrders" :key="order.id">
					<div class="row g-0 border-bottom py-1" :class="{'bg-body-secondary ': product.statusProductOrder === -1 || product.statusProductOrder === -3}" v-for="product in order.items" :key="product.id">
						<div class="col-8 col-md-8 col-lg-8 col-xl-8" :class="product.statusProductOrder === -1|| product.statusProductOrder === -3 ? 'text-decoration-line-through':''">
							<div class="row g-0">
								<div class="col-1 col-md-1 col-lg-1 col-xl-1">
									<span class="fw-bold fs-6">
										{{ product.units }}
									</span>
								</div>
								<div class="col-10 col-lg-10 col-xl-10">
									<div class="row">
										<div class="col-12">
											{{ product.name }}
										</div>
										<div class="col-12" v-if="product.choices">
											<ul v-for="(choice, index) in product.choices" :key="index">
												<li>
													{{ choice.choice }}
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-1 col-md-1 col-lg-1 col-xl-1">
									<span class="fw-bold fs-6">
										{{ formatPrice(product) }}
									</span>
								</div>
							</div>
						</div>
						<div class="col-2 col-md-2 col-lg-2 col-xl-2">
							<span v-if="product.statusProductOrder === -1" class="badge badge-primary">
								Quitado
							</span>
							<span v-if="product.statusProductOrder === -3" class="badge badge-primary">
								Mermado
							</span>
						</div>
						<div class="col-2 col-md-2 col-lg-2 col-xl-2 d-flex justify-content-end">
							<button class="btn btn-outline-primary btn-square" v-if="false" style="width: 40px;height: 40px;" @click="moveProductReservation(product)">
								<i class="fa-solid fa-arrow-turn-up"></i>
							</button>
							<button class="btn btn-outline-primary btn-square" :class="{'active': product.statusProductOrder === -3}" style="width: 40px;height: 40px;" @click="lossProduct(product)">
								<i class="fa-solid fa-trash-can"></i>
							</button>
							<button class="btn btn-outline-primary btn-square" :class="{'active': product.statusProductOrder === -1}" style="width: 40px;height: 40px;" @click="removeProduct(product)">
								<i class="fa-solid fa-xmark"></i>
							</button>
						</div>
					</div>
				</div>
			</li>
		</ul>

		<div>
			<ul class="list-group">
				<li class="list-group-item">
					<div class="col-12">
						<div class="row text-uppercase fw-bold">
							<div class="col-3 col-lg-3 fs-6">
								total
							</div>
							<div class="col-9 col-lg-9 fs-6 text-end">
								{{ totalPrice }}
							</div>
						</div>
					</div>
				</li>
			</ul>
			<div class="row g-0" v-if="false">
				<div class="col-3">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						style="height: 3.5rem;"
						@click="handleReturnAllAccounts"
					>
						Volver
					</button>
				</div>
				<div class="col-3">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						style="height: 3.5rem;"
						@click="handleShowBtnRemoveProduct"
					>
						Quitar productos
					</button>
				</div>
			</div>
			<div class="row g-0">
				<div class="col-3 col-md-2 col-xl-2">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						style="height: 3.5rem;"
						@click="handleReturnAllAccounts"
					>
						Volver
					</button>
				</div>
				<div class="col-3 col-md-2 col-xl-2">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						:class="{'active':isChangeTable}"
						style="height: 3.5rem; font-size:  clamp(10px, calc(10px + 0.25vw), 16px)"
						@click="handleChangeTable"
					>
						<span>
							<span v-if="reservation.roomName !== 'Barra'">Cambiar Mesa</span>
							<span v-if="reservation.roomName === 'Barra'"> Mover a Mesa</span>
						</span>
					</button>
				</div>
				<div class="col-3 col-md-2 col-xl-2">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						style="height: 3.5rem;"
						:disabled="!isConnected"
						@click="handlePrintTicket"
					>
						<i class="fa-solid fa-receipt fa-xl"></i>
					</button>
				</div>
				<div class="col-3 col-md-2 col-xl-2">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						style="height: 3.5rem;"
						@click="handleRequestTicket"
					>
						Pedir cuenta
					</button>
				</div>
				<div class="col-3 col-md-2 col-xl-2">
					<button
						ref="buttons"
						class="btn btn-outline-primary w-100 btn-tpv"
						:class="{'disabled' : !reservation.dateReadyForPayment && reservation.menuAccessEnabled === 1}"
						style="height: 3.5rem;"
						@click="handleReactiveAccount"
					>
						Reactivar cuenta
					</button>
				</div>
			</div>
		</div>
	</section>

	<TpvModalRemoveProduct :reservation="reservation"/>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import store from "@/store";

import TableIcon from "@/core/icons/TableIcon.vue";
import {showModal} from "@/utils";
import TpvModalRemoveProduct from "@/components/tpv/tpvModalRemoveProduct/TpvModalRemoveProduct.vue";

export default {
	name: "TpvAccountSelected",
	components: {TpvModalRemoveProduct, TableIcon},
	data() {
		return {
			showBtnRemoveProduct: false,
		}
	},
	inject: {
		copilotRepository: 'copilotRepository',
		reservationRepository: 'reservationRepository',
	},
	computed: {
		...mapGetters('tpv', ['accountSelectedId', 'viewSectionItems', 'viewSectionAccounts', 'isChangeTable']),
		...mapGetters('copilot', ['reservations']),
		...mapGetters('user', ['user']),
		...mapGetters('weWingman', ['isConnected']),

		reservation() {
			if (this.accountSelectedId) {
				return this.reservations.find(reservation => reservation.id === this.accountSelectedId)
			}
			return null;
		},

		totalPrice() {
			if (!this.reservation || !this.reservation.cartSession) {
				return "0,00";
			}

			let total = this.reservation.cartSession.productOrders.reduce((orderSum, order) => {
				const productSum = order.items
					.filter(product => product.statusProductOrder === 1)
					.reduce((productSum, product) => {
						return productSum + (product.units * product.unitaryPrice);
					}, 0);
				return orderSum + productSum;
			}, 0);

			return total.toFixed(2).replace(".", ",");
		}
	},
	mounted() {
	},
	methods: {
		...mapActions('weWingman', ['printTicket']),

		async addProduct(product) {
			const result = await this.copilotRepository.markProductOrderAsCancelled({productOrderId: product.productOrderId});
			console.log({result: result})
			if (result.result === 0) {
				console.log('todo bien')
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido ${this.titleModal} el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
			}
		},

		moveProductReservation(product) {
			console.log('mover ', product)
		},

		getOptionName(product, optionId, chosenId) {
			const productOption = product.productOptions.find(option => option.id === optionId);
			const option = productOption ? productOption.options.find(opt => opt.id === chosenId) : null;
			return option ? option.name : '';
		},

		handleChangeTable() {
			store.commit('tpv/setIsChangeTable', true)
			store.commit('menu/setCategorySelected', '')
			store.commit("tpv/setViewSectionItems", "tables")
		},
		async handleRequestTicket() {
			try {
				const resultTicket = await store.dispatch('menu/requestAccount', {reservationInfo: this.reservation, user: this.user});

				if (resultTicket.result === 0) {
					console.log('handleRequestTicket correcto')
				}
			} catch (e) {
				console.log(e)
			}
		},
		async handleReactiveAccount() {
			try {
				const resultReactivateReservation = await this.reservationRepository.reactivateReservation(this.reservation.id);

				if (resultReactivateReservation.result === 0) {
					console.log('handleReactiveAccount correcto')
				}
			} catch (e) {
				console.log(e)
			}
		},

		async removeProduct(product) {
			store.commit('tpv/setIsLoading', true)
			const result = await this.copilotRepository.markProductOrderAsCancelled({productOrderId: product.productOrderId});
			console.log({result: result})
			if (result.result === 0) {
				console.log('todo bien');
				store.commit('tpv/setIsLoading', false)
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido quitar el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				store.commit('tpv/setIsLoading', false)
			}
		},

		async lossProduct(product) {
			store.commit('tpv/setIsLoading', true);

			const result = await this.copilotRepository.markProductOrderAsLoss({productOrderId: product.productOrderId});
			console.log({result:result})
			if (result.result === 0) {
				store.commit('tpv/setIsLoading', false);
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido Mermar el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
				store.commit('tpv/setIsLoading', false);

			}
		},

		handleShowBtnRemoveProduct() {
			this.$nextTick(() => {
				showModal('tpv-modal-remove-product')
			})
		},

		handleReturnAllAccounts() {
			store.commit('tpv/setViewSectionItems', 'topChoice');
			store.commit('tpv/setViewSectionAccounts', 'allAccounts');
			store.commit('tpv/setIsChangeTable', false)
		},

		async handlePrintTicket() {
			console.log("En la venue " + this.resourceId + " Vamos a imprimir el ticket de " + this.reservation.id);

			await this.printTicket(this.reservation);

		},

		formatPrice(product) {
			if (!product || typeof product.units !== "number" || typeof product.unitaryPrice !== "number") {
				return "0,00";
			}

			let total = product.units * product.unitaryPrice;

			return total.toFixed(2).replace(".", ",");
		}
	}
}
</script>
<style scoped>
</style>
