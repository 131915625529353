<template>
	<div class="modal fade" id="check-copilot-modal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<h1 class="modal-title fs-5" id="exampleModalLabel">BIENVENIDO A WE COPILOT</h1>
				</div>
				<div class="modal-body">
					<div v-if="wsClientStatus" class="row row-margin-x">
						<div class="col-12">
							<span>WeWelcom: <span></span>{{ wSStatus }}</span>
						</div>
						<div class="col-12 mt-1">
							<span>Sonido: <span></span>{{ copilotSoundStatus }}</span>
						</div>
					</div>
					<div v-else>
						<div class="alert alert-secondary d-flex justify-content-between">
							<h3><span class="alert-heading mb-0">🚀 20 ene</span></h3>

							<h4 class="alert-heading mb-0">¡Nuevo TPV!</h4>
						</div>
						<ul class="list-unstyled">
							<h2 class="border-bottom">
								🎉 NOVEDADES
							</h2>
							<li><b class="text-uppercase">Rol de TPV</b><br>Nueva vista de TPV para gestionar la barra.</li>
							<li><b class="text-uppercase">Top Pedidos</b><br>Ahora mostramos los productos más pedidos en este Rol.</li>
							<li><b class="text-uppercase">Organiza tu Barra</b><br>Agregar la descripción a la mesa de barra para diferenciar mejor.</li>
							<li><b class="text-uppercase">Imprime el comprobante</b><br>selecciona la cuenta que quieras el comprobante</li>
							<hr>
							<h2 class="border-bottom">
								🚀 MEJORAS
							</h2>
							<li><b class="text-uppercase">Packs de productos</b><br>Si tienes productos que van en pack, en cocina ahora se muestra entre paréntesis a cuál corresponde cada producto.</li>
							<hr>
							<h2 class="border-bottom">
								🛠️ Solucionado
							</h2>
							<li><b class="text-uppercase">Ya se puede modificar el número de pax de las reservas telefónicas.</b></li>
							<hr>
							<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
							</li>
							<hr>
							<li><b>✨ FELIZ SERVICIO Y A POR EL AÑO ✨</b></li>
						</ul>
						<h5 class="border-bottom" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePreviousVersions" aria-expanded="false" aria-controls="collapsePreviousVersions">
							Versiones anteriores <span class="fs-6 mt-3">(ver más)</span>
						</h5>
						<div id="collapsePreviousVersions" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
							<div class="accordion accordion-flush" id="accordionFlushExample">
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2010.1" aria-expanded="false"
												aria-controls="flush-collapse_2010.1">
											<span class="fw-bold">0.2010.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2412.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													🎉 NOVEDADES
												</h2>
												<li><b class="text-uppercase">Vista de TPV</b><br>Nueva vista de TPV desde la que gestionar la barra de forma ágil y fácil.</li>
												<hr>
												<h2 class="border-bottom">
													🚀 MEJORAS
												</h2>
												<li><b class="text-uppercase">Packs de productos</b><br>Si tienes productos que van en pack, en cocina ahora se muestra entre paréntesis a cuál corresponde cada producto.</li>
												<hr>
												<h2 class="border-bottom">
													🛠️ Solucionado
												</h2>
												<li><b class="text-uppercase">Ya se puede modificar el número de pax de las reservas telefónicas.</b></li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
												</li>
												<hr>
												<li><b>✨ FELIZ SERVICIO Y A POR EL AÑO ✨</b></li>
											</ul>
										</div>
									</div>
								</div>

								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2412.4" aria-expanded="false"
												aria-controls="flush-collapse_2412.4">
											<span class="fw-bold">0.2412.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2412.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													🚀 MEJORAS
												</h2>
												<li><b class="text-uppercase">Packs de productos</b><br>Si tienes productos que van en pack, en cocina ahora se muestra entre paréntesis a cuál corresponde cada producto.</li>
												<hr>
												<h2 class="border-bottom">
													🛠️ Solucionado
												</h2>
												<li><b class="text-uppercase">Ya se puede modificar el número de pax de las reservas telefónicas.</b></li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
												</li>
												<hr>
												<li><b>✨ FELIZ SERVICIO Y FELICES FIESTAS ✨</b></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2412.3" aria-expanded="false"
												aria-controls="flush-collapse_2412.3">
											<span class="fw-bold">0.2412.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2412.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													🎉 NOVEDADES
												</h2>
												<li><b class="text-uppercase">Tiempo de reserva</b><br>Ahora les decimos a los clientes por su reserva y email cuánto tiempo pueden quedarse en su mesa.</li>
												<hr>
												<li><b class="text-uppercase">Reservas grandes</b><br>Para reservas de grupos de + de 8 comensales, ya les aparece las condiciones para su reserva.</li>
												<hr>
												<li><b class="text-uppercase">Origen de la reserva</b><br>Verás si la reserva vino de la web o por teléfono.</li>
												<hr>
												<h2 class="border-bottom">
													🚀 MEJORAS
												</h2>
												<li><b class="text-uppercase">Servicios en Copilot</b><br>Los servicios ya no se ocultan durante la operativa.</li>
												<hr>
												<li><b class="text-uppercase">Control de productos</b><br>Marca los productos como "merma" o "quítalos" después de servirlos.</li>
												<hr>
												<li><b class="text-uppercase">Menú del día</b><br>Antes de asignar productos, debes indicar cuántos menús se van a usar. Así nos aseguramos que se cobran todos los menús.</li>
												<hr>
												<li><b class="text-uppercase">Comandero</b><br>Ahora siempre ves todos los productos en pantalla y los botones te dicen en qué sección estás.</li>
												<hr>
												<h2 class="border-bottom">
													🛠️ Solucionado
												</h2>
												<li><b class="text-uppercase">Ya no se puede apretar dos veces rápido el botón de enviar a cocina. ¡Adiós a los duplicados!</b></li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
												</li>
												<hr>
												<li><b>✨ FELIZ SERVICIO ✨</b></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2412.2" aria-expanded="false"
												aria-controls="flush-collapse_2412.2">
											<span class="fw-bold">0.2412.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2412.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													💡 NOVEDADES
												</h2>
												<li><b class="text-uppercase">Agrupación de Productos iguales en Cocina</b><br>veras el total.</li>
												<hr>
												<li><b class="text-uppercase">Opción de Envío de Pedidos</b><br>Selecciona si quieres que los pedidos lleguen directos a cocina/Barra o si prefieres que pasen primero por anotarse en el TPV.</li>
												<hr>
												<li><b class="text-uppercase">Notificaciones de Menú del Día Completado</b><br>Se envían notificaciones a cocina cuando se completa el menú del día.</li>
												<hr>
												<li><b class="text-uppercase">Visualización de Datos del Servicio</b><br>El propietario ahora puede conocer los datos exactos del servicio: hora de llegada, pedido, tiempo de anotación en TPV, tiempo de preparación y tiempo de entrega, permitiendo un seguimiento completo del recorrido del cliente.</li>
												<hr>
												<h2 class="border-bottom">
													🏗️ MEJORAS
												</h2>
												<li><b class="text-uppercase">Comandero Mejorado</b><br>La interfaz del comandero ahora es más intuitiva y rápida.</li>
												<hr>
												<li><b class="text-uppercase">Edición del Menú del Día</b><br>Al editar el menú del día, ahora se muestran solo las opciones habilitadas. También puedes ver las opciones desactivadas y ordenarlas alfabéticamente.</li>
												<hr>
												<h2 class="border-bottom">
													🛠️ Solucionado
												</h2>
												<li><b class="text-uppercase">Arreglado el error de las reservas hechas por Google que no se eliminaban de Copilot cuando eran canceladas.</b></li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
												</li>
												<hr>
												<li><b>✨ FELIZ SERVICIO ✨</b></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2412.1" aria-expanded="false"
												aria-controls="flush-collapse_2412.1">
											<span class="fw-bold">0.2412.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2412.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													💡 NOVEDADES
												</h2>
												<li><b class="text-uppercase">HABILITAR RESERVA</b><br>Ya puedes habilitar la reserva para poder pedir con el comandero.</li>
												<hr>
												<h2 class="border-bottom">
													🏗️ MEJORAS
												</h2>
												<li><b class="text-uppercase">Menú del Día Agrupado </b><br>Menús de mediodía agrupados en la comanda.</li>
												<hr>
												<li><b class="text-uppercase">Menú del Día desde el Comandero </b><br>Ahora seleccionas primero cuántos menús antes de elegir los platos.</li>
												<hr>
												<h2 class="border-bottom">
													🛠️ Solucionado
												</h2>
												<li><b class="text-uppercase">Comensales ya pueden pedir escaneando el QR antes de que llegue el que ha reservado.</b></li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br>y ya sabes, si tienes alguna duda: CONTÁCTANOS.
												</li>
												<hr>
												<li><b>✨ FELIZ SERVICIO ✨</b></li>
											</ul>
										</div>
									</div>
								</div>

								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2411.4" aria-expanded="false"
												aria-controls="flush-collapse_2411.4">
											<span class="fw-bold">0.2411.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2411.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<h2 class="border-bottom">
													💡 NOVEDADES
												</h2>
												<li><b>PARTIDAS DE COCINA</b><br>Actívalas en las tablets de cocina. Contacta con nosotros para modificar los productos y las partidas.</li>
												<hr>
												<li><b>NUEVOS DATOS PARA PROPIETARIOS</b><br>Los propietarios ahora pueden saber el uso de la carta digital, el comandero, las valoraciones obtenidas y mucho más!</li>
												<hr>
												<h2 class="border-bottom">
													🏗️ MEJORAS
												</h2>
												<li><b>COMPLETAR MENÚ DEL DÍA</b><br>Más fácil desde el comandero poder completar los menús en distintos momentos.</li>
												<hr>
												<li><b>REVISA LAS MEJORAS Y COMPRUEBA LAS NOVEDADES</b><br> y ya sabes, si tienes alguna duda: CONTÁCTANOS.</li>
												<hr>
												<li><b>FELIZ SERVICIO</b></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2411.3" aria-expanded="false"
												aria-controls="flush-collapse_2411.3">
											<span class="fw-bold">0.2411.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2411.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nueva forma de pedir menús del día</b>: Ahora para pedir un menú del día primero hay que elegir cuántos menús van a ser, y después elegir los platos.</li>
												<hr>
												<li><b>Añadir platos a los menús</b>: Si quieres pedir cafés o postres, o nuevos platos de un menú, puedes hacer clic en el botón "Completar Menú" que aparecerá en la cuentas con menú para pedirlos directamente.</li>
												<hr>
												<li><b>Ver información de las reservas</b>: Ya se pueden ver los datos de la persona que ha hecho una reserva. Despliega la tarjeta de la cuenta de la reserva, y haz clic en el icono (i) junto al nombre de la reserva.</li>
												<hr>
												<li><b>Arreglados pedidos duplicados</b>: Arreglado un error que permitía pedir varias veces un carrito haciendo que se duplicaran los pedidos para las mesas.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2411.2" aria-expanded="false"
												aria-controls="flush-collapse_2411.2">
											<span class="fw-bold">0.2411.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2411.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Marchar Platos a Cocina/Barra</b>: Ahora puedes solicitar directamente desde la aplicación que se marchen platos a cocina o barra, facilitando la comunicación y mejorando la eficiencia del servicio.</li>
												<hr>
												<li><b>Reservas Telefónicas</b>: Hemos solucionado el problema anterior y ahora puedes hacer reservas telefónicas sin inconvenientes.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2411.1" aria-expanded="false"
												aria-controls="flush-collapse_2411.1">
											<span class="fw-bold">0.2411.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2411.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Ajuste de Terminología en Copilot</b>: Hemos actualizado las variables globales en Copilot para utilizar un lenguaje más cercano al sector hostelero. Esta mejora asegura que los términos usados sean más familiares y relevantes para ti y tu equipo, mejorando la comprensión y la eficiencia en el uso de la aplicación.</li>
												<hr>
												<li><b>Desactivación de Platos desde Cocina</b>: Desde el menú desplegable. Introducimos la opción para desactivar platos directamente desde la cocina en caso de agotarse el stock. Esta actualización incluye notificaciones en audio en tiempo real en Copilot, asegurando que todos estén informados instantáneamente de cualquier cambio.</li>
												<hr>
												<li><b>Desactivación de Productos por temporada</b>: Ahora puedes desactivar productos temporalmente cuando estén fuera de temporada, sin la necesidad de desactivarlos día tras día. Esta funcionalidad te permite gestionar el menú de manera más eficiente y adaptar tu oferta a la demanda estacional.</li>
												<hr>
												<li><b>Mejoras en el Flujo de Invitaciones</b>: Hemos mejorado el flujo de invitaciones para comensales, incluyendo una alerta que informa cuando te llevamos a tu carta personalizada. Ahora también podemos verificar si el comensal ya ha accedido a la carta y está en la sección de invitaciones, mejorando la coordinación y la experiencia del usuario.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2410.4" aria-expanded="false"
												aria-controls="flush-collapse_2410.4">
											<span class="fw-bold">0.2410.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2410.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Personalización de Nombres en Productos</b>: Ahora puedes solicitar nombres cortos para tus productos que se mostrarán en el comandero. Esta funcionalidad está diseñada para hacer tu gestión más ágil y la información más clara durante el servicio.</li>
												<hr>
												<li><b>Agrupación de pedidos en la notificación de nuevo pedido</b>: Si recibes una notificación en pantalla y la misma mesa agrega un producto más, ahora te aparecerá y te avisará con un audio.</li>
												<hr>
												<li><b>Animación en la Carta Digital</b>: Hemos incorporado una animación que facilita la localización del carrito de compras en nuestra carta digital, mejorando tu experiencia de navegación del cliente.</li>
												<hr>
												<li><b>Mejora en el Sistema de Reservas</b>: Para evitar inconvenientes con las reservas sin mesa, hemos implementado una actualización importante. Ahora, antes de crear una nueva reserva, te informaremos si ya tienes una previamente asignada. De este modo, podrás modificar la reserva existente en lugar de generar una duplicada sin asignación de mesa. Esto garantiza que tu experiencia y la organización en nuestro establecimiento sean óptimas.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2410.3" aria-expanded="false"
												aria-controls="flush-collapse_2410.3">
											<span class="fw-bold">0.2410.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2410.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nuevo aspecto de WECOPILOT</b>: Hemos actualizado el estilo de la aplicación para mejorar tu experiencia. Explora el nuevo diseño que facilita la navegación, cansa menos la vista en entornos de poca luz y optimiza tu gestión diaria.</li>
												<hr>
												<li><b>Pedir menús ahora tiene en cuenta elementos opcionales</b>: Ahora tienes la opción de pedir el menú sin incluir por ejemplo el postre, si fuera opcional. Elige esta flexibilidad para adaptar los pedidos a las preferencias de tus clientes con facilidad.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2410.2" aria-expanded="false"
												aria-controls="flush-collapse_2410.2">
											<span class="fw-bold">0.2410.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2410.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Marcar productos como merma o error</b>: Ahora puedes registrar productos como merma o error directamente en la sección 'Ver cuenta'. Simplemente selecciona la cuenta deseada y realiza la acción con un solo toque.</li>
												<hr>
												<li><b>Marcar platos para compartir</b>: Si quieres poder marcar algunos platos para compartir, ponte en contacto con nosotros. Te habilitaremos esta opción para que desde el menú o el comandero se puedan marcar para compartir.</li>
												<hr>
												<li><b>Actualizado el estilo de los productos en cocina:</b> Hemos renovado la presentación de los productos en cocina. Ahora dispones de un botón que te permite desmarcar un producto como preparado, haciendo más sencilla la gestión de ajustes.</li>
												<hr>
												<li><b>Pedir de una vez múltiples menús del día desde el comandero:</b> Ahora puedes agregar más de un menú a la vez desde el comandero. Al seleccionar un producto, recibirás una alerta que te mostrará los productos faltantes, facilitando el proceso de pedido y gestión.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2410.1" aria-expanded="false"
												aria-controls="flush-collapse_2410.1">
											<span class="fw-bold">0.2410.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2410.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ahora <b>se guarda el rol seleccionado</b>: al recargar, volverás al rol que tuvieras seleccionado. Menos vueltas para llegar a los roles de barra o cocina</li>
												<hr>
												<li>En cocina y barra <b>se muestra el comentario de cliente si hay platos en marcha relacionados con ese pedido</b>. Ya no hace falta avisar o pasar una nota.</li>
												<hr>
												<li>Arreglado: ahora <b>siempre verás el QR de tus walk in y sit in</b> para que el cliente pueda escanear su acceso al menú.</li>
												<hr>
												<li>Arreglado: <b>ya no se duplican las notificaciones al perder la conexión</b>.</li>
												<hr>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2409.5" aria-expanded="false"
												aria-controls="flush-collapse_2409.5">
											<span class="fw-bold">0.2409.5</span>
										</button>
									</h2>
									<div id="flush-collapse_2409.5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ya puedes <b>cambiar el orden de los segundos al comandar</b>: si quieres que se sirvan como primeros, márcalos al validar el pedido.</li>
												<hr>
												<li>También puedes modificarlo <b>desde el botón "Ver cuenta"</b>.</li>
												<hr>
												<li>Los platos así marcados <b>se visualizan en su nueva categoría en cocina</b> .</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2409.4" aria-expanded="false"
												aria-controls="flush-collapse_2409.4">
											<span class="fw-bold">0.2409.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2409.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ahora desde cocina puedes <b>marcar platos como no disponibles</b>: en el menú, selecciona "Disponibilidad de platos" para poder marcar platos como no disponibles temporalmente.</li>
												<hr>
												<li>Todos los platos así deshabilidatos <b>se reactivarán cada día</b>.</li>
												<hr>
												<li>En cocina y barra ahora <b>está sincronizado el estado de mesa caliente</b> entre todos los dispositivos del restaurante.</li>
												<hr>
												<li>Nuevo interfaz más compacto de <b>cocina y barra</b>: ve más información en menos espacio.</li>
												<hr>
												<li>Arreglado: <b>Las reservas no se multiplican</b> al ser hechas a través de google.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2409.3" aria-expanded="false"
												aria-controls="flush-collapse_2409.3">
											<span class="fw-bold">0.2409.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2409.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Cuando el cliente pida la cuenta <b>sabrás si el pago se va a hacer en efectivo o tarjeta</b>. H.A.R.V.I.S. también lo indica en la notificación que escucharás.</li>
												<hr>
												<li>Las mesas que han pedido la cuenta se resaltan en amarillo <b>permanecen 10 minutos en el listado del servicio</b>: se dan como finalizadas automáticamente después de ese tiempo.</li>
												<hr>
												<li>Los pedidos de cocina y barra que están en la mesa caliente o pasante <b>muestran la mesa a la que pertenecen</b> para hacer más sencillo organizar qué sale.</li>
												<hr>
												<li>En la parte inferior de la vista del rol de cocina <b>se muestran los pedidos pendientes de servir</b>: Así en caso de ser compartida, los compañeros de sala pueden comprobar qué platos han de servir.</li>
												<hr>
												<li>En el menú + se ha añadido <b>un QR directo a las valoraciones de Google</b> para esos clientes especialmente encantados.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2409.2" aria-expanded="false"
												aria-controls="flush-collapse_2409.2">
											<span class="fw-bold">0.2409.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2409.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Nuevo <b>ROL de Sala y Barra</b>: Gestiona la operativa de sala recibiendo los pedidos y prepara y sírvelos haciendo clic en el botón que se muestra en la parte superior.</li>
												<hr>
												<li>Nueva <b>organización de platos en cocina</b>: Clasifica tus platos en entrantes, primeros, postres (o lo que prefieras) para dividir los platos marchados en concina. Habla con tu contacto de WeWelcom para establecer los platos.</li>
												<hr>
												<li>Mejoras en la <b>edición de los menús desde WE COPILOT</b>: puedes elegir qué platos están incluidos y cuáles no seleccionándolos, y también puedes añadir un suplemento para ese plato en concreto.</li>
												<hr>
												<li>Las comandas de todas las mesas indican ahora la zona.</li>
												<hr>
												<li>Nuevo <b>estilo visual de los platos</b> en los roles de elaboración (barra, cocina). Si tus platos tienen foto, ahora podrás reconocerlos más fácilmente.</li>
												<hr>
												<li><b>Arreglado</b>: El nombre de los platos en el comandero ya no se quedará a medias.</li>
												<hr>
												<li><b>Arreglado</b>: Ya puedes volver a sacar los productos de la mesa caliente/pasante de uno en uno pulsando sobre ellos.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2409.1" aria-expanded="false"
												aria-controls="flush-collapse_2409.1">
											<span class="fw-bold">0.2409.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2409.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Nuevo <b>ROL de Sala y Barra</b>: Gestiona la operativa de sala recibiendo los pedidos y prepara y sírvelos haciendo clic en el botón que se muestra en la parte superior.</li>
												<hr>
												<li>Nueva <b>organización de platos en cocina</b>: Clasifica tus platos en entrantes, primeros, postres (o lo que prefieras) para dividir los platos marchados en concina. Habla con tu contacto de WeWelcom para establecer los platos.</li>
												<hr>
												<li>Mejoras en la <b>edición de los menús desde WE COPILOT</b>: puedes elegir qué platos están incluidos y cuáles no seleccionándolos, y también puedes añadir un suplemento para ese plato en concreto.</li>
												<hr>
												<li>Las comandas de todas las mesas indican ahora la zona.</li>
												<hr>
												<li>Nuevo <b>estilo visual de los platos</b> en los roles de elaboración (barra, cocina). Si tus platos tienen foto, ahora podrás reconocerlos más fácilmente.</li>
												<hr>
												<li><b>Arreglado</b>: El nombre de los platos en el comandero ya no se quedará a medias.</li>
												<hr>
												<li><b>Arreglado</b>: Ya puedes volver a sacar los productos de la mesa caliente/pasante de uno en uno pulsando sobre ellos.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2408.4" aria-expanded="false"
												aria-controls="flush-collapse_2408.4">
											<span class="fw-bold">0.2408.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2408.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Nuevo <b>ROL de Sala y Barra</b>: Gestiona la operativa de sala recibiendo los pedidos y prepara y sírvelos haciendo clic en el botón que se muestra en la parte superior.</li>
												<hr>
												<li>Nueva <b>organización de platos en cocina</b>: Clasifica tus platos en entrantes, primeros, postres (o lo que prefieras) para dividir los platos marchados en concina. Habla con tu contacto de WeWelcom para establecer los platos.</li>
												<hr>
												<li>Mejoras en la <b>edición de los menús desde WE COPILOT</b>: puedes elegir qué platos están incluidos y cuáles no seleccionándolos, y también puedes añadir un suplemento para ese plato en concreto.</li>
												<hr>
												<li>Las comandas de todas las mesas indican ahora la zona.</li>
												<hr>
												<li>Nuevo <b>estilo visual de los platos</b> en los roles de elaboración (barra, cocina). Si tus platos tienen foto, ahora podrás reconocerlos más fácilmente.</li>
												<hr>
												<li><b>Arreglado</b>: El nombre de los platos en el comandero ya no se quedará a medias.</li>
												<hr>
												<li><b>Arreglado</b>: Ya puedes volver a sacar los productos de la mesa caliente/pasante de uno en uno pulsando sobre ellos.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2408.3" aria-expanded="false"
												aria-controls="flush-collapse_2408.3">
											<span class="fw-bold">0.2408.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2408.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Nueva opción disponible para que <b>el cliente pueda enviar un comentario con su carrito al hacer su comanda</b>: esta opción no es obligatoria y hay que configurarla en tu cuenta. Habla con tu contacto de WeWelcom si quieres configurarla.</li>
												<hr>
												<li><b>Arreglado</b>: Los productos de bebida ya no se mostrarán en la mesa caliente del rol de cocina.</li>
												<hr>
												<li><b>Arreglado</b>: Los pedidos para servir ya no se quedan con el botón sin productos permanentemente.</li>
												<hr>
												<li><b>Arreglado</b>: Las reservas finalizadas se muestran correctamente en un listado vertical.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2408.2" aria-expanded="false"
												aria-controls="flush-collapse_2408.2">
											<span class="fw-bold">0.2408.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2408.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Mejorado <b>Aviso de mesas dobladas</b>: Si hay una reserva que empieza dentro de la media hora siguiente a la finalización de una reseva, esta parpadeará con un color especial avisando de que la mesa está doblada.</li>
												<hr>
												<li><b>Editor del menú de mediodía</b>: Si tu restaurante tiene menú del día, puedes editarlo directamente desde el menú superior derecho para indicar en cada sección los platos que correspondan.</li>
												<hr>
												<li>Nuevo <b>formato de precios</b>: Los números redondos generan confianza y mejoran ventas. Hemos eliminado los decimales para números redondos y recortado el número. Ej: 12,7.</li>
												<hr>
												<li>Diseño de <b>reservas mejorado</b>: puedes verr las finalizadas y no-shows de cada servicio al final, sin que interrumpan tu flujo de trabajo.</li>
												<hr>
												<li>Para recordar los comandos disponibles en H.A.R.V.I.S., <b>cuando pulses verás una chuleta</b>: Walkins, reservas, finalizaciones.</li>
												<hr>
												<li>El carrusel de productos recomendados de los clientes ahora tiene flechas para navegarlo</li>
												<hr>
												<li>Nuevos iconos de WEWELCOM y WECOPILOT.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2408.1" aria-expanded="false"
												aria-controls="flush-collapse_2408.1">
											<span class="fw-bold">0.2408.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2408.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nuevo menú inferior</b>: El botón del + se ha sustituido por una barra inferior con las funciones más utilizadas.</li>
												<hr>
												<li><b>Nuevas cuentas SITTING</b>: Ahora puedes gestionar las mesas de clientes que se sientan directamente en una mesa, seleccionando el número de pesonas y la mesa en la que vayan a estar.</li>
												<hr>
												<li>Las notificaciones <b>avisan ahora de cuántos platos y de qué mesa</b> están listos en cocina y barra: "2 platos de la mesa 6".</li>
												<hr>
												<li>Disponible el <b>Menú del Día</b>: Los clientes pueden ahora pedir tu menú con su primero, segundo, postre y bebida... o las opciones que desees, que podrás configurar cada día según tu necesidad. Habla con tu Account Manager de WEWELCOM para más información.</li>
												<hr>
												<li>Disponibles las <b>Sugerencias del Chef</b>: un listado atractivo al comienzo del menú con los platos que queráis promocionar. Habla con tu Account Manager de WEWELCOM para más información.</li>
												<hr>
												<li>Alertas <b>para cuentas que tengan una mesa doblada</b>: cuando una cuenta tiene una reserva próxima en la misma mesa, mostrará un borde parpadeando en amarillo.</li>
												<hr>
												<li>Ahora puedes <b>vender productos por unidades</b>: croquetas, gildas, o cualquier producto que desees ahora se pueden vender y marcar por unidades. También puedes seleccionar el mínimo de unidades a pedir, y en incrementos de cuánto se pueden pedir.</li>
												<hr>
												<li>Puedes seleccionar el rol directamente desde el menú debajo del calendario.</li>
												<hr>
												<li>Cuando hagas un pedido desde el comandero, <b>no escucharás la notificación generada por tu acción</b>. Habla con tu Account Manager para comprobar que todos los usuarios de WECOPILOT tengan su usuario independiente</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2407.5" aria-expanded="false"
												aria-controls="flush-collapse_2407.5">
											<span class="fw-bold">0.2407.5</span>
										</button>
									</h2>
									<div id="flush-collapse_2407.5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nuevo rol de BARRA</b>: Coordina la elaboración de los pedidos de barra que requieran elaboración en barra. Comprueba de un vistazo qué platos se están elaborando y marcha uno o varios a la vez.</li>
												<hr>
												<li>Las cuentas <b>finalizadas y NO SHOW ahora están agrupadas</b> al final de cada servicio para que de un vistazo solo aparezcan las cuentas más relevantes en operativa.</li>
												<hr>
												<li>Puedes <b>marcar como NO SHOW una reserva</b> directamente desde el botón que se despliega la información de la reserva que no ha llegado aún.</li>
												<hr>
												<li>Arreglado un bug que impedía en ocasiones mostrar la opción de pedir la cuenta desde el comandero.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2407.4" aria-expanded="false"
												aria-controls="flush-collapse_2407.4">
											<span class="fw-bold">0.2407.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2407.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nuevo rol de COCINA</b>: Coordina la elaboración de los platos o productos y su marchado. Comprueba de un vistazo qué platos se están elaborando y marcha uno o varios a la vez.</li>
												<hr>
												<li>Hemos <b>actualizado el comandero</b> para que ahora se puedan 4 platos por fila con nombres completos.</li>
												<hr>
												<li>Ahora puedes <b>marcar como NO SHOW</b> una reserva que no haya venido: pulsa en FINALIZAR RESERVA para seleccionar si quieres cancelar la reserva o marcarla como NO SHOW. Si lo haces, dependiendo de la configuración, esa pesona no podrá reservar de nuevo en el restaurante.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2407.3" aria-expanded="false"
												aria-controls="flush-collapse_2407.3">
											<span class="fw-bold">0.2407.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2407.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Comandero renovado</b>: busca el producto por su nombre, descripción o categoría además de navegar por el menú como siempre. Tarjetas optimizadas para abarcar más que antes.</li>
												<hr>
												<li>Pide a tu gestor WEWELCOM <b>definir nombres cortos para uso interno a los productos </b> y que se vean en el nuevo comandero para ahorrar espacio y ganar velocidad.</li>
												<hr>
												<li>Hemos <b>arreglado la duplicación de clientes en las invitaciones</b> y escaneos de QR: se limita también el número máximo de personas que se pueden invitar a una reserva.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
												data-bs-target="#flush-collapse_2407.2" aria-expanded="false"
												aria-controls="flush-collapse_2407.2">
											<span class="fw-bold">0.2407.2</span>
										</button>
									</h2>
									<div id="flush-collapse_2407.2" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li><b>Nuevo comandero para anotar pedidos</b>: busca el producto por su nombre, descripción o categoría además de navegar por el menú como siempre. Tarjetas optimizadas para abarcar más que antes.</li>
												<hr>
												<li>Ya se puede <b>cambiar el número de personas de una mesa</b>: pulsa en el icono botón para seleccionar las nuevas personas. <b>ATENCIÓN:</b> si intentas cambiar el número de personas antes de haber escaneado el QR, si no hay espacio en la mesa asignada, puede no dejarte. Si esto sucede, escanea el QR del cliente y después modifica el número de personas.</li>
												<hr>
												<li>El cliente puede ahora <b>instalar WEPASS</b> en su teléfono para tener siempre acceso a su reserva y su QR.</li>
												<hr>
												<li>Ahora puedes <b>ver el historial de comandas unificado</b> para comprobar d eun vistado el total de los pedidos de una cuenta.</li>
												<hr>
												<li>Los clientes <b>ya no saldrán accidentalmente tan fácilmente del menú</b> porque se le pregunta si realmente quiere salir antes de hacerlo.</li>
												<hr>
												<li><b>Nuevo formulario de reserva para el cliente</b> más intuitivo y por pasos.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse_2407.1" aria-expanded="false"
												aria-controls="flush-collapse_2407.1">
											<span class="fw-bold">0.2407.1</span>
										</button>
									</h2>
									<div id="flush-collapse_2407.1" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ya puedes <b>anotar una nueva reserva en Copilot</b>: la opción del botón + está activada. Elige el día, personas y hora y haz la reserva. Si todo va bien, recuerda al cliente que compruebe su correo para acceder a su QR en WEPASS.</li>
												<hr>
												<li>Ahora es posible <b>asignar múltiples mesas a una cuenta</b>: al pulsar en la mesa de una cuenta, se abre una ventana para seleccionar la nueva mesa, y se permite .</li>
												<hr>
												<li>El cliente ahora <b>puede modificar la reserva desde su WEPASS</b>: si alguien quiere hacerlo, dile que puede pulsar el botón de "Modificar Reserva" desde su WEPASS.</li>
												<hr>
												<li>Disponible ya <b>seleccionar varios productos iguales en multi-selección</b> para las promociones que permitan repetición.</li>
												<hr>
												<li>Modificado el menú para <b>mostrar el selector de categorías abajo</b>. Más cómodo, más accesible, y más rápido.</li>
												<hr>
												<li>Los clientes <b>ya no necesitan confirmar su email</b> para hacer una reserva. Más fácil para ellos.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse_2406.5" aria-expanded="false"
												aria-controls="flush-collapse_2406.5">
											<span class="fw-bold">0.2406.5</span>
										</button>
									</h2>
									<div id="flush-collapse_2406.5" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ya disponible <b>la opción multi-producto</b>: se puede escoger el punto de la carne, guarniciones y opción de múltiples subproductos para determinados elementos del menú, con sobreprecio individual opcional.</li>
												<hr>
												<li>También se puede <b>seleccionar el refresco para acompañar las copas</b>: dinos qué opciones tenéis disponibles y lo configuraremos a medida.</li>
												<hr>
												<li>Contacta con tu representante de ventas para adecuar las opciones de tu restaurante.</li>
												<hr>
												<li>Se ha <b>desactivado el cambio de mesa</b> para las cuentas finalizadas.</li>
												<hr>
												<li>Solucionado el error que impedía <b>pedir la cuenta desde el comandero</b>.</li>
												<hr>
												<li>Solucionado problema a la hora de <b>mostrar quién reserva y los invitados</b> en la tarjeta de cada mesa.</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse_2406.4" aria-expanded="false"
												aria-controls="flush-collapse_2406.4">
											<span class="fw-bold">0.2406.4</span>
										</button>
									</h2>
									<div id="flush-collapse_2406.4" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ya se puede
													<b>cambiar una cuenta de mesa</b>: pulsa en el botón de mesa para ver el desplegable de las mesas del establecimiento y seleccionar la deseada.
												</li>
												<hr>
												<li>Si hay un pico de comandas
													<b>puedes hacer que las notificaciones suenen espaciadas por 15 o 30 segundos</b>, activa manualmente esta opción en el menú.
												</li>
												<hr>
												<li>Las
													<b>cuentas finalizadas se ordenan automáticamente</b> al final de la lista para no entorpecer la vista de mesas activas en operativa.
												</li>
												<hr>
												<li>
													<b>Nueva interfaz del menú de productos</b> en un único scroll. Más rápido, más intuitivo.
												</li>
											</ul>
										</div>
									</div>
								</div>
								<div class="accordion-item">
									<h2 class="accordion-header">
										<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" aria-expanded="false"
												data-bs-target="#flush-collapse_2406.3"
												aria-controls="flush-collapse_2406.3">
											<span class="fw-bold">0.2406.3</span>
										</button>
									</h2>
									<div id="flush-collapse_2406.3" class="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
										<div class="accordion-body">
											<ul class="list-unstyled">
												<li>Ahora verás esta
													<b>pantalla de bienvenida a WE COPILOT</b>: una ventana con un botón para conectar a WE COPILOT y comprobar el estado de la conexión a los servidores y la capacidad de reproducir mensajes. Al hacer clic en "Conectar a WE COPILOT" debería de sonar "Conectando a WE COPILOT" para indicarnos que la conexión y el audio son correctos. El mensaje se cierra solo tras breves instantes. Esperamos que estoy ayude a detectar qué problemas puede tener copilot cuando nos dices que no se escuchan los mensajes pero que están en la aplicación cuando la has mirado.
												</li>
												<hr>
												<li>
													<b>H.A.R.V.I.S.</b>: Damos la bienvenida al asistente por voz de WE COPILOT. El botón aparece desplegado entre las opciones al hacer clic en el botón (+). De momento puede crear un nuevo walk in si tras pulsar el botón, hablamos a través del micrófono (o auriculares bluetooth) "María, walkin para 4 personas". Aparecerá un mensaje en la pantalla indicando que se está escuchando y el resultado de la acción reconocida. Después avisa mediante un mensaje de voz el resultado. También reconoce "Finaliza mesa X" y "Activa mesa X" para finalizar y activar mesas. Cada vez iremos haciendo que pueda hacer más cosas.
												</li>
												<hr>
												<li>
													<b>Nuevo estilo visual de las mesas/reservas</b>. El botón para mostrar el QR se resalta en azul. Al hacer clic en una mesa, se despliegan las opciones inferiores para comandar pedidos, ver el historial si lo hubiera y finalizar/activar la mesa/reserva.
												</li>
												<hr>
												<li>Ahora
													<b>se pueden ver los pedidos con comida en el historial de pedidos</b> independientemente de si están en cocina todavía. Aparecen con un pequeño icono (que cambiaremos) junto a la fecha de aceptado.
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button v-if="!wsClientStatus" type="button" @click="handleCheckCopilot" class="btn btn-secondary w-100">
						{{ connectButtonText }}
						<SpinnerIcon v-if="isSpinnerIconLoading"/>
					</button>
					<DismissButton v-else :on-click="handleCloseModal"/>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {WebSocketClient} from "@/service/webSocketClient";
import {hideAllModals} from "@/utils";
import {mapGetters} from "vuex";
import DismissButton from "@/core/DismissButton";
import SpinnerIcon from "@/core/icons/SpinnerIcon";

export default {
	name: 'CheckCopilotModal',
	components: {SpinnerIcon, DismissButton},
	inject: {
		speakService: 'speakService',
	},
	props: {
		wsClient: WebSocketClient,
	},
	computed: {
		...mapGetters('config', ['wsConnectionStatus', 'isProductionEnvironment']),
		wSStatus() {
			const status = {0: "Conectando....", 1: "✅", 2: "Desconectando...", 3: "❌"};
			return status[this.wsConnectionStatus] || null
		}
	},
	data() {
		return {
			wsClientStatus: null,
			copilotSoundStatus: "Comprobando...",
			connectButtonText: "Conectar a WE COPILOT",
			isSpinnerIconLoading: false,

		}
	},
	mounted() {
	},
	beforeUnmount() {

	},
	watch: {
		wsConnectionStatus(newValue) {
			console.log('Nuevo valor del WSConnection', newValue);
			if (newValue === 2 || newValue === 3) {
				//showModal('check-copilot-modal')
			}
		},
	},
	methods: {
		clearModal() {
			this.wsClientStatus = null;
			this.copilotSoundStatus = "Comprobando...";
			this.connectButtonText = "Conectar a WE COPILOT";
		},
		handleCloseModal() {
			this.clearModal();
			hideAllModals()
		},
		async handleCheckCopilot() {
			this.connectButtonText = "Conectando a WE COPILOT..."
			this.isSpinnerIconLoading = true
			try {
				await this.speakService.playCheckCopilotSound();
				this.copilotSoundStatus = "✅";
				this.wsClientStatus = this.wsClient.socket.readyState;
				let that = this;
				setTimeout(function () {
					that.handleCloseModal()
					this.isSpinnerIconLoading = false
				}, 1500);
			} catch (e) {
				this.wsClientStatus = this.wsClient.socket.readyState;
				this.copilotSoundStatus = "❌";
			}

		}
	}
}
</script>

