<template>
<!--	<div class="modal fade modal-kiosk" id="modalInformationScannerQR" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
		<div class="modal-dialog modal-dialog-centered modal-fullscreen">
			<div class="modal-content">
				<div class="modal-header">
					<div class="modal-title h4" id="exampleModalLabel">¡Listo para tu experiencia!</div>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body text-center justify-content-center d-flex flex-column">
					<p>
						Accede a tu reserva a través del enlace que te hemos enviado por email y prepárate para mostrar tu código QR.
					</p>
				</div>
				<div class="modal-footer">
					<div class="row w-100">
						<div class="col-4">
							<button type="button" class="btn btn-outline-primary w-100" data-bs-dismiss="modal">Cerrar</button>
						</div>
						<div class="col-8">
							<button type="button" @click="cameraService.showModalCameraScan" class="btn btn-primary w-100">todo listo escanear</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>-->

	<div class="h-100 py-2">
		<section class="container-fluid m-0 h-100 d-flex flex-column justify-content-between">
			<h1 class="text-center">¡Listo para tu experiencia!</h1>
			<p class="text-center fs-4">
				Accede a tu reserva a través del enlace que te hemos enviado por email y prepárate para mostrar tu código QR.
			</p>

			<div class="row gap-3">
				<div class="col-12">
					<button type="button" @click="cameraService.showModalCameraScan" class="btn btn-primary w-100 text-uppercase fs-3">todo listo escanear</button>
				</div>
				<div class="col-12">
					<button type="button" @click="emitCloseComponent" class="btn btn-outline-primary w-100 text-uppercase">Cerrar</button>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
	name: "KioskWalkin",
	inject: {
		cameraService: 'cameraService',
	},
	props:{
		viewComponent: {
			type: String
		}
	},
	emits: ['close-component'],
	methods:{
		...mapActions('kiosk',['saveSetShowComponentKiosk']),
		emitCloseComponent() {
			this.saveSetShowComponentKiosk('')
		},
	}
}
</script>

<style scoped>

</style>
