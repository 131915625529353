<template>
	<section class="box-shadow m-0 h-100 p-1 scroll-y">
		<div class="row g-0">
			<template
				v-for="menu in sortedMenus"
				:key="menu.id"
			>
				<div class="col-12">
					<span class="fs-6 text-uppercase fw-bold">{{menu.menuTypeName}}</span>
				</div>
				<template
					v-for="category in menu.menuCategories"
					:key="category.id"
				>
					<div
						class="col-3 col-md-3 col-lg-2 col-xl-2"
						v-if="category.products.length || category.children.length"
					>
						<button
							ref="buttons"
							class="btn btn-secondary w-100 btn-tpv"
							@click="selectCategory(category.id, category.name)"
							:class="{ 'active': category.id === selectedCategory.id }"
							style="height: 5rem;"
						>
							{{ category.name }}
						</button>
					</div>
				</template>

			</template>
		</div>
	</section>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import store from "@/store";
export default {
	name: "TpvMenuCategories",
	computed: {
		...mapGetters("menu", ["menus", "selectedCategory"]),
		sortedMenus() {
			return Array.isArray(this.menus)
				? [...this.menus].sort((a, b) => a.sortOrder - b.sortOrder)
				: [];
		},
	},
	methods: {
		...mapActions("menu", ["setCategorySelected"]),

		selectCategory(categoryId, categoryName) {
			this.setCategorySelected({ id: categoryId, name: categoryName });

			store.commit('tpv/setViewSectionAccounts', "createAccount")
			this.showProducts()
		},

		showProducts() {
			this.$store.commit('tpv/setViewSectionItems','products')
		},
	},
};
</script>

<style scoped>

</style>
