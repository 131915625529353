<template>
	<div class="col-12" v-if="!isProductOption">
		<ul class="nav nav-tabs mb-2">
			<li class="nav-item" v-for="name in categoryNamesWithProducts" :key="name">
				<button
					ref="buttons"
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv"
					:class="{ 'active': name === selectedCategoryName }"
					@click="selectCategory(name)"
				>
					{{ name }}
				</button>
			</li>
		</ul>
		<div class="col-12">
			<div class="row g-0">
				<TpvCardProduct
					v-for="product in selectedProducts"
					:key="product.id"
					:product="product"
					@product-selected-with-options="viewProductOptions"
				/>
			</div>

		</div>
	</div>
	<div class="col-12" v-else>
		<div class="row g-1">
			<ul class="nav nav-tabs">
				<li class="nav-item">
					<button
						ref="buttons"
						class="btn btn-outline-secondary btn-welcom btn-nav-tpv"
						@click="closeProductSelectedForOptions"
					>
						Volver
					</button>
				</li>
				<template v-if="selectedProductOptions.length > 1">
					<li class="nav-item" v-for="category in selectedProductOptions" :key="category.id">
						<button
							ref="buttons"
							class="btn btn-outline-secondary btn-welcom btn-nav-tpv"
							@click="selectedCategoryMenuProduct(category.id)"
						>
							{{ category.name }}
						</button>
					</li>
				</template>
			</ul>
			<template
				v-for="option in selectedProductOptions"
				:key="option.id"
			>
				<div
					class="col-12 col-md-3 col-xl-2"
					v-for="choseOption in option.options"
					:key="choseOption.id"
				>
					<button
						ref="buttons"
						class="btn btn-primary w-100 btn-tpv"
						@click="selectedOption(choseOption.id)"
					>
						{{ choseOption.name }}
					</button>
				</div>
			</template>
		</div>
	</div>
	<MenuProductCardOffCanvasOptionDailyMenu v-if="productSelectedForOptions && productSelectedForOptions.isMenu === 'SI'" :product="productSelectedForOptions" />

</template>

<script>

import TpvCardProduct from "@/components/tpv/tpvCardProduct/TpvCardProduct.vue";
import {normalizeChoices, showOffcanvas} from "@/utils";
import MenuProductCardOffCanvasOptionDailyMenu from "@/components/menuProductCardOffCanvasOptionDailyMenu/MenuProductCardOffCanvasOptionDailyMenu.vue";

export default {
	name: "TpvProductsByCategory",
	components: {MenuProductCardOffCanvasOptionDailyMenu, TpvCardProduct},
	props: {
		category: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			selectedCategoryName: null,

			productSelectedForOptions: null,
			selectedProductOptions:null,
			isProductOption: false,

			optionsSelectedForProduct:null,

			categoryMenuOptions: null
		};
	},
	mounted() {
		if (this.categoryNamesWithProducts.length > 0) {
			this.selectCategory(this.categoryNamesWithProducts[0]);
		}

		this.$nextTick(() => {
			this.adjustFontSize();
		});

		window.addEventListener('resize', this.adjustFontSize);
	},
	beforeMount() {
		this.adjustFontSize();
		window.removeEventListener('resize', this.adjustFontSize);
	},
	computed: {
		categoryNamesWithProducts() {
			const findCategoryNames = (category) => {
				let result = [];

				if (category.products && Array.isArray(category.products) && category.products.length > 0) {
					result.push(category.name);
				}

				if (category.children && Array.isArray(category.children)) {
					for (const child of category.children) {
						result = result.concat(findCategoryNames(child));
					}
				}

				return result;
			};

			return findCategoryNames(this.category);
		},

		selectedProducts() {
			const findProductsByCategoryName = (category, targetName) => {
				let products = [];

				if (category.name === targetName && category.products && Array.isArray(category.products)) {
					products = products.concat(category.products);
				}

				if (category.children && Array.isArray(category.children)) {
					for (const child of category.children) {
						products = products.concat(findProductsByCategoryName(child, targetName));
					}
				}

				return products;
			};

			return this.selectedCategoryName
				? findProductsByCategoryName(this.category, this.selectedCategoryName)
				: [];
		},

		firstCategoryName() {
			return this.categoryNamesWithProducts.length > 0 ? this.categoryNamesWithProducts[0] : null;
		},
	},
	watch: {
		firstCategoryName: {
			immediate: true,
			handler(newCategoryName) {
				this.selectedCategoryName = newCategoryName;
				this.isProductOption = false;
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		isProductOption: {
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		selectCategory: {
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		},
		selectedCategoryName :{
			immediate: true,
			handler() {
				this.$nextTick(() => {
					this.adjustFontSize()
				})
			},
		}
	},
	methods: {
		selectCategory(categoryName) {
			this.selectedCategoryName = categoryName;
			this.isProductOption = false;
			this.$nextTick(() => {
				this.adjustFontSize()
			})

			console.log('que categoria he seleccionado', categoryName)
		},

		viewProductOptions({ product, productOptions, isProductOption }) {
			this.productSelectedForOptions = product;
			if(this.productSelectedForOptions.isMenu === 'SI') {
				console.log('Es Menu?', product.isMenu)
				this.$nextTick(() => {
					showOffcanvas(`offcanvas_${this.productSelectedForOptions.sku}`);
				})
				return
			}
			this.selectedProductOptions = productOptions;
			this.isProductOption = isProductOption;

			const normalizedOptions = normalizeChoices(this.selectedProductOptions);
			console.log('Emitting add-product with options:', normalizedOptions);
		},

		selectedOption(chosenOptionId) {
			const options = {};
			if (Array.isArray(this.selectedProductOptions)) {
				this.selectedProductOptions.forEach(option => {
					options[option.id] = [];
				});
			} else {
				console.warn('selectedProductOptions is not an array or is undefined:', this.selectedProductOptions);
			}

			const selectedOptionGroup = this.selectedProductOptions.find(option => option.options.some(o => o.id === chosenOptionId));
			if (selectedOptionGroup) {
				options[selectedOptionGroup.id] = [chosenOptionId];
			}

			this.$store.commit('menu/addProductToCart', {
				product: this.productSelectedForOptions,
				units: 1,
				options: options
			});

			this.closeProductSelectedForOptions()
		},

		selectedCategoryMenuProduct(categoryId) {
			if (this.selectedProductOptions.length > 1) {
				this.selectedProductOptions.find(option => {
					if (option.id === categoryId) {
						console.log('Opciones del menu', option.options)
						return 	this.categoryMenuOptions = option.options
					}
				})

				console.log('que es esto en el menu', this.selectedProductOptions)
			}
		},

		closeProductSelectedForOptions(){
			this.productSelectedForOptions = null;
			this.isProductOption = false;
		},

		adjustFontSize() {
			if (this.$refs.buttons) {
				// Asegurarte de que `this.$refs.buttons` sea iterable
				const buttons = Array.isArray(this.$refs.buttons)
					? this.$refs.buttons
					: Array.from(this.$refs.buttons);

				buttons.forEach(button => {
					let fontSize = 20; // Tamaño inicial en px
					button.style.fontSize = `${fontSize}px`;
					while (button.scrollHeight > button.offsetHeight && fontSize > 1) {
						fontSize -= 1;
						button.style.fontSize = `${fontSize}px`;
					}
				});
			}
		}

	}
};
</script>

<style scoped>

</style>
