<template>
	<section class="box-shadow d-flex flex-column m-0 h-100 p-2">
		<ul class="list-group">
			<li class="list-group-item">
				<div class="col-12">
					<div class="row g-0 text-uppercase fw-bold">
						<div class="col-2 col-lg-1">
							Pax
						</div>
						<div class="col-3 col-lg-3">
							Mesa
						</div>
						<div class="col-5 col-lg-5">
							Nombre
						</div>
					</div>
				</div>
			</li>
		</ul>

		<ul class="list-group list-group-item-light overflow-y-scroll h-100">
				<li class="list-group-item" v-for="reservation in activeAccounts" :key="reservation.id">
					<div class="row g-0 align-items-center text-uppercase">
						<div class="col-1 col-xl-1 fw-bold">
							{{reservation.pax}}
						</div>
						<div class="col-3 col-xl-3">
							<span class="d-flex d-xxl-block flex-column" v-if="reservation.roomName !== 'Barra'">
								<span class="badge badge-primary">
									{{reservation.table}}
								</span>
								<span class="vr mx-1"></span>
								<span>
									{{reservation.roomName}}
								</span>
							</span>
							<span class="d-flex d-xxl-block flex-column" v-if="reservation.roomName === 'Barra'">
								<span class="badge badge-primary text-wrap">
									{{reservation.locationTag}}
								</span>
								<span>
									<span class="vr mx-1"></span>
									{{reservation.roomName}}
								</span>
							</span>
						</div>
						<div class="text-truncate text-uppercase" :class="reservation.dateReadyForPayment ? 'col-3 col-lg-3 col-xl-2' : 'col-5 col-lg-5 col-xl-6'">
							<span class="" v-if="reservation.name">
								{{reservation.name}}
							</span>
						</div>
						<div class="col-2 col-xl-4" v-if="reservation.dateReadyForPayment">
							<span class="badge badge-warning text-dark" >
								Cuenta solicitada
							</span>
						</div>
						<div class="col-3 col-xl-2 d-flex justify-content-end">
							<button
								class="btn btn-outline-primary btn-square"
								style="width: 40px;height: 40px;"
								@click="viewAccount(reservation.id)"
							>
								<i class="fa-solid fa-eye"></i>
							</button>
						</div>
					</div>
				</li>
			</ul>
	</section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "TpvListAccounts",
	components: {},
	computed: {
		...mapGetters("copilot", ['reservations']),
		activeAccounts() {
			return this.reservations.filter(reservation =>
				reservation.menuAccessEnabled && !reservation.finishedDateTime
			);
		}
	},
	methods:{
		viewAccount(reservationId) {
			this.$store.commit("tpv/setAccountSelectedId", reservationId);
			this.$store.commit("tpv/setViewSectionAccounts",'accountSelected');
		},
	}
}
</script>
