import {reservationRepository} from "@/service/repository/reservationRepository";
import {DateTime} from 'luxon';

const state = {
	availableRooms: [],
	currentStep: 1,
	selectedDate: DateTime.now().toISODate(),
	selectedPax: null,
	selectedRoom: null,
	selectedTime: null,
	isCheckingAvailability: false,
	selectedReservationDuration: null,
	selectedPhone: null,
	timePickerObject: null,
	isCannotBeReserved: false,
	isIframe: null,
	previousHeight: null,
	allTimeSlots: null,
	enableTimeSlots: null,
	rooms: []
};

const getters = {
	currentStep: state => {
		return state.currentStep;
	},
	rooms: state => {
		return state.rooms;
	},
	isIframe: state => {
		return state.isIframe;
	},
	isInputTimeDisable: state => {
		return !state.selectedDate || !state.selectedPax || state.isCannotBeReserved || state.isCheckingAvailability;
	},
	isVisibleRoomsSelect: state => {
		return state.selectedDate && state.selectedPax && !state.isCannotBeReserved && state.selectedTime;
	},
	isCannotBeReserved: state => {
		return state.isCannotBeReserved;
	},
	availableRooms: state => {
		return state.availableRooms;
	},
	enableTimeSlots: state => {
		return state.enableTimeSlots;
	},
	allTimeSlots: state => {
		return state.allTimeSlots;
	},
	selectedRoom: state => {
		return state.selectedRoom;
	},
	selectedPax: state => {
		return state.selectedPax;
	},
	selectedDate: state => {
		return state.selectedDate;
	},
	selectedTime: state => {
		return state.selectedTime;
	},
	selectedReservationDuration: state => {
		return state.selectedReservationDuration;
	},
	selectedPhone: state => {
		return state.selectedPhone;
	},
};

const mutations = {
	setIsCheckingAvailability(state, payload) {
		state.isCheckingAvailability = payload.isChecking;
	},
	setIsIframe(state, payload) {
		state.isIframe = payload.isIframe;
	},
	setRooms(state, payload) {
		state.rooms = payload.rooms;
	},
	setCurrentStep(state, payload) {
		state.currentStep = payload.currentStep;
	},
	setSelectedDate(state, payload) {
		state.selectedDate = payload.selectedDate;
	},
	setSelectedPax(state, payload) {
		state.selectedPax = payload.selectedPax;
	},
	setSelectedRoom(state, payload) {
		state.selectedRoom = payload.selectedRoom;
	},
	setSelectedTime(state, payload) {
		let duration = null;

		if (state.allTimeSlots) {
			console.log(state.allTimeSlots)
			const availableRoomIds = state.allTimeSlots.filter(timeSlot => timeSlot.timeSlot === payload.selectedTime && timeSlot.countAvailable > 0).map(timeSlot => timeSlot.roomId);
			const availableRooms = state.rooms.filter(room => availableRoomIds.includes(room.room_id));
			console.log(availableRooms);
			state.availableRooms = availableRooms;

			const slot = state.allTimeSlots.find(slot => slot.timeSlot === payload.selectedTime);
			duration = slot ? slot.duration : null;
		}

		state.selectedTime = payload.selectedTime;
		state.selectedReservationDuration = duration;
		console.log('el selected time: ', state.selectedTime, ' y la duracion: ', state.selectedReservationDuration)
	},
	setTimesSlotsEnable(state, payload) {
		console.log(payload.timesToEnable, payload)
		const timesSlotsEnable = payload.timesToEnable.map(timeSlot => timeSlot.time);
		if (!timesSlotsEnable.length) {
			console.log('No hay disponibilidad');
			state.isCannotBeReserved = true;
			state.enableTimeSlots = null;
			state.timePickerObject.set('disable', true);
			state.timePickerObject.set('enable', false);
		} else {
			state.isCannotBeReserved = false;
			state.enableTimeSlots = payload.timesToEnable;
			state.timePickerObject.set('disable', true);
			state.timePickerObject.set('enable', false);
			state.timePickerObject.set('disable', timesSlotsEnable);
		}
	},
	setAllTimesSlots(state, payload) {
		state.allTimeSlots = payload.allTimeSlots;
	},
	setTimePickerObjetc(state, payload) {
		state.timePickerObject = payload.timePickerObject;

		if (state.isIframe) {
			state.timePickerObject.on('open', () => {
				const pickerFrameTime = document.querySelectorAll('.picker__frame')[1];
				pickerFrameTime.style.removeProperty("bottom");
				pickerFrameTime.style.top = "0";
				const container = document.querySelector('.picker__list');
				const height = container.offsetHeight;
				mutations.sendResizeMessage(state, height);
			})

			state.timePickerObject.on('close', () => {
				mutations.sendResizeMessage(state, state.previousHeight);
			})
		}
	},
	clearTimePicker(state) {
		state.selectedTime = null;
		if (state.timePickerObject != null) {
			state.timePickerObject.clear();
		}
	},
	setPhone(state, payload) {
		state.selectedPhone = payload.selectedPhone;
	},
	clearDataFormModule(state) {
		state.selectedDate = DateTime.now().toISODate();
		state.selectedPax = null;
		state.selectedRoom = null;
		state.selectedTime = null;
		state.selectedReservationDuration = null;
		state.selectedPhone = null;
		state.allTimeSlots = null;
		state.enableTimeSlots = null;
		state.isIframe = null;
		state.previousHeight = null
		state.currentStep = null
	},
	sendResizeMessage(state, newSize = null) {
		const componentReference = document.getElementById('welcom-reservar');
		const currentHeight = componentReference.scrollHeight + 10;
		if (currentHeight !== state.previousHeight || newSize !== null) {
			const size = newSize !== null ? newSize : currentHeight;
			state.previousHeight = currentHeight;
			window.parent.postMessage({event: 'resize', data: {size: size}}, '*');
		}
	},
};

const actions = {
	async setAvailableRooms({commit}) {
		try {
			const availableRoomsResult = await reservationRepository.getAvailableRooms();
			if (availableRoomsResult.result === 0) {
				let allRooms = [];
				availableRoomsResult.rooms.length ? allRooms = availableRoomsResult.rooms : allRooms = Object.values(availableRoomsResult.rooms);
				if(allRooms.length === 1){
					commit('setSelectedRoom', {selectedRoom: allRooms[0].room_id})
				}
				commit('setRooms', {rooms: allRooms});
			} else {
				console.log("Algo ha ido mal al obtener las aviableRooms");
			}
		} catch (error) {
			console.error("Algo ha ido mal al obtener las aviableRooms", error);
		}
	},
	async checkAvailability({commit}) {
		console.log('Comprobando la disponibilidad');
		commit('setIsCheckingAvailability', {isChecking: true})
		const data = {
			dateFrom: state.selectedDate,
			date: state.selectedDate,
			pax: state.selectedPax,
		}
		try {
			const timeSlotsResponse = await reservationRepository.checkAvailability(data);
			const timeSlots = timeSlotsResponse.timeSlots;

			const enabledTimes = timeSlots.filter(slot => slot.countAvailable > 0);

			const timesToEnable = enabledTimes.map(timeSlotObjet => {
				const [hours, minutes] = timeSlotObjet.timeSlot.split(':').map(Number);
				const duration = timeSlotObjet.duration;
				const durationLimited = timeSlotObjet.duration_limited;
				const roomId = timeSlotObjet.roomId
				return {time: [hours, minutes], duration, roomId, durationLimited};
			});

			commit('setTimesSlotsEnable', {timesToEnable});
			commit('setAllTimesSlots', {allTimeSlots: timeSlots});
			commit('setIsCheckingAvailability', {isChecking: false})
		} catch (error) {
			commit('setIsCheckingAvailability', {isChecking: false})
			console.error("Algo ha ido mal al comprobar la disponibilidad", error);
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	getters,
	actions
};
