import { copilotRepository } from "@/service/repository/copilotRepository";
import { showModal } from "@/utils";
import store from "@/store";


export class WalkingService {
	constructor() {
	}

	async create(data) {
		let createWalkInResult = await copilotRepository.createWalkin(data);

		if (createWalkInResult.result === 0) {
			let reservation = createWalkInResult.reservation;
			store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			this._openQRInvitation(reservation.id);
		}

		return createWalkInResult;
	}

	async createKiosk(data) {
		let createWalkInResult = await copilotRepository.createKioskoWalkin(data);

		if (createWalkInResult.result === 0) {
			let reservation = createWalkInResult.reservation;
			store.commit('copilot/setSelectedReservationId', { reservationId:reservation.id });

			this._openQRInvitation(reservation.id);
		}

		return createWalkInResult;
	}

	_openQRInvitation(reservationId) {
		const collapseElementId = 'collapse' + reservationId;
		const reservationElementId = 'reservation-' + reservationId;

		let collapseElement = document.getElementById(collapseElementId);

		if (collapseElement) {
			this._showQrModal(reservationId, collapseElementId, reservationElementId);
			return;
		}

		const observer = new MutationObserver((mutationsList, observer) => {
			collapseElement = document.getElementById(collapseElementId);

			if (collapseElement) {
				observer.disconnect();  // Stop observing once the element is found
				this._showQrModal(reservationId, collapseElementId, reservationElementId);
			}
		});

		observer.observe(document.body, { childList: true, subtree: true });

		setTimeout(() => {
			observer.disconnect();
			collapseElement = document.getElementById(collapseElementId);
			if (!collapseElement) {
				console.log(`Collapse element for reservation ${reservationId} was not found after 3 seconds.`);
			}
		}, 3000);
	}

	_showQrModal(reservationId) {
		const collapseElement = document.getElementById('collapse'+reservationId);
		const reservationElement = document.getElementById('reservation-'+reservationId);
		const parentAccordion = collapseElement.closest('[data-bs-parent="#accordionAllServices"]');

		if (parentAccordion && !parentAccordion.classList.contains('show')) {
			parentAccordion.classList.add('show');
		}

		if (reservationElement) {
			window.scrollTo({
				top: reservationElement.offsetTop,
				behavior: 'smooth'
			});
		} else {
			console.error(`Reservation element with ID reservation-'${reservationId}' not found.`);
		}

		setTimeout(() => {
			showModal('modalQr' + reservationId);
		}, 0);
	}

}

export const walkingService = new WalkingService();
