<template>
	<div class="offcanvas offcanvas-bottom" tabindex="-1" id="menu-modal-assessment">
		<div class="offcanvas-header mb-0">
			<div class="offcanvas-title h4" id="offcanvasBottomLabel">
				<i class="fa-solid fa-receipt"></i> {{ titleOffcanvas }}
			</div>

			<button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
		</div>
		<div class="offcanvas-body p-3" id="body-menu-modal-assessment">
			<div>
				<p class="text-center">{{ t('menu.reviewHelperTextTop')}}</p>

				<section v-if="showStep1" class="text-center">
					<div class="star-rating mb-2">
						<input type="radio" id="star5" name="rating" value="5" @change="handleRatingSelection">
						<label for="star5" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star4" name="rating" value="4" @change="handleRatingSelection">
						<label for="star4" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star3" name="rating" value="3" @change="handleRatingSelection">
						<label for="star3" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star2" name="rating" value="2" @change="handleRatingSelection">
						<label for="star2" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
						<input type="radio" id="star1" name="rating" value="1" @change="handleRatingSelection">
						<label for="star1" class="star"><i class="fas fa-star"></i>
							<StarIcon/>
						</label>
					</div>
					<div class="rating-feedback"><small>{{ t('menu.reviewHelperTextBottom') }}</small></div>
				</section>

				<section v-if="showStep2 && paymentConfig" key="step2" id="step2">
					<div class="container">
						<div class="row"  @click="resetAll" style="cursor: pointer">
							<div class="col-10 fw-bold" data-bs-toggle="collapse">
								1. Selecciona tu método de pago
							</div>
							<div class="col-2">
								<div v-if="!isCollapseShowStep2" class="w-100" >
									<span class="rating-feedback"><small>Volver</small></span>
								</div>
							</div>
						</div>
						<div class="collapse show mt-3" id="collapse-method-pay">
							<ul class="row g-3 list-unstyled">
								<li class="col-12">
									<button :class="{'disabled': !paymentConfig.localCashPayment}" class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedLocalPayCash">
										<template v-if="isLoadingLocalPayCash">
											Procesando solicitud...
											<SpinnerIcon/>
										</template>
										<template v-else>
											{{ textSelectedLocalPayCash }}
										</template>
									</button>
								</li>
								<li class="col-12">
									<button :class="{'disabled': !paymentConfig.localCardPayment}" class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedLocalPayCard">
										<template v-if="isLoadingLocalPayCard">
											Procesando solicitud...
											<SpinnerIcon/>
										</template>
										<template v-else>
											{{ textSelectedLocalPayCard }}
										</template>
									</button>
								</li>
								<li class="col-12">
									<button :class="{'disabled': !paymentConfig.onlinePayment}" class="btn btn-outline-primary btn-welcom btn-lg w-100" @click="selectedMobilePay" v-html="textSelectedMobilePay">
									</button>
								</li>
							</ul>
						</div>
					</div>
				</section>

				<div v-if="showStep3" key="step3" id="step3">
					<section>
						<div class="container">
							<div class="row g-3">
								<div class="col-12 fw-bold">
									2. Tus datos personales
								</div>

								<div class="col-12">
									<div class="input-group">
										<span class="input-group-text" id="basic-addon1">@</span>
										<input type="text" class="form-control" :placeholder="t('menu.enterYourEmail')" aria-label="Email" aria-describedby="basic-addon1">
									</div>
								</div>

								<div class="col-12">
									Ingrese su correo electrónico para obtener el recibo del pago.
								</div>

								<div class="col-12">
									<div id="signupTermsSection" class="form-check">
										<input id="signup-terms" class="form-check-input" type="checkbox" value="signup-terms">
										<label class="form-check-label" for="signup-terms">
											Acepto la
											<a data-bs-toggle="modal" data-bs-target="#privacy-terms-modal" class="link link-primary" href="#termsPrivacy">política de privacidad</a>
										</label>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section>
						<div>
							<div class="fw-bold" style="cursor: pointer" @click="openCollapseProductsList">
								Lista de productos <span class="rating-feedback"><small>(mostrar {{ textInformationCollapseProductList }})</small></span>
							</div>

							<div class="vr-horizontal"></div>

							<div class="orders-history bg-transparent collapse" id="list-products-historic">
								<div class="products-list-content mt-3">
									<OrdersProductCard source="account" :order="order" v-for="order in historicProducts" :key="order.cartSessionOrderId"></OrdersProductCard>
								</div>
							</div>

							<!--								<div class="fw-bold d-flex justify-content-between" >-->
							<!--									Subtotal <span>35$</span>-->
							<!--								</div>-->
							<!--								<div class="fw-bold d-flex justify-content-between" >-->
							<!--									IVA <span>35$</span>-->
							<!--								</div>-->

							<div class="fw-bold d-flex justify-content-between mb-3" v-if="reservationInfo.paymentInfo">
								Total <span>{{ reservationInfo.paymentInfo.total }}€</span>
							</div>

							<button class="btn btn-primary w-100" :class="''" @click="paymentOpen"> {{ textBtnPaymentOpen}} </button>
						</div>
					</section>

					<section v-if="false">
						<div class="container">
							<div class="row g-3">
								<div class="col-12 fw-bold">
									¿Quieres añadir una propina?
								</div>

								<!-- Tarjeta 1 (5%) -->
								<div class="col-3">
									<div class="card h-100 mb-0" :class="{'active': selectedCard === 1}" @click="selectCard(1)">
										<div class="card-body p-1">
											<div>(5%)</div>
											<b>1.5$</b>
										</div>
									</div>
								</div>

								<!-- Tarjeta 2 (10%) -->
								<div class="col-3">
									<div class="card h-100 mb-0" :class="{'active': selectedCard === 2}" @click="selectCard(2)">
										<div class="card-body p-1">
											<div>(10%)</div>
											<b>3.0$</b>
										</div>
									</div>
								</div>

								<div class="col-3">
									<div class="card h-100 mb-0" :class="{'active': selectedCard === 3}" @click="selectCard(3)">
										<div class="card-body p-1">
											<div>(5%)</div>
											<b>1.5$</b>
										</div>
									</div>
								</div>

								<!-- Tarjeta editable -->
								<div class="col-3">
									<div
										class="card h-100 mb-0 text-black-50 p-1 d-flex justify-content-center align-items-center"
										contenteditable="true"
										@focus="clearPlaceholder"
										@blur="setPlaceholder"
										@input="saveEditableContent"
										ref="editableDiv"
										:class="{'active': selectedCard === 4}"
										@click="selectCard(4)"
									>
										{{ placeholderText }}
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>

				<section v-if="showStep4" key="step4" id="step4">
					<iframe width="100%" height="900px" :src="resultUrlCreatePayment" frameborder="0"></iframe>
				</section>
			</div>
		</div>
		<div class="offcanvas-footer" v-if="false">
			<button :class="{'disabled': !isActiveBtnAssessment}" class="btn btn-primary w-100" @click="handleClickBtnAssessment">
				<span v-html="assessmentButtonText"></span>
			</button>
		</div>
	</div>

</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";
import * as bootstrap from 'bootstrap'

import OrdersProductCard from "@/components/ordersProductCard/OrdersProductCard";
import StarIcon from "@/core/icons/starIcon";
import SpinnerIcon from "@/core/icons/SpinnerIcon";
import {hideAllOffcanvas, showModal} from "@/utils";
import {useI18n} from "vue-i18n";
import {computed} from "vue";

export default {
	name: 'menuOffcanvasAssessment',
	inject: {
		menuRepository: 'menuRepository',
		reservationRepository: 'reservationRepository',
	},
	props: {
		reservationInfo: {
			type: Object,
			required: true
		},
		historicProducts: {
			type: Object,
			required: true
		}
	},
	data() {
		return {

			comment: "",
			selectedRating: null,

			showStep1: true,
			showStep2: false,
			showStep3: false,
			showStep4: false,

			isCollapseShowStep2: true,

			//assessmentButtonText: 'Pedir la cuenta',

			//textSelectedLocalPayCash: 'Pagar en efectivo',
			isLoadingLocalPayCash: false,

			//textSelectedLocalPayCard: 'Pagar con tarjeta',
			isLoadingLocalPayCard: false,

			//textSelectedMobilePay: 'Pagar online',
			isLoadingMobilePay: false,


			isActiveBtnAssessment: false,
			clickBtn: '',

			isOpenCollapseProductsList : false,

			//placeholderText: 'Dejar propina',
			isPlaceholderVisible: true,
			savedContent: '',

			selectedCard: null,

			//textBtnPaymentOpen: 'Ir a pagar',
			urlCreatePayment: '',
		};
	},
	setup() {
		const { t } = useI18n();

		// Use computed to dynamically return the translated strings
		const assessmentButtonText = computed(() => t('menu.requestBill'));
		const textSelectedLocalPayCash = computed(() => t('menu.payCash'));
		const textSelectedLocalPayCard = computed(() => t('menu.payCard'));
		const textSelectedMobilePay = computed(() => t('menu.payOnline'));
		const textBtnPaymentOpen = computed(() => t('menu.goToPay'));
		const placeholderText = computed(() => t('menu.leaveTip'));

		return {
			t,
			assessmentButtonText,
			textSelectedLocalPayCash,
			textSelectedLocalPayCard,
			textSelectedMobilePay,
			textBtnPaymentOpen,
			placeholderText,
		};
	},
	components: {OrdersProductCard, StarIcon, SpinnerIcon},
	computed: {
		...mapGetters('user', ['user']),
		...mapGetters('venue', ['reviewGoogleMapsUrl', 'paymentConfig']),
		...mapGetters('menu', ['isAccountRequester']),
		isActiveReservation() {
			return this.reservationInfo && !this.reservationInfo.finishedDateTime && this.reservationInfo.status === 1 && this.reservationInfo.menuAccessEnabled === 1
		},
		titleOffcanvas() {
			return this.isActiveReservation ? this.t('menu.requestBill') : 'Enviar valoración'
		},

		textInformationCollapseProductList() {
			if (this.isOpenCollapseProductsList) {
				return 'menos'
			}
			return 'más'
		},

		resultUrlCreatePayment() {
			return this.urlCreatePayment
		}
	},
	mounted() {
	},
	methods: {
		async handleRatingSelection(event) {
			this.selectedRating = event.target.value;

			console.log(this.selectedRating, this.reservationInfo.id, this.user.id);

			if (!this.isActiveReservation && !this.isAccountRequester) {
				const data = {reservationWid: this.reservationInfo.id, userId: this.user.id, score: this.selectedRating}
				await this.menuRepository.sendReview(data);

				hideAllOffcanvas();

				showModal('modal-payment-result')
			}

			await this.stepsToFollow(2)
			await this.scrollToStep('step2')
		},
		async handleSendComment() {
			const data = {reviewId: this.userReview.reviewId, reviewText: this.comment}
			console.log(data, 'desde poner el comenario')
			await this.menuRepository.addTextToReview(data);
		},

		scrollToStep(id) {
			const step2Element = document.getElementById(id);
			const offcanvasBody = document.getElementById('body-menu-modal-assessment');

			if (step2Element && offcanvasBody) {
				const step2Position = step2Element.offsetTop;
				offcanvasBody.scrollTo({
					top: step2Position,
					behavior: 'smooth'
				});
			}
		},
		async handleAskAccount(paymentMethod) {
			const data = {reservationWid: this.reservationInfo.id, userId: this.user.id, score: this.selectedRating}

			await this.menuRepository.sendReview(data);

			if (this.isActiveReservation) {

				await store.dispatch('menu/requestAccount', {
					reservationInfo: this.reservationInfo,
					user: this.user,
					paymentMethod: paymentMethod
				})
				this.titleOffcanvas = 'Has pedido la cuenta'
			}
			this.titleOffcanvas = 'Valoración enviada'
		},
		async handleClickBtnAssessment() {
			if (this.clickBtn === 'selectedLocalPay') {
				await this.handleAskAccount(0)
			}

			if (this.clickBtn === 'selectedMobilePay') {
				await this.selectedMobilePay()
			}
		},

		stepsToFollow(step) {
			this[`showStep${step}`] = true;
		},

		async selectedLocalPayCash() {
			this.assessmentButtonText = this.t('menu.requestBill');
			this.isActiveBtnAssessment = true;

			this.clickBtn = 'selectedLocalPayCash';

			this.isLoadingLocalPayCash = true;
			await this.handleAskAccount(1)

			this.isLoadingLocalPayCash = false;
			hideAllOffcanvas();

			this.$store.dispatch('menu/changeAccountRequesterStatus', true);
			showModal('modal-payment-result');
		},
		async selectedLocalPayCard() {
			this.assessmentButtonText = this.t('menu.requestBill');
			this.isActiveBtnAssessment = true;

			this.clickBtn = 'selectedLocalPayCard';

			this.isLoadingLocalPayCard = true;
			await this.handleAskAccount(2)

			this.textSelectedLocalPayCard = 'Pago con tarjeta';
			this.isLoadingLocalPayCard = false;
			hideAllOffcanvas();

			this.$store.dispatch('menu/changeAccountRequesterStatus', true);
			showModal('modal-payment-result');
		},
		async selectedMobilePay() {
			this.assessmentButtonText = 'Proceder con el pago';
			this.isActiveBtnAssessment = false;
			this.clickBtn = 'selectedMobilePay';
			const idElement = document.getElementById('collapse-method-pay')

			if (idElement.classList && idElement.classList.contains('show')) {
				idElement.classList.remove('show');
			}
			this.isCollapseShowStep2 = false;

			await this.handleAskAccount(3);

			await this.stepsToFollow(3)
			await this.scrollToStep('step3')
		},

		async paymentOpen() {
			this.textBtnPaymentOpen = 'Cargando...';
			console.log(this.reservationInfo.id)
			console.log(this.user.id)

			const resultCreatePayment = await this.reservationRepository.createPayment({
				reservationWid: this.reservationInfo.id,
				userId: this.user.id
			});

			console.log(resultCreatePayment);
			if (resultCreatePayment.result === 0) {
				// store.commit("copilot/setToast", {
				// 	toast: {
				// 		toastMessage: 'Se han actualizado los comensales correctamente.',
				// 		showToast: true,
				// 		idToast: "correctlyToast"
				// 	}
				// });
				this.urlCreatePayment = resultCreatePayment.paymentUrl;
				await this.handleAskAccount(1)

				await this.stepsToFollow(4)
				await this.scrollToStep('step4')

				this.textBtnPaymentOpen = 'Ir a pagar';

				// hideAllOffcanvas();
				//
				// showModal('modal-payment-result')
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: 'Ha ocurrido un error. No hemos podido continuar con el proceso.',
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});

				this.textBtnPaymentOpen = 'Ir a pagar';
			}
		},

		clearPlaceholder() {
			if (this.isPlaceholderVisible) {
				this.$refs.editableDiv.innerText = '';
				this.isPlaceholderVisible = false;
				this.$refs.editableDiv.focus();
			}
		},
		setPlaceholder() {
			const content = this.$refs.editableDiv.innerText.trim();
			if (!content) {
				this.$refs.editableDiv.innerText = this.placeholderText;
				this.isPlaceholderVisible = true;
			}
		},
		saveEditableContent(event) {
			const content = event.target.innerText.trim();
			this.savedContent = content;
		},
		selectCard(cardIndex) {
			this.selectedCard = cardIndex;
		},

		resetAll() {
			const idElement = document.getElementById('collapse-method-pay')

			if (idElement.classList) {
				idElement.classList.add('show');
			}
			this.showStep1 = true
			this.showStep3 = false;
			this.showStep4 = false;
			this.isCollapseShowStep2 = true;
		},
		openCollapseProductsList() {
			const element = document.getElementById('list-products-historic');
			if (element) {
				const collapse = new bootstrap.Collapse(element, {
					toggle: false
				});

				if (element.classList.contains('show')) {
					collapse.hide();
					this.isOpenCollapseProductsList = false;
				} else {
					collapse.show();
					this.isOpenCollapseProductsList = true;
				}
			}
		}
	}
}
</script>
