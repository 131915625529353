import * as bootstrap from 'bootstrap';
import jsQR from 'jsqr';
import store from "@/store";
import {copilotRepository} from "@/service/repository/copilotRepository";
import {hideAllModals} from "@/utils";
export class CameraService {
	constructor() {
		const host = window.location.hostname;
		if (host === "localhost"|| host.startsWith("192.168")){
			this.urlBase = 'https://devdemo.wewelcom.io';
		} else {
			this.urlBase = window.location.origin;
		}
		console.log('CameraService instanciado', this.urlBase);
		this.scannedCode = null;
		this.cameraFacingMode = "environment";
		this.qrcameraScanTimeoutHandler = null;
		this.cameraActive = false;
		this.cameraStream = null;
		this.sourceComponent = null;
	}

	async startCamera() {
		if(this.sourceComponent === 'Kiosk'){
			this.cameraFacingMode = 'user'
		}else{
			this.cameraFacingMode = 'environment'
		}

		const that = this;
		this.checkingCode = false;

		if (!this.cameraActive && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			navigator.mediaDevices.getUserMedia({
				video: {
					facingMode: this.cameraFacingMode,
					focusMode: "auto",
				}
			})
				.then(function (stream) {
					console.log(stream)
					that.cameraStream = stream;
					let video = document.getElementById('preview');
					video.srcObject = stream;
					video.setAttribute("playsinline", true);
					video.play();
				})
				.catch(function (error) {
					console.error("No se puede acceder a la cámara", error);
					alert("Error inicializando cámara")
				});

			this.cameraActive = true;
		}

		this.scanQRCode();
		return true;
	}

	async pauseScan(){
		if (this.cameraStream) {
			console.log("Parando video");
			clearInterval(this.qrcameraScanTimeoutHandler);
		} else {
			console.log("No puedo parar el vídeo");
		}
	}
	async stopCamera() {

		if (this.cameraStream) {
			console.log("Parando video");
			this.cameraStream.getTracks().forEach(track => track.stop());

			let video = document.getElementById('preview');
			video.removeEventListener('play', this.scanQRCode);
			video.srcObject = null;

			this.cameraActive = false;

			clearInterval(this.qrcameraScanTimeoutHandler);
			hideAllModals()
		} else {
			console.log("No puedo parar el vídeo");
		}
	}

	async showModalCameraScan() {

		await this.startCamera();

		console.log("Mostrando modal de Cámara");
		let modalElement = document.getElementById("modalCameraScan");
		let modal = bootstrap.Modal.getInstance(modalElement);

		if (!modal) {
			// If the modal hasn't been initialized yet
			modal = new bootstrap.Modal(modalElement, {
				backdrop: 'static',
				keyboard: false
			});
		}

		if (!modal) {
			modal = new bootstrap.Modal(modalElement, {
				backdrop: 'static',
				keyboard: false
			});
		}
		modal.show();

	}

	scanQRCode() {
		this.qrcameraScanTimeoutHandler = setInterval(async () => {
			console.log("Buscando QR en captura de vídeo");

			let video = document.getElementById('preview');

			// Verificar si el video está listo.
			if (video.readyState === video.HAVE_ENOUGH_DATA ) {
				let canvas = document.createElement("canvas");
				let context = canvas.getContext("2d");

				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

				let imageData = context.getImageData(0, 0, video.videoWidth, video.videoHeight);
				let code = jsQR(imageData.data, imageData.width, imageData.height);

				if (code) {
					this.scannedCode = code.data;
					console.log(`Contenido del QR: ${this.scannedCode}`);
					this.checkingCode = true;
					await this.pauseScan();
					await this.checkScannedCode();
				} else {
					//output.textContent = "Esperando QR...";
				}
			}
		}, 1000);

	}

	async checkScannedCode() {
		console.log("Comprobando reservas del código " + this.scannedCode);

		let agent = "WELCOM IN QR";

		let data = {
			"code": this.scannedCode,
			// "code":'dfsdfdsf',
			"agent": agent,
			"type": 1,
			"text": "Escaneo de welcom in",
		}

		try {

			const checkCodeResponse = this.sourceComponent === 'Copilot' ? await copilotRepository.registerDoorLog(data) : await copilotRepository.registerKioskScanQr(data);

			console.log("La respuesta del servidor para el check del código fue ");
			console.log(checkCodeResponse);

			if(this.sourceComponent === 'Copilot'){
				await this.handleCheckCodeResponseFromCopilot(checkCodeResponse)
			}

			if(this.sourceComponent === 'Kiosk'){
				await this.handleCheckCodeResponseFromKiosk(checkCodeResponse)
			}


		} catch (e) {
			console.log("Error: " + e);
			//this.setErrorMessage("Vaya, ahora mismo parece haber un problema comprobando tu código.");
		}

		this.scannedCode = null;
	}

	async handleCheckCodeResponseFromCopilot(checkCodeResponse){
		if(checkCodeResponse.result=== 0){
			store.commit('copilot/setIsShowToastScanQr',{isShowToastScanQr: true})
			await this.stopCamera();
		}else{
			store.commit('copilot/setIsShowToastScanQr',{isShowToastScanQr: false})
			this.scanQRCode();
		}
	}

	async handleCheckCodeResponseFromKiosk(checkCodeResponse){
		// tiene reserva y es para hoy
		if (checkCodeResponse.result === 0) {
			console.log('El codigo existe y es para hoy');
			store.commit('kiosk/setModalScannerQr', {
				modalScannerQr: {
					showModal: true,
					idModal: 'modalCameraScanSuccess',
					firstReservation: checkCodeResponse.firstReservation,
					user: checkCodeResponse.user
				}
			});
			store.commit('kiosk/setShowComponentKiosk', '');

			hideAllModals();
			await this.stopCamera();
		}

		// tiene usuario pero no tiene reserva
		if (checkCodeResponse.result === 1) {
			console.log('Tiene usuario pero no tiene reserva');
			store.commit('kiosk/setModalScannerQr', {
				modalScannerQr: {
					showModal: true,
					idModal: 'modalCameraScanNotReservation',
					firstReservation: null,
					user: checkCodeResponse.user
				}
			});
			hideAllModals();
			await this.stopCamera();
		}

		// la reserva es de otro dia
		if(checkCodeResponse.result === 2) {
			console.log('Esta reserva es de otro dia');
			store.commit('kiosk/setModalScannerQr', {
				modalScannerQr: {
					showModal: true,
					idModal:'modalCameraScanErrorReservation',
					firstReservation: checkCodeResponse.firstReservation,
					user: checkCodeResponse.user
				}
			});
			hideAllModals();
			await this.stopCamera();

		}

		// no reconocemos el QR
		if(checkCodeResponse.result === -1) {
			console.log('No reconocemos el QR');
			store.commit('copilot/setModal', {
				modal: {
					showModal: true,
					idModal:'modalCameraScanError',
					firstReservation: null,
					user: null
				}
			});
			hideAllModals();
			await this.stopCamera();
		}

	}
}

export const cameraService = new CameraService();
