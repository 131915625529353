<template>
	<div class="col-12">
		<ul class="nav nav-tabs align-items-center">
			<li class="nav-item">
				<button
					class="btn btn-outline-secondary btn-welcom btn-nav-tpv text-uppercase active"
					style="height: 4.3rem;width: 5rem"
				>
					Numero de Pax
				</button>
			</li>

			<li class="nav-item mx-2" v-if="isTableBar">
				<div class="input-group" style="width: 20rem;">
					<span class="input-group-text" id="basic-addon1">
						<i class="fa-solid fa-tag"></i>
					</span>
					<input type="text" class="form-control" placeholder="Descripción de la zona" aria-label="Username" aria-describedby="basic-addon1" v-model="locationTagName">
				</div>
			</li>

			<li class="nav-item px-2">
				<span class="h5" v-if="isDisabled && isTableBar">
					Agrega una descripción de la zona
				</span>
				<span class="h5 text-wrap"  v-else>
					Selecciona cuantos pax serán para la nueva cuenta
				</span>
			</li>
		</ul>
	</div>
	<div class="col-12 mt-1 h-100">
		<div class="row g-0">
			<div
				class="col-3 col-lg-3 col-xl-2"
				v-for="(number, index) in groupedPax"
				:key="index"
			>
				<button
					class="btn btn-primary btn-welcom w-100 btn-tpv position-relative"
					style="height: 5rem;"
					@click="handleSelectedPax(number)"
					:disabled="isDisabled && isTableBar"
				>

						<span class="fs-5">
							{{ number }}
						</span>
				</button>
			</div>

		</div>
	</div>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {walkingService} from "@/service/walkingService";

export default {
	name: "TpvSelectedPax",
	data() {
		return {
			groupedPax: [
				1,
				2,
				3,
				4,
				5,
				6,
				7,
				8,
				9,
				10,
			],
			locationTagName: ''
		};
	},
	computed: {
		...mapGetters('tpv', ['newAccount']),
		...mapGetters('copilot', ['tables']),

		isDisabled() {
			return this.locationTagName.trim().length < 2;
		},

		isTableBar() {
			if (!this.newAccount || !this.newAccount.tables || !this.tables) {
				return false;
			}

			return this.newAccount.tables.some(accountTableId => {
				const foundTable = this.tables.find(table => table.table_id === accountTableId);
				return foundTable && foundTable.room === "Barra";
			});
		}
	},
	methods:{
		async handleSelectedPax(numberPax) {
			console.log('newAccount', this.newAccount)
			if (!this.newAccount) {
				console.log('cuantos pax son? ', numberPax);
				store.commit('tpv/setIsSelectedPax', true)
				store.commit('tpv/setSelectedNumberPax', numberPax)
				store.commit("tpv/setViewSectionItems","tables")
			} else {
				store.commit('tpv/setIsLoading', true)
				store.commit('tpv/setIsSelectedPax', true)
				store.commit('tpv/setSelectedNumberPax', numberPax)
				store.commit('tpv/updateNewAccount', {pax: numberPax, locationTag: this.locationTagName})

				let resultWalkin = await walkingService.create(this.newAccount);
				console.log("El resultado de crear el walkin", resultWalkin);

				if (resultWalkin.result === 0) {
					console.log({result: resultWalkin});
					// store.commit("tpv/setAccountSelectedId", resultWalkin.reservation.id);
					store.commit("tpv/setViewSectionAccounts", 'allAccounts');
					store.commit('menu/setProductsCart', {productsCart: []});
					store.commit('tpv/setViewSectionItems', 'topChoice');
					store.commit('tpv/setNewAccount', null);
					store.commit('tpv/setIsLoading', false)
				} else {
					store.commit("copilot/setToast", {toast: {toastMessage: 'No se ha podido crear la cuenta.', showToast: true, idToast: "incorrectlyToast"}});
					store.commit('tpv/setIsLoading', false)
				}
				console.log('Se actualiza la cuenta?', this.newAccount)
			}
		}
	},
	beforeMount() {
		console.log('newAccount', this.newAccount)

	},
	mounted() {
		console.log('newAccount', this.newAccount)
	}
};
</script>
