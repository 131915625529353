<template>
	<div class="modal fade" id="tpv-modal-remove-product" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" v-if="reservation">
		<div class="modal-dialog modal-lg modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header d-block">
					<h1 class="modal-title fs-4" id="exampleModalLabel">
						<div class="row">
							<div class="col-6">
								<span class="ps-2" v-if="reservation.name">{{ reservation.name }}</span>
							</div>
							<div class="col-6">
								<TableIcon/>
								<span class="ms-1">
									<span v-if="reservation.table">{{ reservation.table }}</span>
									<span class="vr mx-2"></span>
									<span v-if="reservation.roomName">{{ reservation.roomName }}</span>
								</span>
							</div>
						</div>
					</h1>
				</div>
				<div class="modal-body" style="height: 50vh">
					<ul class="list-group overflow-y-scroll overflow-hidden h-100">
						<li class="list-group-item list-group-item-light text-uppercase" v-if="reservation.cartSession">
							<div class="col-12" v-for="order in reservation.cartSession.productOrders" :key="order.id">
								<div class="row g-0 border-bottom py-1" :class="{'bg-body-secondary ': product.statusProductOrder === -1}" v-for="product in order.items" :key="product.id">
									<div class="col-8 col-xl-9" :class="product.statusProductOrder === -1 ? 'text-decoration-line-through':''">
										<div class="row g-0">
											<div class="col-lg-1 col-xl-1">
												<span class="fw-bold fs-5">
													{{ product.units }}
												</span>
											</div>
											<div class="col-10 col-lg-11 col-xl-11">
												<div class="row">
													<div class="col-12">
														{{ product.name }}
													</div>
													<div class="col-12" v-if="product.choices">
														<ul v-for="(choice, index) in product.choices" :key="index">
															<li>
																{{ choice.choice }}
															</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-2 col-xl-1">
										<span v-if="product.statusProductOrder === -1" class="badge badge-danger">
											Quitado
										</span>
									</div>
									<div class="col-2 col-xl-2 d-flex justify-content-end">
										<button class="btn bg-danger btn-square" style="width: 40px;height: 40px;" @click="removeProduct(product)">
											<i class="fa-solid fa-xmark"></i>
										</button>
									</div>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="modal-footer">
					<DismissButton class="text-uppercase" :is-modal="true"/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import TableIcon from "@/core/icons/TableIcon.vue";
import DismissButton from "@/core/DismissButton.vue";
import store from "@/store";

export default {
	name: "TpvModalRemoveProduct",
	props:{
		reservation:{
			type: Object,
			required: true,
		}
	},
	inject: {
		copilotRepository: 'copilotRepository',
	},
	components: {
		DismissButton,
		TableIcon
	},
	data() {
		return {

		}
	},
	methods: {
		async removeProduct(product) {
			const result = await this.copilotRepository.markProductOrderAsCancelled({productOrderId: product.productOrderId});
			console.log({result: result})
			if (result.result === 0) {
				console.log('todo bien')
			} else {
				store.commit("copilot/setToast", {
					toast: {
						toastMessage: `No se ha podido ${this.titleModal} el producto correctamente.`,
						showToast: true,
						idToast: "incorrectlyToast"
					}
				});
			}
		},
	}
}
</script>
