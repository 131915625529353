<template>
	<main :data-bs-theme="resourceId" class="main-container-kiosk vh-100 overflow-hidden" :style="{ 'background-image': `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.667)), url(/world/img/${resourceId}/header/header.jpg ` }">
		<div class="container-fluid h-100">
			<div class="row h-100">
				<div class="col-4 h-100">
					<div class="d-flex flex-column justify-content-between h-100">
						<button class="h-100 btn-welcom btn btn-primary flex-column my-2 text-uppercase" :class="{'active border-secondary': showComponentKiosk === 'KioskWalkin'}" @click="showComponentWalkin">
							<span class="fs-1">
								<i class="fas fa-qrcode icon"></i>
							</span>
							<span class="h3 fw-bold">Entrar con reserva QR</span>
						</button>
						<button class="h-100 btn-welcom btn btn-secondary flex-column my-2 text-uppercase" :class="{'active border-primary': showComponentKiosk === 'KioskSitin'}" @click="showComponentSitin">
							<span class="fs-1">
								<i class="fas fa-walking icon"></i>
							</span>
							<span class="h3 fw-bold">Entrar sin reserva</span>
						</button>
					</div>
				</div>
				<div class="col-8 h-100">
					<transition
						@before-enter="beforeEnter"
						@enter="enter"
						@leave="leave"
					>
							<component :is="showComponentKiosk" v-if="showComponentKiosk"  :view-component="showComponentKiosk" @close-component="emitCloseComponent"></component>
							<div v-else class="header-kiosk h-100 py-2 flex-column w-100 align-items-center">
<!--								<div class="title-kiosk">{{ businessName }}</div>-->
								<div class="content-img">
									<img :src="`/world/img/${resourceId}/logo/logo.png`" :alt="`logotipo de la empresa ${businessName}`">
								</div>
							</div>
					</transition>
				</div>
			</div>
		</div>
	</main>
	<modal-scanner-qr/>

<!--	<modal-kiosk-walkin></modal-kiosk-walkin>-->
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ModalScannerQR from "@/components/modalScannerQR/ModalScannerQR.vue";
import KioskWalkin from "@/components/kioskWalkin/KioskWalkin";
import KioskSitin from "@/components/kioskSitin/KioskSitin";
import { gsap } from "gsap";

export default {
	name: 'KioskWelcom',
	inject: {
		cameraService: 'cameraService',
	},
	data() {
		return {
		}
	},
	computed: {
		...mapGetters('venue', ['resourceId', 'urlVenue', 'businessName']),
		...mapGetters('config',['weWelcomUrl']),
		...mapGetters("kiosk",["showComponentKiosk"]),
	},
	components:{
		KioskSitin,
		KioskWalkin,
		modalScannerQr: ModalScannerQR,
	},
	created() {
		this.cameraService.sourceComponent = 'Kiosk';
		console.log('Se ha creado el componente Kiosk y hemos modificado el sourceComponent de cameraService')
	},
	beforeMount() {
	},
	mounted() {
	},
	methods: {
		...mapActions("kiosk",["saveSetShowComponentKiosk"]),
		emitCloseComponent() {
			this.saveSetShowComponentKiosk('')
		},

		showComponentWalkin(){
			this.saveSetShowComponentKiosk('KioskWalkin')
		},
		showComponentSitin(){
			this.saveSetShowComponentKiosk('KioskSitin')
		},

		beforeEnter(el) {
			gsap.set(el, {
				opacity: 0,
				x: 30
			});
		},
		enter(el, done) {
			gsap.to(el, {
				opacity: 1,
				x: 0,
				duration: 0.4,
				onComplete: done // Asegúrate que esta animación realmente finalice
			});
		},
		leave(el, done) {
			gsap.to(el, {
				opacity: 0,
				x: 30,
				duration: 0.4,
				onComplete: done // Igualmente aquí
			});
		}
	}
}
</script>
